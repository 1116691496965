import React from 'react';

import { type ButtonProps, Button } from '@npm/core/ui/components/atoms/Button';
import {
  type CodebookItem,
  type SecondmarketOrderItemShow,
  CbAccountSponsorshipType,
} from '@npm/data-access';

import { RequestMoreInformationButton } from '../../../../components';

import { InvestorL2MarketOrderActions } from './InvestorL2MarketOrderActions';

type Props = {
  orderItem: SecondmarketOrderItemShow;
  sponsorshipType: CodebookItem;
  handleShowOrderDetails: (
    orderItemId: number,
    parentOrderId: number | null,
    isEntityNpmsInvestor?: boolean,
    isOpportunitiesEnabled?: boolean
  ) => void;
  handleShowNegotiations: (orderItem: SecondmarketOrderItemShow) => void;
  handleStartNegotiation:
    | ((orderItem: SecondmarketOrderItemShow) => void)
    | undefined;
  buttonSize?: ButtonProps['size'];
};

export const InvestorMarketOrderActions = ({
  orderItem,
  sponsorshipType,
  ...props
}: Props) => {
  if (CbAccountSponsorshipType.isSponsoredAccountLevel2(sponsorshipType)) {
    return <InvestorL2MarketOrderActions orderItem={orderItem} {...props} />;
  }

  if (orderItem.account) {
    return (
      <Button
        variant="outline"
        block
        onClick={() =>
          props.handleShowOrderDetails(
            orderItem?.id,
            orderItem?.parent_order_item_id
          )
        }
      >
        Show Order Details
      </Button>
    );
  }
  return <RequestMoreInformationButton orderItem={orderItem} />;
};

import React, { type ComponentProps, type ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { Modal } from '@npm/core/ui/components/molecules/Modal';
import type { SecondmarketOrderItemShow } from '@npm/data-access';

import { useIsBrokerObserver } from '../../../../auth/user/role/hooks/useIsBrokerObserver';
import { useIsInvestor } from '../../../../auth/user/role/hooks/useIsInvestor';
import { MarketOrderCard } from '../../../market-feed/Inventory/MarketOrdersList/Cards/MarketOrderCard';
import { getMarketOrdersListConfig } from '../../../market-feed/Inventory/MarketOrdersList/MarketOrdersList.config';

import * as S from './MoreInfoConfirmationModal.styles';

type Props = {
  orderItem: SecondmarketOrderItemShow;
  title?: string;
  okText?: ReactNode;
  isLoading?: boolean;
  requestNegotiationAccessCheckboxValue?: boolean;
  setRequestNegotiationAccessCheckboxValue?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
} & Pick<ComponentProps<typeof Modal>, 'open' | 'onOk' | 'onCancel'>;

export const MoreInfoConfirmationModal = ({
  orderItem,
  title = 'Request More Information',
  okText = 'Send Inquiry',
  isLoading,
  requestNegotiationAccessCheckboxValue,
  setRequestNegotiationAccessCheckboxValue,
  ...rest
}: Props) => {
  const theme = useTheme();

  const isInvestor = useIsInvestor();
  const isBrokerObserver = useIsBrokerObserver();

  const { cardRows } = getMarketOrdersListConfig({
    isReadOnlyBroker: !isInvestor && isBrokerObserver,
    isBrokerAccountManager: false,
    isInvestor,
    theme,
  });

  return (
    <Modal
      title={title}
      cancelText={'Cancel'}
      okText={okText}
      destroyOnClose={true}
      size="md"
      okButtonProps={{ loading: isLoading }}
      {...rest}
    >
      <S.Wrapper>
        <MarketOrderCard
          rows={cardRows}
          marketOrder={orderItem}
          header={{ companyLogoProps: { linkProps: { variant: 'logo' } } }}
          requestNegotiationAccessCheckboxValue={
            requestNegotiationAccessCheckboxValue
          }
          setRequestNegotiationAccessCheckboxValue={
            setRequestNegotiationAccessCheckboxValue
          }
        />
      </S.Wrapper>
    </Modal>
  );
};

import { useMemo, useState } from 'react';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useModal } from '@npm/core/ui/components/molecules/Modal';
import { SuccessModal } from '@npm/core/ui/components/organisms/SuccessModal';
import type { SecondmarketOrderItemShow } from '@npm/data-access';

import { useZendesk, ZendeskCustomFieldName } from '../../../app/zendesk';
import { toShares } from '../../../order';

import { MoreInfoConfirmationModal } from './components/MoreInfoConfirmationModal';
import { RequestMoreInformationContext } from './RequestMoreInformation.context';

type Props = {
  children: React.ReactNode;
};

export const RequestMoreInformationHandler = ({ children }: Props) => {
  const [order, setOrder] = useState<SecondmarketOrderItemShow>(null);
  const [openSuccessModal, successModalProps] = useModal();
  const { createCustomerRequest, createRequestLoading } = useZendesk();
  const { showAlert } = useAlerts();

  const [
    requestNegotiationAccessCheckboxValue,
    setRequestNegotiationAccessCheckboxValue,
  ] = useState(false);

  const sendRequest = async () => {
    const { success, message } = await createCustomerRequest(
      'second_market_market_feed',
      `I would like to know more about order #${order.id}${
        requestNegotiationAccessCheckboxValue
          ? ' AND I would also like to request Level 2 access for online negotiation.'
          : ''
      }`,
      {
        [ZendeskCustomFieldName.CompanyName]: order.issuer_entity?.name,
        [ZendeskCustomFieldName.Side]: order.order_side?.code,
        [ZendeskCustomFieldName.SizeUsd]: order.quantity,
        [ZendeskCustomFieldName.PricePerShare]: order.price,
        [ZendeskCustomFieldName.SizeShares]: order.price
          ? toShares(order.price, order.price)
          : undefined,
      }
    );
    if (success) {
      openSuccessModal();
      setOrder(null);
    } else {
      showAlert(message, {
        type: 'error',
      });
    }
  };

  const context = useMemo(() => {
    return {
      requestMoreInformation: order => {
        setOrder(order);
      },
    };
  }, []);

  return (
    <>
      <MoreInfoConfirmationModal
        orderItem={order}
        open={!!order}
        onCancel={() => setOrder(null)}
        onOk={sendRequest}
        isLoading={createRequestLoading}
        requestNegotiationAccessCheckboxValue={
          requestNegotiationAccessCheckboxValue
        }
        setRequestNegotiationAccessCheckboxValue={
          setRequestNegotiationAccessCheckboxValue
        }
      />
      <SuccessModal
        {...successModalProps}
        icon="circle-check"
        title="Your request was successfully sent"
        subTitle="Our team will contact you soon"
      />
      <RequestMoreInformationContext.Provider value={context}>
        {children}
      </RequestMoreInformationContext.Provider>
    </>
  );
};

import React from 'react';

import { type ButtonProps, Button } from '@npm/core/ui/components/atoms/Button';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { MarketFeedDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { composeDataId } from '@npm/core/ui/utils/cypress';
import { type SecondmarketOrderItemShow } from '@npm/data-access';

import { useIsBrokerObserver } from '../../../../../auth/user/role/hooks/useIsBrokerObserver';
import { getOrderItemInfo } from '../../../../../order';
import { RequestMoreInformationButton } from '../../../../components';

import { ActionsContainer } from './ActionsContainer';
import { useCopyOrderItemLink } from './BrokerMarketOrderActions.hooks';

export type MarketOrderActionsProps = {
  orderItem: SecondmarketOrderItemShow;
  brokerageFirmId: number;
  handleShowOrderDetails: (orderItemId: number) => void;
  handleEditOrder: ((orderItemId: number) => void) | undefined;
  handleCancelOrder: ((orderItemId: number) => void) | undefined;
  handleShowNegotiations: (orderItem: SecondmarketOrderItemShow) => void;
  handleStartNegotiation:
    | ((orderItem: SecondmarketOrderItemShow) => void)
    | undefined;
  buttonSize?: ButtonProps['size'];
};

export const BrokerMarketOrderActions = ({
  orderItem,
  brokerageFirmId,
  handleShowOrderDetails,
  handleEditOrder,
  handleCancelOrder,
  handleShowNegotiations,
  handleStartNegotiation,
  buttonSize = 'md',
}: MarketOrderActionsProps) => {
  const { isMobile } = useBreakpoints();

  const isBrokerObserver = useIsBrokerObserver();

  const {
    isSpvStructure,
    isForwardStructure,
    isExternal,
    isInternal,
    isSoftIoi,
  } = getOrderItemInfo(orderItem);

  const isDifferentBrokerageFirm =
    orderItem.account?.brokerage_firm?.id !== brokerageFirmId;

  const handleCopyLink = useCopyOrderItemLink(orderItem.id);

  if (!brokerageFirmId) return null;

  const dropdownTrigger = (
    <Button
      data-cy={composeDataId(
        MarketFeedDataIds.Table.Actions.OtherActions,
        orderItem.id
      )}
      variant="text"
      size={buttonSize}
      icon={<Icon name="dots-vertical" />}
    />
  );

  const copyLinkDropdownItem = {
    label: 'Copy Link',
    onClick: () => handleCopyLink(),
  };

  const copyLinkDropdown = (
    <Dropdown items={[copyLinkDropdownItem]}>{dropdownTrigger}</Dropdown>
  );

  if (isBrokerObserver) {
    if (isDifferentBrokerageFirm) {
      return (
        <ActionsContainer>
          <RequestMoreInformationButton
            variant={'default'}
            size={buttonSize}
            orderItem={orderItem}
            title="Connect with NPM"
            style={{ flexGrow: 1 }}
          />
          {copyLinkDropdown}
        </ActionsContainer>
      );
    }

    return (
      <ActionsContainer>
        <Tooltip title={'Access to Order Details is restricted for your role.'}>
          <Button
            size={buttonSize}
            variant={'outline'}
            style={{ flexGrow: 1 }}
            disabled
          >
            Show Details
          </Button>
        </Tooltip>
        {copyLinkDropdown}
      </ActionsContainer>
    );
  }

  if (isDifferentBrokerageFirm) {
    if (isSoftIoi || isSpvStructure) {
      return (
        <ActionsContainer>
          <RequestMoreInformationButton
            size={buttonSize}
            orderItem={orderItem}
            title="Connect with NPM"
            style={{ flexGrow: 1 }}
          />
          {!isMobile && isInternal && (
            // Placeholder to keep the buttons aligned
            <Button
              variant="text"
              size={buttonSize}
              icon={<Icon name="dots-vertical" />}
              style={{ visibility: 'hidden' }}
              disabled
            />
          )}
          {isExternal && copyLinkDropdown}
        </ActionsContainer>
      );
    }
    return (
      <ActionsContainer>
        {orderItem.negotiations_total > 0 ? (
          <Button
            variant="outline"
            size={buttonSize}
            onClick={() => handleShowNegotiations(orderItem)}
            style={{ flexGrow: 1 }}
            data-cy={composeDataId(
              MarketFeedDataIds.Table.Actions.InNegotiation,
              orderItem.id
            )}
          >
            In Negotiation ({orderItem.negotiations_total})
          </Button>
        ) : (
          <Button
            size={buttonSize}
            onClick={() => handleStartNegotiation?.(orderItem)}
            disabled={!handleStartNegotiation}
            style={{ flexGrow: 1 }}
            data-cy={composeDataId(
              MarketFeedDataIds.Table.Actions.StartNegotiation,
              orderItem.id
            )}
          >
            Negotiate
          </Button>
        )}
        {isExternal && copyLinkDropdown}
        {!isMobile && isInternal && (
          // Placeholder to keep the buttons aligned
          <Button
            variant="text"
            size={buttonSize}
            icon={<Icon name="dots-vertical" />}
            style={{ visibility: 'hidden' }}
            disabled
          />
        )}
      </ActionsContainer>
    );
  }

  const editCTA = (
    <Button
      variant="outline"
      color="info"
      size={buttonSize}
      onClick={() => handleEditOrder?.(orderItem.id)}
      disabled={!handleEditOrder}
      style={{ flexGrow: 1 }}
      data-cy={composeDataId(
        MarketFeedDataIds.Table.Actions.EditOrder,
        orderItem.id
      )}
    >
      Edit Order
    </Button>
  );

  const negotiationsCTA = (
    <Button
      variant="outline"
      color="info"
      size={buttonSize}
      onClick={() => handleShowNegotiations(orderItem)}
      style={{ flexGrow: 1 }}
      data-cy={composeDataId(
        MarketFeedDataIds.Table.Actions.InNegotiation,
        orderItem.id
      )}
    >
      Negotiations ({orderItem.negotiations_total})
    </Button>
  );

  const editDropdownItem = {
    label: 'Edit Order',
    onClick: () => handleEditOrder?.(orderItem.id),
    disabled: !handleEditOrder,
    'data-cy': composeDataId(
      MarketFeedDataIds.Table.Actions.EditOrder,
      orderItem.id
    ),
  };

  const showDetailsDropdownItem = {
    label: 'Show Order Details',
    onClick: () => handleShowOrderDetails(orderItem.id),
  };

  const cancelDropdownItem = {
    label: 'Cancel Order',
    onClick: () => handleCancelOrder?.(orderItem.id),
    disabled: !handleCancelOrder || !orderItem.cancellable,
    danger: true,
    'data-cy': composeDataId(
      MarketFeedDataIds.Table.Actions.CancelOrder,
      orderItem.id
    ),
  };

  const startNegotiationDropdownItem = {
    'data-cy': composeDataId(
      MarketFeedDataIds.Table.Actions.StartNegotiation,
      orderItem.id
    ),
    label: 'Match Internal',
    onClick: () => handleStartNegotiation?.(orderItem),
    disabled: !handleStartNegotiation,
  };

  if (isSpvStructure || isForwardStructure) {
    if (orderItem.cancellable) {
      return (
        <ActionsContainer>
          {editCTA}
          <Dropdown
            items={[
              ...(isForwardStructure ? [startNegotiationDropdownItem] : []),
              copyLinkDropdownItem,
              showDetailsDropdownItem,
              cancelDropdownItem,
            ]}
          >
            {dropdownTrigger}
          </Dropdown>
        </ActionsContainer>
      );
    }

    return (
      <ActionsContainer>
        {isForwardStructure && negotiationsCTA}
        <Dropdown
          items={[
            copyLinkDropdownItem,
            showDetailsDropdownItem,
            editDropdownItem,
          ]}
        >
          {React.cloneElement(dropdownTrigger, {
            style: { marginLeft: 'auto' },
          })}
        </Dropdown>
      </ActionsContainer>
    );
  }

  if (isExternal && orderItem.cancellable) {
    return (
      <ActionsContainer>
        {editCTA}
        <Dropdown
          items={[
            ...(isSoftIoi ? [] : [startNegotiationDropdownItem]),
            copyLinkDropdownItem,
            showDetailsDropdownItem,
            cancelDropdownItem,
          ]}
        >
          {dropdownTrigger}
        </Dropdown>
      </ActionsContainer>
    );
  }

  if (isExternal && !orderItem.cancellable) {
    return (
      <ActionsContainer>
        {negotiationsCTA}
        <Dropdown
          items={[
            ...(isSoftIoi ? [] : [startNegotiationDropdownItem]),
            copyLinkDropdownItem,
            showDetailsDropdownItem,
            editDropdownItem,
          ]}
        >
          {dropdownTrigger}
        </Dropdown>
      </ActionsContainer>
    );
  }

  if (isInternal && orderItem.cancellable) {
    return (
      <ActionsContainer>
        {editCTA}
        <Dropdown
          items={[
            ...(isSoftIoi ? [] : [startNegotiationDropdownItem]),
            showDetailsDropdownItem,
            cancelDropdownItem,
          ]}
        >
          {dropdownTrigger}
        </Dropdown>
      </ActionsContainer>
    );
  }

  if (isInternal && !orderItem.cancellable) {
    return (
      <ActionsContainer>
        {negotiationsCTA}
        <Dropdown
          items={[
            ...(isSoftIoi ? [] : [startNegotiationDropdownItem]),
            showDetailsDropdownItem,
            editDropdownItem,
          ]}
        >
          {dropdownTrigger}
        </Dropdown>
      </ActionsContainer>
    );
  }

  return null;
};

import React, { createContext, useContext, useEffect } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { useDrawer } from '@npm/core/ui/components/organisms/Drawer';

export const COMPANY_SELECT_INIT_STATE_KEY = 'isCompanySelectDrawerOpen';

interface CompanySelectDrawerContext {
  isOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  toggleDrawer: () => void;
}

export const useCompanySelectDrawerContext = () => {
  const context = useContext(CompanySelectDrawerContext);

  if (!context) {
    throw new Error(
      'useCompanySelectDrawerContext must be used within an CompanySelectDrawerProvider'
    );
  }

  const [isOpenInitially, setIsOpenInitially] = useQueryParam(
    COMPANY_SELECT_INIT_STATE_KEY,
    BooleanParam
  );

  useEffect(() => {
    if (isOpenInitially) {
      context.openDrawer();
      setIsOpenInitially(undefined, 'replaceIn');
    }
  }, [isOpenInitially]);

  return context;
};

export const CompanySelectDrawerContext = createContext<
  CompanySelectDrawerContext | undefined
>(undefined);

export const CompanySelectDrawerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const drawerProps = useDrawer();

  return (
    <CompanySelectDrawerContext.Provider value={drawerProps}>
      {children}
    </CompanySelectDrawerContext.Provider>
  );
};

import styled from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

export const Panel = styled.div`
  display: flex;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing.xs}px
    ${({ theme }) => theme.spacing.sm}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.xs}px;
  `}
`;

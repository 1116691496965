import React, { type ReactNode } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { eventBus } from '@npm/core/ui/utils/eventBus';

import * as S from './DisabledTradingBanner.styles';

type Props = {
  companyName: string;
  variant: 'horizontal' | 'vertical' | 'card';
  icon?: ReactNode;
};

export const DisabledTradingBanner: React.FC<Props> = ({
  companyName,
  variant,
  icon,
}) => {
  const renderedIcon = icon ?? (
    <Icon name="arrows-exchange-vertical" colorVariant="muted" />
  );

  const contactButton = (
    <Text
      color="info"
      weight="bold"
      size="sm"
      onClick={() =>
        eventBus.dispatch('REQUEST_SUPPORT', {
          key: 'disabled_trading',
          title: `${companyName} does not permit transfers at this time`,
        })
      }
    >
      Contact us with any questions
    </Text>
  );

  const descriptionText = `NPM has been informed by ${companyName} that all share transfers are
        being restricted for the time being. Thank you for your understanding
        and cooperation. We will let you know if anything changes. `;

  const title = (
    <>
      <S.BoldSpan>{companyName}</S.BoldSpan>
      {' does not permit transfers '}
      <S.UnderlinedSpan>at this time</S.UnderlinedSpan>
    </>
  );

  const flexDirection = variant === 'horizontal' ? 'row' : 'column';
  const flexJustify = variant === 'horizontal' ? undefined : 'center';

  const underContent =
    variant === 'horizontal' ? (
      <Text colorVariant="secondary" size="sm" align="left">
        {descriptionText}
        {contactButton}
      </Text>
    ) : null;

  const cardContent = variant === 'card' && (
    <>
      {renderedIcon}
      <S.TextContainer
        $align="center"
        $desktopFontSize="lg"
        $mobileFontSize="md"
      >
        {title}
      </S.TextContainer>
    </>
  );

  const verticalContent = variant === 'vertical' && (
    <>
      {renderedIcon}
      <S.TextContainer
        $align="center"
        $desktopFontSize="xl"
        $mobileFontSize="lg"
      >
        {title}
      </S.TextContainer>

      <Text colorVariant="secondary" size="sm" align="center">
        {descriptionText}
      </Text>

      {contactButton}
    </>
  );

  const horizontalContent = variant === 'horizontal' && (
    <>
      {renderedIcon}
      <S.TextContainer $align="left" $desktopFontSize="xl" $mobileFontSize="lg">
        {title}
      </S.TextContainer>
    </>
  );

  return (
    <Card flex={false}>
      <Flex
        gap="md"
        direction={flexDirection}
        align="center"
        justify={flexJustify}
      >
        {cardContent}
        {verticalContent}
        {horizontalContent}
      </Flex>
      {underContent}
    </Card>
  );
};

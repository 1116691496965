import React, { useCallback, useEffect } from 'react';
import {
  type FormItemProps,
  type FormProps as AntdFormProps,
  Form as AntdForm,
} from 'antd';
import { type ValidateStatus } from 'antd/lib/form/FormItem';
import { throttle } from 'lodash';

import { useBreakpoints } from '../../../hooks/useBreakpoints';

import { isInViewport } from './Form.utils';

type Props<T> = Omit<AntdFormProps<T>, 'children'> & {
  children?: React.ReactNode;
};

export const Form = <T,>({ onFinish, form, ...formProps }: Props<T>) => {
  const { isMobile, isTablet } = useBreakpoints();

  const onFinishWrapper = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => {
      return onFinish?.(value); // here we can do something as trim value
    },
    [onFinish]
  );

  const isFormFocused = React.useRef(false);

  useEffect(() => {
    if (!isMobile && !isTablet) return;

    const isFormActive = () => {
      return (
        !!document.activeElement &&
        document.activeElement !== document.body &&
        isFormFocused.current
      );
    };

    // 1. Close the keyboard on scoll when activeElement is not visible anymore.
    const handleScroll = throttle(() => {
      try {
        if (!isFormActive() || !document.activeElement) return;
        const rect = document.activeElement.getBoundingClientRect();
        if (!isInViewport(rect)) {
          (document.activeElement as HTMLElement).blur?.();
        }
      } catch (error) {
        console.error(error);
      }
    }, 1000);

    document.addEventListener('scroll', handleScroll, true);

    // 2. Close the keyboard when a user swipes up or down. This is necessary when the user
    // cannot scroll up or down because they reached the end of the visual viewport.
    let touchStartY;

    const handleTouchStart = (event: TouchEvent) => {
      try {
        touchStartY = event.touches[0]?.clientY;
      } catch (error) {
        console.error(error);
      }
    };

    document.addEventListener('touchstart', handleTouchStart);

    const handleTouchMove = throttle((event: TouchEvent) => {
      try {
        if (!isFormActive()) return;
        const touchEndY = event.touches[0]?.clientY;
        const touchDiff = Math.abs((touchEndY ?? 0) - (touchStartY ?? 0));
        if (touchDiff > 180 && document.activeElement) {
          (document.activeElement as HTMLElement).blur?.();
        }
      } catch (error) {
        console.error(error);
      }
    }, 100);

    document.addEventListener('touchmove', handleTouchMove);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('scroll', handleScroll);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isMobile, isTablet, form]);

  return (
    <AntdForm
      onFinish={onFinishWrapper}
      form={form}
      onFocus={() => {
        isFormFocused.current = true;
      }}
      onBlur={() => {
        isFormFocused.current = false;
      }}
      {...formProps}
    />
  );
};

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
type AntdTypeFormItemInternal = <Values = unknown>(
  props: FormItemProps<Values>
) => React.ReactElement;

export interface AntdTypeFormItem extends AntdTypeFormItemInternal {
  useStatus: () => {
    status?: ValidateStatus;
  };
}

Form.Item = AntdForm.Item as AntdTypeFormItem;

Form.useForm = AntdForm.useForm;
Form.useFormInstance = AntdForm.useFormInstance;
Form.useWatch = AntdForm.useWatch;
Form.List = AntdForm.List;
Form.ErrorList = AntdForm.ErrorList;
Form.Provider = AntdForm.Provider;

export { hasOnlySpecificProperties } from './Form.utils';

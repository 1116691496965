export type DdContextVariableKey =
  | 'role_type'
  | 'investor_type'
  | 'sponsored_level'
  | 'premium_user'
  | 'npm_user'
  | 'npm_settlement_only_account';

type DdRoleType = 'investor' | 'broker' | 'issuer' | 'advisor';
export type DdWorkstation = 'investor' | 'brokerage' | 'issuer' | 'advisor';

export const DD_WORKSTATION_TO_ROLE_NAME: Record<DdWorkstation, DdRoleType> = {
  investor: 'investor',
  brokerage: 'broker',
  issuer: 'issuer',
  advisor: 'advisor',
};

import { Col, Row } from 'antd';
import pluralize from 'pluralize';
import { useTheme } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';

import { YourRoleDrawerSection } from '../../../../../../auth/user/context/YourRoleDrawerSection';
import { HoldingDetail } from '../HoldingDetail';
import { type HoldingsDistribution } from '../index';

type Props = {
  pricePerShare: number;
  accountId: number;
  holdingsDistribution: HoldingsDistribution;
};

export const SellIndicationOrdersCreation = ({
  pricePerShare,
  accountId,
  holdingsDistribution,
}: Props) => {
  const theme = useTheme();

  const totalOrdersCount = holdingsDistribution.length;

  const ordersCreatedCount = holdingsDistribution.reduce(
    (acc, holdingDistribution) =>
      holdingDistribution.variant === 'success' ? acc + 1 : acc,
    0
  );

  return (
    <div>
      <Row gutter={[theme.spacing.md, theme.spacing.lg]}>
        <Col span={24} style={{ paddingTop: theme.spacing.sm }}>
          <DrawerSection
            iconName="user"
            title="Account"
            content={
              <YourRoleDrawerSection accountId={accountId} showTitle={false} />
            }
          />
        </Col>
        <Col span={24}>
          <DrawerSection
            iconName="loader"
            title={`${ordersCreatedCount} out of ${totalOrdersCount} ${pluralize(
              'Orders',
              totalOrdersCount
            )} Created`}
            content={
              <Flex direction={'column'} gap={'sm'}>
                {holdingsDistribution.map(holdingDistribution => (
                  <HoldingDetail
                    key={holdingDistribution.holding.id}
                    holdingDistribution={holdingDistribution}
                    pps={pricePerShare}
                  />
                ))}
              </Flex>
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export const CypressDataIds = {
  Components: {
    AutoScrollingForm: {
      NextButton: 'autoscrolling-form-next-button',
    },
    UploadForm: {
      Dragger: 'upload-form-dragger',
    },
    Alert: {
      Container: 'alert-container',
    },
    NoDataArea: {
      Container: 'no-data-area-container',
      ActionButton: 'no-data-area-button',
    },
    Notification: {
      Success: 'notification-success',
      Error: 'notification-error',
    },
  },
  ReadOnly: {
    UpdateButton: 'update-button',
  },
  FileUpload: {
    Drawer: { UploadFileButton: 'drawer-upload-button' },
  },
  UserDropdown: {
    UserName: 'user-name',
  },

  Card: {
    ShowMore: 'card-show-more',
    CompanyCard: {
      component: 'company-card',
      header: 'company-card-header',
    },
  },
  CheckBox: {
    GeneralCheckBox: 'check-box',
  },
  Drawer: {
    CloseButton: 'drawer-close-button',
    SubmitButton: 'drawer-submit-button',
    Body: 'drawer-body',
    WatchlistButton: 'watchlist-star-button',
  },

  RoleSwitcher: {
    OpenButton: 'role-switcher-open-button',
    Drawer: {
      Content: 'role-switcher-drawer-content',
      CreateNewAccountButton: 'create-new-account',
    },
  },

  WorkstationSwitcher: {
    OpenButton: 'workstation-switcher-open-button',
    Dropdown: {
      Content: 'workstation-switcher-drawer-content',
    },
  },

  Holdings: {
    Form: {
      Name: 'holdings-form',
      CompanySearch: 'company-search',
      CollapsibleForm: {
        Section: 'collabsible-form',
        ItemHeader: 'collabsible-form-item-header',
      },
    },
    Table: {
      Name: 'holdings-table',
    },
    Cards: {
      Container: 'holdings-cards-container',
      Card: 'holding-card',
    },
    Button: {
      Reject: 'holding-button-reject',
      Verify: 'holding-button-verify',
      Revert: 'holding-button-revert',
      Edit: 'holding-button-edit',
      Delete: 'holding-button-delete',
      CorrectionInstructions: 'holding-button-correction-instructions',
    },
    Properties: {
      State: 'holding-state',
      UniqueId: 'holding-unique-id',
    },
    Filters: {
      Search: 'holdings-filters-search',
    },
    Search: {
      Name: 'holding-search',
      AddNewHolding: 'holding-search-add-holding',
    },
    Drawer: {
      SubmitButton: 'holding-drawer-submit-button',
    },
  },

  Agreements: {
    Table: {
      Name: 'agreements-table',
    },
    Card: {
      Name: 'agreement-card',
      Header: 'agreement-card-header',
      Body: 'agreement-card-body',
    },
    Tab: {
      Signed: 'signed-tab',
      Unsigned: 'unsigned-tab',
    },
    Buttons: {
      FilePreview: 'agreements-file-preview-button',
      History: 'agreements-history-button',
    },
    HistoryDrawer: {
      Buttons: {
        FilePreview: 'agreements-history-file-preview-button',
      },
      List: {
        Name: 'agreements-history-list',
        Item: 'agreements-history-list-item',
      },
    },
    ActionButtons: 'agreements-action-buttons',
  },
  DocumentPreview: {
    Modal: 'document-preview-modal',
  },
  BackgroundCheck: {
    EditButton: 'edit-status',
    ResetButton: 'reset-status',
    RunButton: 'run-background-check',
    Status: {
      Pass: 'passed',
      Fail: 'failed',
      Modal: 'status-modal',
    },
  },
  Accreditation: {
    ResetButton: 'reset-accreditation',
    ResetConfirmationDialog: 'reset-accreditation-confirmation',
    Label: 'accreditation-label',
  },

  Filters: {
    CompanySearch: 'company-name-filter',
    AssetTypeSelect: 'asset-type-select',
  },

  InvestorWorkstation: {
    Holdings: {
      AddHolding: 'add-holding',
    },
    LivePrograms: {
      ProgramCard: {
        Card: 'program-card',
        AccessButton: 'program-card-access-button',
      },
      Overview: {
        DeleteButton: 'Delete-Submission-Icon',
      },
    },
    Nda: {
      NdaSigned: 'nda-signed',
    },
    Acknowledgement: {
      GetStartedButton: 'acknowledgement-get-started-button',
      Modal: 'acknowledgement-modal',
      AcceptButton: 'acknowledgement-accept-button',
    },

    ProgramSubmission: {
      NextStepButton: 'onboarding-next-step-button',
    },
  },

  IssuerWorkstation: {
    manageTransfer: 'manage-transfer-cta',
    actions: {
      approve: 'approve-action',
      assign: 'reject-action',
      block: 'block-action',
    },
    assignBuyer: 'assign-buyer',
    assignBuyerDrawerSubmit: 'assign-buyer-drawer-submit',
  },

  OboModal: {
    OboButton: 'obo-button',
    OboExitButton: 'obo-exit-button',
    OpenButton: 'obo-modal-open-button',
    ConfirmButton: 'obo-modal-confirm-button',
    SelectAccount: {
      SearchInput: 'obo-modal-select-account-search-input',
      Table: 'obo-modal-select-account-table',
      Cards: 'obo-modal-select-account-cards',
    },
  },

  Onboarding: {
    NextStepButton: 'onboarding-next-step-button',
    PrevStepButton: 'onboarding-prev-step-button',
    LeaveButton: 'onboarding-leave-button',
    ManualAddressButton: 'onboarding-manual-address-button',
    Accreditation: {
      SignAgreementButton: 'onboarding-signature-button',
    },
    AccountSetup: {
      Step: 'onboarding-account-setup',
    },
    EntityInformation: {
      Step: 'onboarding-entity-information',
    },
    IndividualInformation: {
      Step: 'onboarding-individual-information',
    },
  },

  PostOnboarding: {
    GetStartedButton: 'get-started-button',
    NextStepButton: 'onboarding-next-step-button',
    PrevStepButton: 'onboarding-prev-step-button',
    EntityInformation: {
      Step: 'post-onboarding-entity-information',
    },
    TaxInformation: {
      Step: 'post-onboarding-tax-information',
      Code: 'code',
    },
    EntityTaxId: {
      Step: 'post-onboarding-entity-tax-id',
      TaxId: 'tax-id',
    },
    FormationDocument: {
      Step: 'post-onboarding-formation-document',
      UploadButton: 'formation-document-upload-button',
      AuthorizedRadioButton: 'authorized',
    },
    AuthorizedSigner: {
      AddAnotherSignerButton: 'add-another-signer-button',
    },
    BeneficialOwnershipAssessment: {
      Step: 'beneficial-ownership-assessment-state',
      ExceptRadioButton: 'excempt-checkbox',
      AddIndividualButton: 'add-individual-button',
      GovernmentIssuedIdButton: 'government-issued-id-button',
      CompleteAssessmentButton: 'complete-assessment-button',
      Drawer: {
        DrawerForm: 'government-id-form',
      },
      GovernmentIssuedID: {
        FileUpload: 'government-issued-id-form-file-upload',
      },
    },
    BankAccountInformation: {
      Step: 'post-onboarding-bank-account-information',
      BankType: 'bank-type-dropdown',
    },
    MyHoldings: {
      IoISellButton: 'my-holdings-sell-button',
    },
  },

  Modal: {
    Submit: 'submit-modal-button',
  },

  MenuMobile: {
    Button: 'mobile-menu-button',
  },

  Loading: {
    FullScreenLoading: 'full-screen-loading-wrapper',
    Loader: 'loader',
  },

  AccountsPage: {
    newAccountButton: 'new-account-button',
    table: 'data-table',
    firstName: 'first',
    legalName: 'name',
    middleName: 'middle',
    lastName: 'last',
    email: 'email',
  },

  Entities: {
    accountId: 'external_id',
    submitButton: 'entity-submit-button',
  },

  Individuals: {
    accountId: 'id',
    submitButton: 'individual-submit-button',
  },

  DataRooms: {
    AddFolderDrawer: {
      TriggerButton: 'dataroom-add-folder-button',
      Form: 'dataroom-add-folder-form',
      SubmitButton: 'add-folder-button',
    },
    EditFolderDrawer: {
      TriggerButton: 'dataroom-edit-folder-button',
      Form: 'dataroom-edit-folder-form',
      SubmitButton: 'edit-folder-button',
    },
    EditDocumentDrawer: {
      TriggerButton: 'dataroom-edit-document-button',
      Form: 'dataroom-edit-document-form',
      SubmitButton: 'edit-document-button',
    },
    DeleteFolderModal: {
      TriggerButton: 'dataroom-delete-folder-button',
    },
    DeleteFileModal: {
      TriggerButton: 'dataroom-delete-file-button',
    },
    UploadDrawer: {
      Drawer: 'dataroom-upload-drawer',
      TriggerButton: 'dataroom-upload-button',
      Form: 'dataroom-upload-form',
      SubmitButton: 'upload-button',
    },
    FileExplorer: {
      Card: 'dataroom-file-explorer-card',
      RowDropdownTrigger: 'dataroom-row-dropdown-trigeer',
      FolderRow: 'dataroom-folder-row',
      DocumentRow: 'dataroom-document-row',
      DocumentDownloadTrigger: 'dataroom-document-download-trigger',
      HomeButton: 'dataroom-home-button',
    },
  },
  OrderEntryDrawer: {
    Drawer: 'order-entry-drawer',
    Open: 'place-an-order-button',
    Preview: 'preview-order-button',
    Submit: 'submit-order-button',
    ReviewCheckBox: 'checkbox-review',
    Save: 'save-order-button',
    Cancel: 'cancel-order-button',
    AcknowledgementModal: {
      Signature: 'acknowledgement-modal-signature',
      Submit: 'acknowledgement-modal-submit',
      LegalConfirmationA: 'legal-confirmation-a',
      LegalConfirmationB: 'legal-confirmation-b',
    },
  },
  Preplatform: {
    EmployeCompanySelect: 'employee-company-select',
  },
  DrawerConfirmDialog: {
    Dialog: 'drawer-confirm-dialog',
    ConfirmButton: 'drawer-confirm-dialog-confirm-button',
    CancelButton: 'drawer-confirm-dialog-cancel-button',
  },
};

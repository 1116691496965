import { useMemo } from 'react';
import { Col, Row } from 'antd';
import { useTheme } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import {
  type BrokerageFirmAggregate,
  type Codebook,
  type Holding,
  type OrderSourceCode,
  CbOrderEntryType,
} from '@npm/data-access';

import { YourRoleDrawerSection } from '../../../../../auth/user/context/YourRoleDrawerSection';
import { type OrderSizeType } from '../../../../../order';
import { type HoldingsDistribution } from '../../../../individual-investor-landing/SellIndication/EnterSellIndication/SellIndicationDrawer';
import { HoldingDetail } from '../../../../individual-investor-landing/SellIndication/EnterSellIndication/SellIndicationDrawer/HoldingDetail';
import { HoldingCard } from '../../../components/HoldingCard';
import { canSelectATSPool } from '../../../utils/order';
import type { Action, OrderPlacementFormValues } from '../..';

import { NextStepsSection } from './NextStepsSection';
import { OrderDetailsSection } from './OrderDetailsSection';

import { OrderPlacementConfirmationContainer } from './OrderPlacementConfirmation.styles';

type Props = {
  values: OrderPlacementFormValues;
  errorMessage?: string;
  activeAction: Action;
  holdingsDistribution?: HoldingsDistribution;
  holding?: Holding;
  orderEntryTypes: Codebook[];
  orderSizeType: OrderSizeType;
  brokerageFirm: BrokerageFirmAggregate | undefined;
  source: OrderSourceCode | undefined;
  showNextStepsSection?: boolean;
};

export const OrderPlacementConfirmation = ({
  values,
  errorMessage,
  activeAction,
  holdingsDistribution,
  holding,
  orderEntryTypes,
  orderSizeType,
  brokerageFirm,
  source,
  showNextStepsSection,
}: Props) => {
  const theme = useTheme();

  const orderType =
    orderEntryTypes.find(({ code }) => code === values?.orderType)?.name ??
    values?.orderType;

  const assetType = useMemo(() => {
    if (holding) {
      return holding?.asset?.type?.code;
    }

    if (holdingsDistribution?.length) {
      const assetType = holdingsDistribution[0].holding?.asset?.type?.code;

      return holdingsDistribution.every(
        holding => holding.holding.asset.type.code === assetType
      )
        ? assetType
        : 'Multiple';
    }
    return undefined;
  }, [holding, holdingsDistribution]);

  if (activeAction === 'sell' && !holding && !holdingsDistribution) {
    return null;
  }

  return (
    <OrderPlacementConfirmationContainer>
      <Row gutter={[theme.spacing.md, theme.spacing.lg]}>
        <Col span={24} style={{ paddingTop: theme.spacing.sm }}>
          <YourRoleDrawerSection
            accountId={values?.accountId}
            transactionRole={
              activeAction === 'buy' ? 'Investor' : 'Stakeholder'
            }
          />
        </Col>
        <Col span={24}>
          <OrderDetailsSection
            values={{
              ...values,
              orderType: values?.orderType || CbOrderEntryType.items.ioi,
              assetType: activeAction === 'buy' ? values?.assetType : assetType,
            }}
            source={source}
            errorMessage={errorMessage}
            activeAction={activeAction}
            orderSizeType={orderSizeType}
            showATSPool={
              brokerageFirm &&
              canSelectATSPool(values?.visibility, brokerageFirm)
            }
            brokerageFirm={brokerageFirm}
          />
        </Col>
        {holdingsDistribution && (
          <Col span={24}>
            <DrawerSection
              iconName="info-circle"
              title={'Your Holdings'}
              content={
                <Flex direction={'column'} gap={'sm'}>
                  {holdingsDistribution.map(holdingDistribution => (
                    <HoldingDetail
                      key={holdingDistribution.holding.id}
                      holdingDistribution={holdingDistribution}
                      pps={values.pricePerShare}
                    />
                  ))}
                </Flex>
              }
            />
          </Col>
        )}

        {holding && (
          <Col span={24}>
            <DrawerSection
              iconName="info-circle"
              title="Your Holding"
              content={<HoldingCard holding={holding} header={true} />}
            />
          </Col>
        )}

        {showNextStepsSection && (
          <Col span={24}>
            <NextStepsSection orderType={orderType} orderSide={activeAction} />
          </Col>
        )}
      </Row>
    </OrderPlacementConfirmationContainer>
  );
};

import React, { type ComponentProps } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import type { SecondmarketOrderItemShow } from '@npm/data-access';

import { RequestMoreInformationContext } from './RequestMoreInformation.context';

type Props = {
  orderItem: SecondmarketOrderItemShow;
  title?: string;
} & ComponentProps<typeof Button>;

export const RequestMoreInformationButton = ({
  orderItem,
  title = 'Request More Information',
  ...rest
}: Props) => {
  return (
    <RequestMoreInformationContext.Consumer>
      {value => (
        <Button
          variant={'outline'}
          color={'info'}
          onClick={() => value.requestMoreInformation(orderItem)}
          {...rest}
        >
          {title}
        </Button>
      )}
    </RequestMoreInformationContext.Consumer>
  );
};

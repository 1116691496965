import React, { useContext } from 'react';

import type { SecondmarketOrderItemShow } from '@npm/data-access';

export const RequestMoreInformationContext = React.createContext<{
  requestMoreInformation: (order: SecondmarketOrderItemShow) => void;
}>({
  requestMoreInformation: null,
});

export const useRequestMoreInformationContext = () => {
  const context = useContext(RequestMoreInformationContext);

  if (!context) {
    throw new Error(
      'useRequestMoreInformationContext must be used within a RequestMoreInformationContext.Provider'
    );
  }

  return context;
};

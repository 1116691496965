import Big from 'big.js';

import {
  type AffiliateDisclosureType,
  type Holding,
  type SecondmarketOrderItemUpdateRequestContract,
  type SecondmarketSubmissionCreateRequestContract,
  CbATSPool,
  CbOrderEntryType,
  CbOrderItemUnit,
  CbSubmissionStructure,
} from '@npm/data-access';

import { sizeInShares } from '../../../../order';
import { EXECUTION_FEE } from '../../../utils/investor/calculateEstimatedPrice';

import { type SellFormValues } from './SellIndicationDrawer';

export const getSubmitPayload = (
  values: SellFormValues,
  accountId: number,
  issuerEntityId: number,
  holding: Holding,
  quantity: number,
  affiliateDisclosure?: AffiliateDisclosureType,
  signature?: string
): SecondmarketSubmissionCreateRequestContract => {
  return {
    issuer_entity_id: issuerEntityId,
    account_id: accountId,
    order_entry_type: CbOrderEntryType.items.firm,
    ats_pool: CbATSPool.items.internal,
    structure: CbSubmissionStructure.items.direct,
    order_buy_sell: 'sell',
    gtc: true,
    expiration: undefined,
    asset_type: holding.asset.type,
    affiliate_disclosure: affiliateDisclosure,
    signature,
    holding_id: holding.id,
    holding_npm_guid: holding.npm_guid,
    order_item_unit: CbOrderItemUnit.items.SHARES,
    quantity,
    price: values.pricePerShare,
  };
};

export const getCalculatedValues = (
  quantity: number,
  pricePerShare?: number
) => {
  let estimatedGrossProceeds = 0;

  if (!quantity || !pricePerShare) {
    return { estimatedGrossProceeds: null, estimatedNetProceeds: null };
  } else {
    estimatedGrossProceeds = new Big(quantity).times(pricePerShare).toNumber();
  }

  const executionFee = estimatedGrossProceeds * EXECUTION_FEE;
  const estimatedNetProceeds = new Big(estimatedGrossProceeds)
    .minus(executionFee)
    .toNumber();

  return { estimatedGrossProceeds, estimatedNetProceeds };
};

export const getEditOrderPayload = (
  values: SellFormValues
): SecondmarketOrderItemUpdateRequestContract => ({
  order_item_unit: CbOrderItemUnit.items.SHARES,
  quantity: sizeInShares('Shares', values.quantity, values.pricePerShare),
  price: values.pricePerShare,
});

import { CompanyOverviewSummaryChartDataSetKey } from './SummaryChart.types';

export const SUMMARY_CHART_TOOLTIP_HTML_ID =
  'company-overview-summary-chart-tooltip';

export const getSummaryChartTooltip = (canvas: HTMLCanvasElement) => {
  return canvas.parentNode.querySelector(
    `#${SUMMARY_CHART_TOOLTIP_HTML_ID}`
  ) as HTMLElement;
};

export const getSummaryChartDataSetOrder = (
  dataSetKey: CompanyOverviewSummaryChartDataSetKey
) => {
  return summaryChartDataSetOrder[dataSetKey];
};

export const summaryChartDataSetOrder: Record<
  CompanyOverviewSummaryChartDataSetKey,
  number
> = {
  [CompanyOverviewSummaryChartDataSetKey.TRADES]: 1,
  [CompanyOverviewSummaryChartDataSetKey.BIDS]: 2,
  [CompanyOverviewSummaryChartDataSetKey.ASKS]: 3,
  [CompanyOverviewSummaryChartDataSetKey.MOVING_AVERAGE]: 4,
  [CompanyOverviewSummaryChartDataSetKey.VALUATIONS_409A]: 5,
  [CompanyOverviewSummaryChartDataSetKey.TAPE_D]: 6,
  [CompanyOverviewSummaryChartDataSetKey.PRIMARY_ROUNDS]: 7,
};

import React from 'react';
import { useTheme } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';

import {
  getBidsColor,
  getOffersColor,
  getTradesColor,
} from '../../BidOfferHistory/BidOfferHistory.utils';
import { type CompanyDataSectionKey } from '../../CompanyOverview.types';
import { CompanyDataSectionKeys } from '../../CompanyOverview.utils';
import { ChartLegendItem } from '../../components/ChartLegendItem';
import { ChartLineIcon } from '../../components/ChartLineIcon/ChartLineIcon';
import { PointLineIcon } from '../../components/PointLineIcon';
import { getMutualFundMarksColor } from '../../MutualFundMarks/MutualFundMarks.utils';
import { getPrimaryRoundsColor } from '../../PrimaryRounds/PrimaryRounds.utils';
import { getTapeDColor } from '../../TapeD/TapeD.utils';
import { getValuations409AColor } from '../../Valuations409A/Valuations409A.utils';

import { CompanyOverviewSummaryChartDataSetKey } from './SummaryChart.types';

const getOnClick = (sectionKey: CompanyDataSectionKey) => {
  return () => {
    document
      .querySelector(`#${sectionKey}`)
      ?.scrollIntoView({ behavior: 'auto', block: 'center' });
  };
};

type Props = {
  onHover: (key: CompanyOverviewSummaryChartDataSetKey) => void;
  onClear: () => void;
  showTapeD: boolean;
  showClosedTrades: boolean;
  showBids: boolean;
  showOffers: boolean;
  showPrimaryRounds: boolean;
  showValuations409A: boolean;
  showMutualFundMarks: boolean;
};

export const SummaryChartLegend = ({
  onHover,
  onClear,
  showTapeD,
  showClosedTrades,
  showBids,
  showOffers,
  showPrimaryRounds,
  showValuations409A,
  showMutualFundMarks,
}: Props) => {
  const theme = useTheme();

  return (
    <Flex gap="md" align="center" justify="center" $wrap={true}>
      {showTapeD && (
        <ChartLegendItem
          icon={<ChartLineIcon color={getTapeDColor(theme)} />}
          label={'Tape D Price'}
          onClick={getOnClick(CompanyDataSectionKeys.tape_d_price)}
          onHover={() => onHover(CompanyOverviewSummaryChartDataSetKey.TAPE_D)}
          onClear={onClear}
        />
      )}

      {showClosedTrades && (
        <ChartLegendItem
          icon={<Icon name="segment" size="xs" color={getTradesColor()} />}
          label={'Reported Trade Level'}
          onClick={getOnClick(CompanyDataSectionKeys.closed_trades)}
          onHover={() => onHover(CompanyOverviewSummaryChartDataSetKey.TRADES)}
          onClear={onClear}
        />
      )}

      {showBids && (
        <ChartLegendItem
          icon={<Icon name="segment" size="xs" color={getBidsColor(theme)} />}
          label={'Bid Level'}
          onClick={getOnClick(CompanyDataSectionKeys.bid_offer_history)}
          onHover={() => onHover(CompanyOverviewSummaryChartDataSetKey.BIDS)}
          onClear={onClear}
        />
      )}

      {showOffers && (
        <ChartLegendItem
          icon={<Icon name="segment" size="xs" color={getOffersColor(theme)} />}
          label={'Offer Level'}
          onClick={getOnClick(CompanyDataSectionKeys.bid_offer_history)}
          onHover={() => onHover(CompanyOverviewSummaryChartDataSetKey.ASKS)}
          onClear={onClear}
        />
      )}

      {showPrimaryRounds && (
        <ChartLegendItem
          icon={<PointLineIcon color={getPrimaryRoundsColor(theme)} />}
          label={'Primary Round'}
          onClick={getOnClick(CompanyDataSectionKeys.primary_rounds)}
          onHover={() =>
            onHover(CompanyOverviewSummaryChartDataSetKey.PRIMARY_ROUNDS)
          }
          onClear={onClear}
        />
      )}

      {showValuations409A && (
        <ChartLegendItem
          icon={<PointLineIcon color={getValuations409AColor(theme)} />}
          label={'409A Valuation'}
          onClick={getOnClick(CompanyDataSectionKeys.valuations409a)}
          onHover={() =>
            onHover(CompanyOverviewSummaryChartDataSetKey.VALUATIONS_409A)
          }
          onClear={onClear}
        />
      )}

      {showMutualFundMarks && (
        <ChartLegendItem
          icon={<ChartLineIcon color={getMutualFundMarksColor(theme)} />}
          label={'Mutual Fund Mark'}
          onClick={getOnClick(CompanyDataSectionKeys.mutual_fund_mark)}
          onHover={() =>
            onHover(CompanyOverviewSummaryChartDataSetKey.MOVING_AVERAGE)
          }
          onClear={onClear}
        />
      )}
    </Flex>
  );
};

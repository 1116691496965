import { type FormInstance, Col, Row } from 'antd';
import { useTheme } from 'styled-components';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import { type Holding } from '@npm/data-access';

import { YourRoleDrawerSection } from '../../../../../../auth/user/context/YourRoleDrawerSection';
import {
  HoldingDrawer,
  MissingPoOAlert,
  useHoldingDrawer,
} from '../../../../../../holdings';
import type { OrderSizeType } from '../../../../../../order';
import { HoldingCard } from '../../../../../drawers/components/HoldingCard';
import { TextLink } from '../../../../components';
import { HoldingDetail } from '../HoldingDetail';
import { ReviewOrderSection } from '../ReviewOrderSection';
import {
  type HoldingsDistribution,
  type SellFormValues,
} from '../SellIndicationDrawer.types';

type Props = {
  confirmationForm: FormInstance<SellFormValues>;
  values: SellFormValues | undefined;
  holding: Holding | undefined;
  holdingsDistribution: HoldingsDistribution | undefined;
  orderSizeType: OrderSizeType;
  accountId: number;
  errorMessage?: string | false;
  onUpdateHolding?: (holding: Holding) => void;
};

export const SellIndicationConfirmation = ({
  confirmationForm,
  values,
  holding,
  holdingsDistribution,
  orderSizeType,
  accountId,
  errorMessage,
  onUpdateHolding,
}: Props) => {
  const theme = useTheme();

  const [{ openEditHoldingDrawer }, holdingDrawerProps] = useHoldingDrawer({
    preselectedIssuerEntityId: values?.issuerEntityId,
    preselectedAccountId: values?.accountId,
  });

  const isMissingPoO = !holding?.proof_of_ownership_document;

  if (!values) {
    return null;
  }

  const drawerContent = (
    <Flex direction="column" gap="sm">
      <HoldingCard holding={holding} header={true} />
      {isMissingPoO && (
        <MissingPoOAlert
          description=""
          message={
            <Flex justify="space-between">
              <Flex>
                <Text size="sm">Missing Proof of Ownership</Text>
              </Flex>
              <Margin left="lg">
                <TextLink
                  as="span"
                  $color="warning"
                  onClick={() => openEditHoldingDrawer(holding?.id)}
                >
                  + Add
                </TextLink>
              </Margin>
            </Flex>
          }
        />
      )}
    </Flex>
  );

  return (
    <div>
      <Form form={confirmationForm}>
        <Row gutter={[theme.spacing.md, theme.spacing.lg]}>
          <Col span={24} style={{ paddingTop: theme.spacing.sm }}>
            <DrawerSection
              iconName="user"
              title="Account"
              content={
                <YourRoleDrawerSection
                  accountId={accountId}
                  showTitle={false}
                />
              }
            />
          </Col>
          <Col span={24}>
            {holdingsDistribution ? (
              <DrawerSection
                iconName="info-circle"
                title={'Your Holdings'}
                content={
                  <Flex direction={'column'} gap={'sm'}>
                    {holdingsDistribution.map(holdingDistribution => (
                      <HoldingDetail
                        key={holdingDistribution.holding.id}
                        holdingDistribution={holdingDistribution}
                        pps={values.pricePerShare}
                        onEditHolding={openEditHoldingDrawer}
                      />
                    ))}
                  </Flex>
                }
              />
            ) : (
              holding && (
                <DrawerSection
                  iconName="info-circle"
                  title="Your Holding"
                  content={drawerContent}
                />
              )
            )}
          </Col>
          <Col span={24}>
            <ReviewOrderSection
              values={values}
              orderSizeType={orderSizeType}
              errorMessage={errorMessage}
            />
          </Col>
        </Row>
      </Form>
      <HoldingDrawer onSuccess={onUpdateHolding} {...holdingDrawerProps} />
    </div>
  );
};

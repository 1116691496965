import styled from 'styled-components';

export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}px;

  padding: ${({ theme }) => theme.spacing.xs / 2}px
    ${({ theme }) => theme.spacing.sm}px;

  color: ${({ theme }) => theme.color.grey_0};
  background-color: ${({ theme }) =>
    theme.color.info.backgrounds.primaryDefault};
  border-radius: ${({ theme }) => theme.spacing.xs}px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12px;
  width: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.grey_0};
`;

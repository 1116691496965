import React, { type ComponentProps, useMemo } from 'react';

import { Select } from '@npm/core/ui/components/atoms/Select';
import {
  type CodebookItems,
  type CodebookShow,
  useCodebook,
} from '@npm/data-access';

import { sortCbSelectOptions } from './CodebookSelect.utils';

type Props = {
  codebook: CodebookItems;
  prefix?: string;
  filterOptions?: (
    value: CodebookShow,
    index: number,
    array: CodebookShow[]
  ) => boolean;
  pinOptions?: string[];
  appendOptions?: {
    label: string;
    value: string;
  }[];
} & ComponentProps<typeof Select>;

export const CodebooksSelect = ({
  codebook,
  prefix,
  filterOptions,
  pinOptions,
  appendOptions,
  showSearch = false,
  placeholder = 'Select',
  ...selectProps
}: Props) => {
  const { data, isLoading, error } = useCodebook(
    {
      type: codebook,
      prefix,
    },
    { queryConfig: { enabled: !!codebook }, onError: Select.onError }
  );

  const options = useMemo(() => {
    if (!data) return null;

    let codebooks = data.codebooks;
    if (filterOptions) {
      codebooks = codebooks.filter(filterOptions);
    }

    const options = [
      ...codebooks.map(({ name, code }) => ({
        label: name,
        value: code,
      })),
      ...(appendOptions ?? []),
    ];

    if (!pinOptions) return options;
    return sortCbSelectOptions(options, pinOptions);
  }, [data, filterOptions, appendOptions, pinOptions]);

  return (
    <Select
      allowClear
      loading={isLoading}
      placeholder={options ? `${placeholder} (${options.length})` : placeholder}
      options={options}
      error={error}
      showSearch={showSearch}
      {...selectProps}
    />
  );
};

import { Form } from 'antd';
import { addDays, endOfDay, format as formatDate } from 'date-fns';

import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { DateTimePickerInput } from '@npm/core/ui/components/atoms/Input';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { DATE_FORMATS, getTimezoneShort } from '@npm/core/ui/utils/formatters';
import {
  type OrderItemStateCode,
  type OrderSourceCode,
  type VisibilityCode,
  CbOrderSource,
  CbVisibility,
} from '@npm/data-access';

import { useIsInvestor } from '../../../../auth/user/role/hooks/useIsInvestor';

type Value = {
  goodTillCancelled?: boolean;
  expiration?: Date;
};

type Props = {
  value: Value;
  onItemChange: () => void;
  source: OrderSourceCode | undefined;
  visibility: VisibilityCode | undefined;
  state: OrderItemStateCode | undefined; // for historical only
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const ExpirationFormSection = ({
  value: { goodTillCancelled, expiration },
  onItemChange,
  source,
  visibility,
  state,
  ...props
}: Props) => {
  const isInvestor = useIsInvestor();
  const form = Form.useFormInstance<{
    expiration: Date;
    goodTillCancelled: boolean;
  }>();

  const isOptional =
    source === CbOrderSource.items.historical &&
    visibility === CbVisibility.items.internal;

  const isGtcDisabled =
    source === CbOrderSource.items.historical &&
    visibility === CbVisibility.items.external;

  if (isGtcDisabled && goodTillCancelled) {
    form.setFieldValue('goodTillCancelled', false);
  }

  return (
    <CollapsibleFormSection.Item
      label={`Expires At (${getTimezoneShort()})`}
      tooltipText="A GTC expires thirty (30) calendar days from the time of entry if not canceled, in an active negotiation, or fully matched. The expiration of a GTC will be extended by an additional 30 calendar days when it’s in an active negotiation. Internal GTC orders do not expire"
      preview={getExpirationPreview(
        goodTillCancelled,
        expiration,
        source,
        visibility
      )}
      optional={isOptional}
      key="expiration"
      name="expiration"
      {...props}
    >
      <>
        <FormItem
          name="expiration"
          dependencies={['goodTillCancelled']}
          marginBottom="sm"
        >
          <DateTimePickerInput
            max={new Date()}
            defaultTime="endOfDay"
            allowClear={isOptional}
            disabled={goodTillCancelled}
            onChange={() => {
              form.setFieldValue('goodTillCancelled', false);
            }}
            {...(source === CbOrderSource.items.historical
              ? {
                  max: state === 'expired' ? new Date() : undefined,
                }
              : {
                  min: new Date(),
                  max:
                    !isInvestor && visibility === CbVisibility.items.internal
                      ? undefined
                      : addDays(new Date(), 30),
                })}
          />
        </FormItem>
        <FormItem
          name="goodTillCancelled"
          valuePropName="checked"
          dependencies={['expiration']}
          marginBottom="sm"
          hidden={isGtcDisabled}
        >
          <Checkbox
            label="Good Till Cancelled"
            onChange={e => {
              if (e.target.checked) {
                form.setFieldValue('expiration', null);
                onItemChange();
              } else if (source !== CbOrderSource.items.historical) {
                const eod = endOfDay(new Date());
                form.setFieldValue('expiration', eod);
              }
            }}
            disabled={isGtcDisabled}
          />
        </FormItem>
      </>
    </CollapsibleFormSection.Item>
  );
};

export const getExpirationPreview = (
  goodTillCancelled: boolean,
  expiration: Date | undefined,
  source: OrderSourceCode | undefined,
  visibility: VisibilityCode | undefined
) => {
  return goodTillCancelled
    ? 'Good Till Cancelled'
    : expiration
    ? formatDate(expiration, DATE_FORMATS.FULL)
    : source !== CbOrderSource.items.historical ||
      visibility === CbVisibility.items.internal
    ? ''
    : 'Now';
};

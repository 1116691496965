import React, { type ReactNode, forwardRef, useEffect, useState } from 'react';
import {
  type RefSelectProps,
  type SelectProps as AntSelectProps,
  Select as AntSelect,
} from 'antd';
import { omit } from 'lodash';

import { type IconNames } from '@npm/utils';

import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { Icon } from '../Icon';
import { Loader } from '../Loader';
import { Text } from '../Typography';

import { NoData } from './NoData/NoData';
import { SearchPlaceholder } from './SearchPlaceholder/SearchPlaceholder';
import { SelectError } from './SelectError';

import * as S from './Select.styles';

type DropdownExtraProps = {
  title: React.ReactNode;
  onClick?: () => void;
  icon?: IconNames | React.ReactNode;
  placement?: 'top' | 'bottom';
  'data-cy'?: string;
};

export type SelectProps = AntSelectProps & {
  notFoundContent?: ReactNode | string;
  dropdownExtraProps?: DropdownExtraProps;
  variant?: 'select' | 'search';
  error?: unknown;
  refetch?: () => void;
  readOnly?: boolean;
  infiniteLoading?: boolean;
  showDropdownBelowInput?: boolean;
  ['data-dd-action-name']?: string;
  'data-cy'?: string;
};

const SelectWithRef = forwardRef<RefSelectProps, SelectProps>(
  function SelectWithRef(
    {
      variant = 'select',
      autoClearSearchValue = true,
      showSearch = true,
      allowClear,
      children,
      notFoundContent = 'No Data',
      mode,
      value,
      placeholder = 'Select',
      loading,
      infiniteLoading,
      dropdownExtraProps,
      optionLabelProp,
      error,
      refetch,
      onFocus,
      onSearch,
      onClear,
      readOnly,
      disabled,
      showDropdownBelowInput,
      ...rest
    },
    ref
  ) {
    const basePlaceholder =
      variant === 'search' ? (
        <SearchPlaceholder placeholder={placeholder} />
      ) : (
        placeholder
      );
    const { isMobile } = useBreakpoints();
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [placeholderNode, setPlaceholderNode] = useState(basePlaceholder);

    const renderNotFoundContent = () => {
      if (loading) return <Loader />;
      if (error) return <SelectError error={error} refetch={refetch} />;
      if (rest.searchValue) {
        return (
          <NoData
            showIcon={true}
            text={"We couldn't find any results with your search"}
          />
        );
      }
      if (typeof notFoundContent === 'string')
        return <NoData text={notFoundContent} />;

      return notFoundContent;
    };

    // Prevent scrolling when dropdown is visible
    useEffect(() => {
      const disableScroll = e => {
        if (isDropdownVisible) {
          e.preventDefault();
        }
      };

      window.addEventListener('touchmove', disableScroll, { passive: false });

      return () => {
        window.removeEventListener('touchmove', disableScroll);
      };
    }, [isDropdownVisible]);

    const handleClear = () => {
      onSearch?.('');
      onClear?.();
    };

    // TODO: remove redundant useEffect with NFE-6327
    useEffect(() => {
      setPlaceholderNode(placeholder);
    }, [placeholder]);

    return (
      <S.StyledSelect
        $isMobile={isMobile}
        $readOnly={readOnly}
        $isSearchVariant={variant === 'search'}
        $showDropdownBelowInput={showDropdownBelowInput}
        disabled={disabled}
        suffixIcon={variant !== 'search' && <Icon name={'chevron-down'} />}
        clearIcon={<Icon name="circle-x-filled" />}
        menuItemSelectedIcon={
          mode ? <Icon name="check" size={'xs'} /> : undefined
        }
        listHeight={(isMobile ? 150 : 300) - (infiniteLoading ? 64 : 0)}
        onDropdownVisibleChange={setDropdownVisible}
        dropdownRender={dropdown =>
          loading ? (
            <LoadingStateDropdown />
          ) : (
            <S.StyledDropdown>
              {dropdownExtraProps &&
                dropdownExtraProps?.placement === 'top' && (
                  <DropdownExtra {...dropdownExtraProps} />
                )}
              {dropdown}
              {dropdownExtraProps &&
                dropdownExtraProps?.placement !== 'top' && (
                  <DropdownExtra {...dropdownExtraProps} />
                )}
              {infiniteLoading && <LoadingStateDropdown />}
            </S.StyledDropdown>
          )
        }
        filterOption={
          showSearch && !onSearch
            ? (input, option) =>
                option?.label?.toLowerCase()?.includes(input.toLowerCase())
            : false
        }
        notFoundContent={renderNotFoundContent()}
        showSearch={
          rest?.options && rest.options.length < 6 && !rest.searchValue
            ? false
            : showSearch
        }
        showArrow
        mode={mode}
        maxTagCount={mode === 'multiple' || mode === 'tags' ? 2 : undefined}
        placeholder={placeholderNode}
        getPopupContainer={triggerNode => triggerNode.parentElement}
        onFocus={event => {
          setPlaceholderNode('');
          const ua = navigator.userAgent.toLowerCase();
          const isAndroid = ua.indexOf('android') > -1;
          // Android does not center inputs by default, so we need to do it manually
          if (isAndroid) {
            setTimeout(() => {
              (event.target as HTMLElement).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }, 300);
          }
          onFocus?.(event);
        }}
        onBlur={() => {
          setPlaceholderNode(basePlaceholder);
        }}
        onSearch={onSearch}
        // avoid "Invalid value for prop `submit`" warning
        {...omit(rest, 'submit')}
        value={value ?? (placeholder ? undefined : '')}
        ref={ref}
        allowClear={onSearch ? true : allowClear}
        optionLabelProp={onSearch ? 'label' : optionLabelProp}
        onClear={handleClear}
        {...(readOnly ? { open: false } : {})}
      >
        {children}
      </S.StyledSelect>
    );
  }
);

const LoadingStateDropdown = () => {
  return (
    <S.LoadingWrap>
      <Loader />
    </S.LoadingWrap>
  );
};

const DropdownExtra = ({
  title,
  onClick,
  icon = 'plus',
  'data-cy': dataCy,
}: DropdownExtraProps) => {
  return (
    <S.ExtraContainer onClick={onClick} data-cy={dataCy}>
      {typeof icon === 'string' ? (
        <Icon name={icon as IconNames} size="sm" />
      ) : (
        icon
      )}
      <Text size="sm" color="info">
        {title}
      </Text>
    </S.ExtraContainer>
  );
};

export const Select = Object.assign(SelectWithRef, {
  Option: AntSelect.Option,
  LoadingStateDropdown,
  onError: (e: unknown) => {
    console.error(e);
  },
});

import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { eventBus } from '@npm/core/ui/utils/eventBus';

type Props = {
  companyName: string;
};

export const DisabledTradingAlert: React.FC<Props> = ({ companyName }) => {
  return (
    <Margin bottom="md">
      <Alert
        message={
          <>
            NPM has been informed by {companyName} that all share transfers are
            being restricted for the time being. Thank you for your
            understanding and cooperation. We will let you know if anything
            changes.{' '}
            <Text
              color="info"
              weight="bold"
              size="sm"
              onClick={() =>
                eventBus.dispatch('REQUEST_SUPPORT', {
                  key: 'disabled_trading',
                  title: `${companyName} does not permit transfers at this time`,
                })
              }
            >
              Contact us with any questions
            </Text>
          </>
        }
      />
    </Margin>
  );
};

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { TwoRowsCell } from '@npm/core/ui/components/molecules/TwoRowsCell';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type IssuerEntityForOrderbookIssuerEntityStatistic } from '@npm/data-access';

export const LastRoundCell = ({
  issuerStatistics,
}: {
  issuerStatistics: IssuerEntityForOrderbookIssuerEntityStatistic;
}) => {
  return issuerStatistics && issuerStatistics?.latest_valuation ? (
    <TwoRowsCell
      firstRow={
        <Flex gap="xs">
          <Text.Price value={issuerStatistics?.latest_valuation} compact />
          <Text size="sm" colorVariant="muted">
            @
          </Text>
          <Text.Price
            value={issuerStatistics?.latest_valuation_preferred_price}
          />
        </Flex>
      }
      secondRowText={issuerStatistics?.latest_valuation_stock_class_name}
      secondRowExtra={formatDate(issuerStatistics?.latest_valuation_date, {
        dateFormat: DATE_FORMATS.DATE,
      })}
    />
  ) : (
    <>--</>
  );
};

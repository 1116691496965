import { type DefaultTheme } from 'styled-components';

import { type CompanyPricingApi } from '@npm/data-access';

export const getBidsColor = (theme: DefaultTheme) => {
  return theme.color.success.typography.primary;
};

export const getOffersColor = (theme: DefaultTheme) => {
  return theme.color.error.typography.primary;
};

export const getTradesColor = () => {
  return '#9147F0';
};

export const getLevelItems = (
  aggregatedItems: CompanyPricingApi.OrderPrice[],
  bestItems: CompanyPricingApi.OrderPrice[]
) => {
  const maxLength = Math.max(aggregatedItems.length, bestItems.length);

  return Array.from({ length: maxLength }, (_, index) => ({
    id: index,
    date: aggregatedItems[index]?.date,
    aggregated: aggregatedItems[index],
    best: bestItems[index],
  }));
};

import React, {
  type ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type ColProps, type FormInstance, Col, Row } from 'antd';
import { type DefaultTheme } from 'styled-components';
import { useIsFirstRender } from 'usehooks-ts';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { filtersCollapse } from '@npm/core/ui/utils/animations';

import { TotalRecordsLabel } from './components/TotalRecordsLabel';
import { FilterChip, FiltersToggleButton } from './components';

import * as S from './FilterPanel.styles';
import { type FilterPanelAnimationState } from './FilterPanel.styles';

export type FilterItemProps = {
  name?: string;
  onFilterSubmit?: (
    name: string | undefined,
    blurActiveElement?: boolean
  ) => void;
};

export type FilterPanelItem<T> = {
  name: keyof T;
  render: (args: FilterItemProps) => React.ReactElement;
  columnProps?: ColProps;
  alwaysVisible?: boolean;
  asExtraToggle?: boolean;
  'data-dd-action-name'?: string;
} & Omit<ComponentProps<typeof FormItem>, 'name'>;

type Props<T> = {
  filters: FilterPanelItem<T>[];
  form: FormInstance<T>;
  totalRecords?: number;
  totalRecordBackground?: keyof DefaultTheme['color']['general']['layout'];
  handleSubmit: (values: T) => void;
  isExpandable?: boolean;
  defaultColumnProps?: ColProps;
  // default filter values set by us
  // using `FilterPanel` alongside `usePersistedFilters`, be sure to pass `initialValues` there as well
  initialValues?: T;
  // initial filter values coming for example from the query params when user refreshes the page
  initialFilterValues?: T;
  footerExtra?: React.ReactNode;
  onValuesChange?: (name: string) => void;
  totalActiveFilters: number;
  renderCustomCollapsedContent?: (
    isExpanded: boolean,
    toggleIsExpanded: () => void
  ) => React.ReactNode;
  extraContent?: React.ReactNode;
  showClearButton?: boolean;
};

export const FilterPanel = <T,>({
  filters,
  form,
  totalRecords,
  totalRecordBackground = 'one',
  handleSubmit,
  isExpandable,
  defaultColumnProps = isExpandable
    ? { span: 24 }
    : { xs: 24, sm: 12, lg: 8, xl: 6 },
  initialValues,
  initialFilterValues,
  footerExtra,
  onValuesChange,
  totalActiveFilters,
  renderCustomCollapsedContent,
  extraContent,
  showClearButton = true,
}: Props<T>) => {
  const isFirstMount = useIsFirstRender();
  const { isMobile } = useBreakpoints();

  const [isExpanded, setIsExpanded] = useState(false);
  const [animationState, setAnimationState] =
    useState<FilterPanelAnimationState>('collapsed');

  const displayClearButton = showClearButton && totalActiveFilters > 0;

  const { extraToggleFilter, alwaysVisibleFilters, _restFilters } =
    useMemo(() => {
      // extra toggle filter next to the Filters toggle
      let extraToggleFilter;
      // always visible filters outside of expandable container
      const alwaysVisibleFilters = [];
      // rest of the filters
      const _restFilters = [];

      filters.forEach(f => {
        if (f.asExtraToggle) {
          extraToggleFilter = f;
        } else if (f.alwaysVisible) {
          alwaysVisibleFilters.push(f);
        } else {
          _restFilters.push(f);
        }
      });

      return { extraToggleFilter, alwaysVisibleFilters, _restFilters };
    }, [filters]);

  // Set initial form values from query params
  // initial values are not used, because form reset will use those values
  useEffect(() => {
    if (initialFilterValues) {
      form.setFields(
        Object.entries(initialFilterValues).map(([key, val]) => ({
          name: key,
          value: val,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _areFiltersExpandable =
    (isExpandable ?? isMobile) && _restFilters.length > 1;

  const mainFilters = useMemo(() => {
    if (_areFiltersExpandable) {
      if (renderCustomCollapsedContent) {
        return [];
      } else {
        // show only 1st filter if expandable
        return _restFilters.slice(0, 1);
      }
    } else {
      return _restFilters;
    }
  }, [_restFilters, _areFiltersExpandable, renderCustomCollapsedContent]);

  const collapsibleFilters = useMemo(() => {
    if (_areFiltersExpandable) {
      if (renderCustomCollapsedContent) {
        return _restFilters;
      } else {
        // all except 1st
        return _restFilters.slice(1);
      }
    } else {
      return [];
    }
  }, [_areFiltersExpandable, _restFilters, renderCustomCollapsedContent]);

  const onFinish = (values: T) => {
    handleSubmit(values);
  };

  const onFilterItemSubmit = useCallback(
    async (name: string, blurActiveElement = true) => {
      onValuesChange?.(name);

      try {
        await form.validateFields();
        form.submit();

        if (
          blurActiveElement &&
          document.activeElement instanceof HTMLElement
        ) {
          document.activeElement?.blur?.();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [form, onValuesChange]
  );

  const resetAll = () => {
    form.resetFields();
    form.submit();
    handleSubmit(undefined);
  };

  const renderFilterItem = ({ name, ...item }: FilterPanelItem<T>) => {
    return (
      <FormItem
        name={name as string}
        data-dd-action-name={`Filter Item - ${item.label}`}
        {...item}
      >
        {item.render({
          name: name as string,
          onFilterSubmit: onFilterItemSubmit,
        })}
      </FormItem>
    );
  };

  const renderToggleButtons = () => (
    <>
      <FiltersToggleButton
        onClick={() => setIsExpanded(v => !v)}
        isOpened={isExpanded}
        count={totalActiveFilters}
      />
      {extraToggleFilter && renderFilterItem(extraToggleFilter)}
    </>
  );

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      {renderCustomCollapsedContent ? (
        renderCustomCollapsedContent(isExpanded, () => setIsExpanded(v => !v))
      ) : (
        <Row gutter={[!isMobile ? 24 : 0, 12]}>
          {mainFilters.map(
            (filter, index) =>
              !filter.hidden && (
                <Col
                  key={index}
                  {...(filter.columnProps || defaultColumnProps)}
                >
                  <S.FormItemContainer>
                    {renderFilterItem(filter)}
                    {index === 0 && collapsibleFilters.length
                      ? renderToggleButtons()
                      : null}
                  </S.FormItemContainer>
                </Col>
              )
          )}
        </Row>
      )}

      {collapsibleFilters.length ? (
        <S.ExpandedFiltersContainer
          {...filtersCollapse}
          $animationState={animationState}
          animate={isExpanded ? 'expanded' : 'collapsed'}
          initial={isFirstMount ? false : filtersCollapse.initial}
          onAnimationStart={() => setAnimationState('animating')}
          onAnimationComplete={() => {
            setAnimationState(isExpanded ? 'expanded' : 'collapsed');
          }}
        >
          <S.ExpandedFiltersInnerContainer>
            <Row gutter={[12, 12]}>
              {collapsibleFilters.map(
                (filter, index) =>
                  !filter.hidden && (
                    <Col key={index} span={24}>
                      {renderFilterItem(filter)}
                    </Col>
                  )
              )}
            </Row>
          </S.ExpandedFiltersInnerContainer>
        </S.ExpandedFiltersContainer>
      ) : null}

      {alwaysVisibleFilters.length ? (
        <Row gutter={[24, 12]} style={{ marginTop: 12 }}>
          {alwaysVisibleFilters.map(
            (filter, index) =>
              !filter.hidden && (
                <Col
                  key={index}
                  {...(filter.columnProps || defaultColumnProps)}
                >
                  <S.FormItemContainer>
                    {renderFilterItem(filter)}
                  </S.FormItemContainer>
                </Col>
              )
          )}
        </Row>
      ) : null}

      {totalRecords !== undefined || displayClearButton || footerExtra ? (
        <S.Footer
          $areFiltersExpanded={isExpanded && alwaysVisibleFilters.length === 0}
          $justify={footerExtra ? 'space-between' : 'flex-start'}
        >
          <Flex gap="sm" align="center">
            {extraContent}

            {totalRecords !== undefined ? (
              <TotalRecordsLabel totalRecords={totalRecords} />
            ) : null}

            {displayClearButton && (
              <FilterChip
                isClear
                onClick={resetAll}
                bgColor={totalRecordBackground}
              >
                Clear All
                <Icon name="x-close" size="xs" />
              </FilterChip>
            )}
          </Flex>

          {footerExtra}
        </S.Footer>
      ) : null}
    </Form>
  );
};

import React, { type ComponentProps } from 'react';

import { BrokerageUserSearch } from '../../smartSelects';
import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps & ComponentProps<typeof BrokerageUserSearch>;

export const BrokerageUserFilter = ({
  name,
  onFilterSubmit,
  onChange,
  ...rest
}: Props) => {
  return (
    <BrokerageUserSearch
      onChange={(...args) => {
        onChange?.(...args);
        onFilterSubmit?.(name);
      }}
      {...rest}
    />
  );
};

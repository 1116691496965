import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Radio } from '@npm/core/ui/components/atoms/Radio';
import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import {
  type CompanyOverviewChartRange,
  type CompanyOverviewChartSeries,
  type PriceType,
} from '../../CompanyOverview.types';

import * as S from './CompanyOverviewChartFilters.styles';

type Props = {
  range: CompanyOverviewChartRange;
  onChangeRange: (value: CompanyOverviewChartRange) => void;
  series?: CompanyOverviewChartSeries;
  onChangeSeries?: (value: CompanyOverviewChartSeries) => void;
  priceType?: PriceType;
  onChangePriceType?: (value: PriceType) => void;
  canChangeSeries?: boolean;
  pagination: {
    page: number;
    totalPages: number;
    onPageChange: (page: number) => void;
  };
  customFilter?: React.ReactNode;
};

export const CompanyOverviewChartFilters = ({
  range,
  series,
  priceType,
  pagination,
  onChangeRange,
  onChangeSeries,
  onChangePriceType,
  canChangeSeries = !!onChangeSeries,
  customFilter,
}: Props) => {
  const { isMobile } = useBreakpoints();
  const theme = useTheme();

  const seriesRadio = useMemo(() => {
    if (!onChangeSeries) return null;
    return (
      <S.RadioGroup
        value={series}
        onChange={e => onChangeSeries(e.target.value)}
        disabled={!canChangeSeries}
        style={{
          flexShrink: 0,
        }}
      >
        <Radio value="PPS" label="PPS" />
        <Radio value="EST_VALUATION" label="Valuation" />
      </S.RadioGroup>
    );
  }, [series, onChangeSeries, canChangeSeries]);

  const priceTypeRadio = useMemo(() => {
    if (!onChangePriceType) return null;
    return (
      <S.RadioGroup
        value={priceType}
        onChange={e => onChangePriceType(e.target.value)}
        style={{
          flexShrink: 0,
        }}
      >
        <Radio value="average" label="Average Price" />
        <Radio value="best" label="Best Bid / Offer" />
      </S.RadioGroup>
    );
  }, [priceType, onChangePriceType]);

  return (
    <S.Wrapper gap="sm" align="center" justify="stretch">
      <Margin left="sm">
        <Flex gap="sm">
          <Button
            size="sm"
            variant="text"
            icon={<Icon name="chevron-left" />}
            onClick={() => pagination.onPageChange(pagination.page + 1)}
            disabled={pagination.page === pagination.totalPages}
          />
          <Button
            size="sm"
            variant="text"
            icon={<Icon name="chevron-right" />}
            onClick={() => pagination.onPageChange(pagination.page - 1)}
            disabled={pagination.page === 0}
          />
        </Flex>
      </Margin>
      {!isMobile && (
        <Divider
          type="vertical"
          marginBottom={theme.spacing.md}
          marginTop={theme.spacing.md}
        />
      )}

      {!isMobile && (
        <S.RangeTabsWrapper>
          <S.RangeTabs
            activeKey={range}
            variant="tertiary"
            onChange={onChangeRange}
            tabBarExtraContent={
              <Flex gap={'sm'} align={'center'}>
                {priceTypeRadio}
                {priceTypeRadio && seriesRadio && (
                  <S.FiltersDivider type="vertical" />
                )}
                {seriesRadio}
                {customFilter}
              </Flex>
            }
          >
            <Tabs.TabPane key={'24M'} tab="24 Months" />
            <Tabs.TabPane key={'12M'} tab="12 Months" />
            <Tabs.TabPane key={'6M'} tab="6 Months" />
          </S.RangeTabs>
        </S.RangeTabsWrapper>
      )}

      {isMobile && (
        <Flex
          gap="sm"
          align="center"
          justify="space-between"
          style={{
            flex: 1,
            overflowX: 'scroll',
          }}
        >
          <S.MobileFilterInfoContainer>6 Months</S.MobileFilterInfoContainer>
          {priceTypeRadio}
          {priceTypeRadio && seriesRadio && (
            <S.FiltersDivider type="vertical" />
          )}
          {seriesRadio}
        </Flex>
      )}
    </S.Wrapper>
  );
};

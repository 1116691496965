import { Flex } from '@npm/core/ui/components/atoms/common';

export const ActionsContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Flex gap="sm" justify="flex-end" onClick={event => event.stopPropagation()}>
    {children}
  </Flex>
);

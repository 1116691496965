import React, { createContext, useContext } from 'react';

import { useOrderPlacement } from '../OrderPlacement.hooks';

type OrderPlacementDrawerContext = ReturnType<typeof useOrderPlacement>;

export const useOrderPlacementDrawerContext = () => {
  const context = useContext(OrderPlacementDrawerContext);

  if (!context) {
    throw new Error(
      'useOrderPlacementDrawerContext must be used within an OrderPlacementDrawerContext'
    );
  }
  return context;
};

export const OrderPlacementDrawerContext = createContext<
  OrderPlacementDrawerContext | undefined
>(undefined);

export const OrderPlacementDrawerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const drawerProps = useOrderPlacement();

  return (
    <OrderPlacementDrawerContext.Provider value={drawerProps}>
      {children}
    </OrderPlacementDrawerContext.Provider>
  );
};

import { addDays, isBefore } from 'date-fns';

import { DATE_FORMATS, parseDateToString } from '@npm/core/ui/utils/formatters';
import {
  type SecondmarketInvestorOrderItemShow,
  CbOrderItemState,
} from '@npm/data-access';

export const EXPIRING_ORDERS_STATE: string[] = [
  CbOrderItemState.items.live_in_marketplace,
  CbOrderItemState.items.not_posted_to_marketplace,
];

export const getExpiringOrderDate = () => {
  return parseDateToString(addDays(new Date(), 7), DATE_FORMATS.DATE_NUMERIC);
};

export const isExpiringOrder = (order: SecondmarketInvestorOrderItemShow) => {
  const expiresAtDate = new Date(order.expires_at);
  const expiringLimitDate = addDays(new Date(), 7);

  const isInAllowedState = EXPIRING_ORDERS_STATE.includes(order.state.code);

  return isInAllowedState && isBefore(expiresAtDate, expiringLimitDate);
};

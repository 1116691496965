import React, { memo, useMemo } from 'react';

import {
  Navigation,
  NavigationMenu,
} from '@npm/core/ui/components/organisms/Layout';
import { useSidebarStore } from '@npm/core/ui/components/organisms/Layout/Sidebar/Sidebar.store';
import { filterMenuItemsByFeatureFlag } from '@npm/core/ui/routes';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { useFeatureFlagMenuItem } from '@npm/features/app/featureFlags/Menu/useFeatureFlagMenuItem';
import { useHelpMenuItem } from '@npm/features/app/zendesk/menu/useHelpMenuItem';
import { useUserContextStore } from '@npm/features/auth/user/context';
import { useUserMenuItems } from '@npm/features/auth/user/menu/menuItems';
import { useUserRole } from '@npm/features/auth/user/role';
import { truthyFilter } from '@npm/utils';

import { getAdvisorSidebarItems } from './config/advisor-workstation';

export const AdvisorMenu = memo(function AdvisorMenu() {
  const userContext = useUserContextStore(store => store);
  const userRole = useUserRole();

  const { helpMenuItem, handleHelpMenuItemClick } = useHelpMenuItem();
  const userMenuItems = useUserMenuItems();

  const { featureFlagMenuItem, handleFeatureFlagMenuItemClick } =
    useFeatureFlagMenuItem();

  const isCollapsed = useSidebarStore(store => store.isCollapsed);

  const { isEnabled } = useFeatureFlags();

  const menuItems = useMemo(
    () =>
      filterMenuItemsByFeatureFlag(
        isEnabled,
        getAdvisorSidebarItems(userContext, userRole)
      ),
    [userContext, userRole, isEnabled]
  );

  return (
    <NavigationMenu $isCollapsed={isCollapsed}>
      <Navigation menuItems={menuItems} />
      <Navigation
        menuItems={[featureFlagMenuItem, helpMenuItem, ...userMenuItems].filter(
          truthyFilter
        )}
        handleClick={key =>
          handleHelpMenuItemClick(key) || handleFeatureFlagMenuItemClick(key)
        }
      />
    </NavigationMenu>
  );
});

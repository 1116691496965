import React, { useMemo, useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Loader } from '@npm/core/ui/components/atoms/Loader';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../../../../documents/DocumentViewer';
import { MissingPoOAlert } from '../../../../../../holdings';
import { PriceBlock, TextLink } from '../../../../components';
import {
  type HoldingDistribution,
  type HoldingDistributionVariant,
} from '../SellIndicationDrawer.types';

import { getHoldingDetailRowsConfig } from './HoldingDetail.config';

import * as S from './HoldingDetail.styles';

type Props = {
  holdingDistribution: HoldingDistribution;
  pps: number;
  onEditHolding?: (holdingId: number) => void;
};

export const HoldingDetail = ({
  holdingDistribution,
  pps,
  onEditHolding,
}: Props) => {
  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();

  const [isExpanded, setIsExpanded] = useState(false);

  const rowsConfig = useMemo(() => {
    return getHoldingDetailRowsConfig(showNpmDocument);
  }, [showNpmDocument]);

  if (!holdingDistribution?.holding) return null;

  const { holding, allocation, variant } = holdingDistribution;

  const isExpandableVersion = variant === 'detail';
  const isMissingPoO =
    !holdingDistribution.holding?.proof_of_ownership_document;

  return (
    <>
      <S.Card>
        <S.Header $isSuccess={variant === 'posting'}>
          <Flex align={'center'} gap={'sm'}>
            <Text colorVariant={'primary'} size={'sm'}>
              {holding.asset?.type?.name}
            </Text>
            <Label variant={'info'} round>
              {holding.certificate_number ?? 'N/A'}
            </Label>
          </Flex>

          {isExpandableVersion && (
            <Button
              variant={'text'}
              icon={<Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />}
              iconPosition={'right'}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? 'Show Less' : 'Show More'}
            </Button>
          )}

          {!isExpandableVersion && getIcon(variant)}
        </S.Header>

        {/*  CONTENT TABLE */}
        {isExpanded && isExpandableVersion && (
          <S.Content>
            <TableVertical data={holding} rows={rowsConfig} />
          </S.Content>
        )}

        {isExpandableVersion && isMissingPoO && onEditHolding && (
          <Margin bottom={'sm'} left={'sm'} right={'sm'}>
            <MissingPoOAlert
              message={
                <Flex align={'center'}>
                  <Text size="sm">Missing Proof of Ownership</Text>
                  <Margin left="lg">
                    <TextLink
                      as="span"
                      $color="warning"
                      onClick={() => onEditHolding(holding?.id)}
                    >
                      + Add
                    </TextLink>
                  </Margin>
                </Flex>
              }
              description={''}
            />
          </Margin>
        )}

        {/* PRICE DISPLAY  */}
        {(isExpanded || !isExpandableVersion) && (
          <S.Footer
            $priceBlockColorVariant={
              variant === 'posting'
                ? 'muted'
                : variant === 'failed'
                ? 'tertiary'
                : undefined
            }
          >
            <PriceBlock
              compact
              borderless
              quantity={allocation}
              price={pps}
              isBuy={false}
            />
          </S.Footer>
        )}
      </S.Card>
      <DocumentViewerModal {...documentViewerModalProps} />
    </>
  );
};

const getIcon = (variant: HoldingDistributionVariant) => {
  switch (variant) {
    case 'posting':
      return <Loader size={'small'} />;
    case 'success':
      return <Icon name="check" size={'xs'} color={'info'} />;
    case 'failed':
      return <Icon name="x-close" size={'xs'} color={'error'} />;
    case 'detail':
    default:
      return null;
  }
};

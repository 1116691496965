import React from 'react';
import { type Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { type DefaultTheme } from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type ExtendedColumnType,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';

import { CompanyOverviewPriceDisplay } from '../../../components/CompanyOverviewPriceDisplay';

import { type AverageAndBestLevelsDataType } from './Levels.types';

import * as S from './Levels.styles';

export const getColumns = ({
  showTotalSize,
  breakpoint,
  theme,
}: {
  showTotalSize: boolean;
  breakpoint: Partial<Record<Breakpoint, boolean>>;
  theme: DefaultTheme;
}): ExtendedColumnType<AverageAndBestLevelsDataType>[] => [
  {
    title: 'Date',
    key: 'date',
    render: (_, { date }) => (
      <Text
        style={{
          paddingLeft: !breakpoint.sm ? theme.spacing.sm : undefined,
        }}
      >
        {formatDate(date, { dateFormat: DATE_FORMATS.MONTH_AND_YEAR })}
      </Text>
    ),
  },
  {
    title: 'Total Size $',
    titleInfo:
      'The total volume of closed trades observed over the stated month.',
    key: 'total_size',
    render: (_, { aggregated }) => aggregated.total_value,
    hidden: !showTotalSize,
  },
  {
    title: 'Avg. PPS',
    key: 'avg-pps',
    align: 'left',
    render: (
      _,
      { aggregated: { price_per_share, discount_or_premium, total_value } }
    ) => (
      <CompanyOverviewPriceDisplay
        price={price_per_share}
        change={discount_or_premium}
        multiplyChange={true}
        align="flex-start"
        sizeRange={total_value}
      />
    ),
  },
  {
    title: 'Implied Val. (Avg.)',
    key: 'avg-valuation',
    align: 'center',
    render: (_, { aggregated: { implied_valuation } }) => (
      <Text.Price value={implied_valuation} compact size="default" />
    ),
    responsive: TABLE_BREAKPOINTS.TABLET_SM,
  },
  {
    title: <S.Divider type="vertical" />,
    key: 'divider',
    align: 'center',
    render: () => <S.Divider type="vertical" />,
    hidden: !breakpoint.sm,
  },
  {
    title: 'Best PPS',
    key: 'best-pps',
    align: 'left',
    render: (_, { best: { price_per_share, discount_or_premium } }) => (
      <CompanyOverviewPriceDisplay
        price={price_per_share}
        change={discount_or_premium}
        multiplyChange={true}
        align="flex-start"
      />
    ),
  },
  {
    title: 'Implied Val. (Best)',
    key: 'best-valuation',
    align: 'center',
    render: (_, { best: { implied_valuation } }) => (
      <Text.Price value={implied_valuation} compact size="default" />
    ),
    responsive: TABLE_BREAKPOINTS.TABLET_SM,
  },
];

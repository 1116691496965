import React from 'react';

import { Priority } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type TableVerticalRowType } from '@npm/core/ui/components/molecules/TableVertical';
import {
  type NegotiationShowHolding,
  type Submission,
  type SubmissionItemsInner,
  CbAssetType,
} from '@npm/data-access';

import { getEventInfo } from '../../program/program.utils';

import { formatClearingPrice } from './OrderData.utils';

const isWarrantOrOption = (holding: NegotiationShowHolding | undefined) =>
  holding?.asset?.type?.code === CbAssetType.items.Warrant ||
  holding?.asset?.type?.code === CbAssetType.items.Option;

export const orderEntryConfig = (
  submission: Submission
): TableVerticalRowType<SubmissionItemsInner>[] => {
  const currency = submission.event.issuance?.denomination?.name ?? 'USD';
  const { isBuy, isSell, isTender } = getEventInfo(submission.event);

  return [
    {
      key: 'quantity',
      title: 'Offered Quantity',
      render: (_, { quantity }) => <Text.Quantity value={quantity} />,
    },
    {
      key: 'exercise_qty',
      title: 'Exercise Quantity',
      render: (_, { exercise_qty }) => <Text.Quantity value={exercise_qty} />,
      hidden: (_, { holding }) =>
        !isTender ||
        !submission.event.order_settings?.exercise_and_hold ||
        !isWarrantOrOption(holding),
    },
    {
      key: 'price',
      title: 'Price Per Share',
      render: (_, { price }) => (
        <Text.Price
          value={price}
          currency={currency}
          formatOptions={{ long: true }}
        />
      ),
      hidden: (_, { price }) => price == null || price <= 0,
    },
    {
      key: 'priority',
      title: 'Priority',
      render: (_, { priority }) => <Priority>P{priority}</Priority>,
      hidden: (_, { priority }) => priority == null || priority < 1,
    },
    {
      key: 'estimated_gross_proceeds',
      title: 'Estimated Gross Proceeds',
      titleInfo:
        'The total number of holdings (e.g., shares) offered multiplied by the offer price. This amount may differ from the final gross proceeds based on various transaction parameters that could impact final allocations.',
      render: (_, { estimated_gross_proceeds }) => (
        <Text.Price value={estimated_gross_proceeds} currency={currency} />
      ),
      hidden: isBuy,
    },
    {
      key: 'estimated_gross_purchase_price',
      title: 'Estimated Gross Purchase Price',
      render: (_, { estimated_gross_purchase_price }) => (
        <Text.Price
          value={estimated_gross_purchase_price}
          currency={currency}
        />
      ),
      hidden: !isBuy,
    },
    {
      key: 'estimated_exercise_costs',
      title: 'Estimated Exercise Costs',
      titleInfo:
        "The total number of holdings offered (e.g., shares) multiplied by each holding's applicable strike price. This amount may differ based on various transaction parameters that could impact the final exercise costs.",
      render: (_, { estimated_exercise_costs }) => (
        <Text.Price value={estimated_exercise_costs} currency={currency} />
      ),
      hidden: (_, { holding }) => !isWarrantOrOption(holding),
    },
    {
      key: 'estimated_fees',
      title: 'Estimated Fees',
      titleInfo:
        'An estimate of any applicable fees that will be deducted from gross proceeds.',
      render: (_, { estimated_fees }) => {
        const displayValue =
          isSell && estimated_fees > 0 ? estimated_fees * -1 : estimated_fees;
        return <Text.Price value={displayValue} currency={currency} />;
      },
    },
    {
      key: 'estimated_net_proceeds',
      title: 'Estimated Net Proceeds',
      titleInfo:
        'An estimate of the amount to be disbursed at settlement less taxes, fees, and/or other withholdings at settlement.',
      render: (_, { estimated_net_proceeds }) => (
        <Text.Price value={estimated_net_proceeds} currency={currency} />
      ),
      hidden: isBuy,
    },
    {
      key: 'estimated_net_purchase_price',
      title: 'Estimated Net Purchase Price',
      render: (_, { estimated_net_proceeds }) => (
        <Text.Price value={estimated_net_proceeds} currency={currency} />
      ),
      hidden: !isBuy,
    },
  ];
};

export const orderFilledConfig = (
  submission: Submission
): TableVerticalRowType<
  SubmissionItemsInner['filled'] & {
    matches: SubmissionItemsInner['matches'] | undefined;
    holding: SubmissionItemsInner['holding'] | undefined;
  }
>[] => {
  const currency = submission.event.issuance?.denomination?.name ?? 'USD';

  const { isBuy, isSell } = getEventInfo(submission.event);

  return [
    {
      key: 'quantity',
      title: 'Filled Quantity',
      render: (_, { quantity }) => <Text.Quantity value={quantity} />,
    },
    {
      key: 'clearing_price',
      title: 'Clearing Price',
      render: (_, { clearing_price }) => (
        <Text.Price
          value={formatClearingPrice(clearing_price)}
          currency={currency}
          formatOptions={{ long: true }}
        />
      ),
    },
    {
      key: 'exercise_costs',
      title: 'Exercise Costs',
      titleInfo:
        'The sum of the Strike Price multiplied by the Offered amount for each option.',
      render: (_, { exercise_costs }) => (
        <Text.Price value={exercise_costs} currency={currency} />
      ),
      hidden: (_, { holding }) => !isWarrantOrOption(holding),
    },
    {
      key: 'gross_proceeds',
      title: 'Gross Proceeds',
      titleInfo:
        'The total number of holdings offered multiplied by the offer price. This amount may be reduced if the transaction is oversubscribed.',
      render: (_, { gross_proceeds }) => (
        <Text.Price value={gross_proceeds} currency={currency} />
      ),
      hidden: isBuy,
    },
    {
      key: 'gross_purchase_price',
      title: 'Gross Purchase Price',
      render: (_, { gross_purchase_price }) => (
        <Text.Price value={gross_purchase_price} currency={currency} />
      ),
      hidden: !isBuy,
    },
    {
      key: 'tax_withholdings',
      title: 'Tax Withholdings',
      titleInfo:
        'Tax Withholdings represent proceeds withheld for tax obligations.',
      render: (_, { tax_withholdings }) => (
        <Text.Price value={tax_withholdings} currency={currency} />
      ),
      hidden: isBuy,
    },
    {
      key: 'other_withholdings',
      title: 'Other Withholdings',
      titleInfo:
        'Other Withholdings represent any additional fees or transaction related costs associated with the transaction.',
      render: (_, { other_withholdings }) => (
        <Text.Price value={other_withholdings} currency={currency} />
      ),
      hidden: isBuy,
    },
    {
      key: 'transaction_fees',
      title: 'Transaction Fees',
      titleInfo:
        'Fees that may include those charged by an issuer, escrow agent, or legal counsel.',
      render: (_, { transaction_fees }) => {
        const displayValue =
          isSell && transaction_fees > 0
            ? transaction_fees * -1
            : transaction_fees;
        return <Text.Price value={displayValue} currency={currency} />;
      },
    },
    {
      key: 'net_proceeds',
      title: (
        <Text size="sm" weight="bold" colorVariant="primary">
          Net Proceeds
        </Text>
      ),
      titleInfo:
        'Net Proceeds represents the amount to be disbursed at settlement.',
      render: (_, { net_proceeds }) => (
        <Text.Price value={net_proceeds} currency={currency} />
      ),
      hidden: isBuy,
    },
    {
      key: 'net_purchase_price',
      title: (
        <Text size="sm" weight="bold" colorVariant="primary">
          Net Purchase Price
        </Text>
      ),
      render: (_, { net_purchase_price }) => (
        <Text.Price value={net_purchase_price} currency={currency} />
      ),
      hidden: !isBuy,
    },
  ];
};

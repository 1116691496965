import styled, { createGlobalStyle, css } from 'styled-components';

import { Button } from '@npm/core/ui/components/atoms/Button';

import { borderWithShadowCSS } from '../../FilterPanel/components/styles';

const DROPDOWN_WIDTH = 320;
const DROPDOWN_BUTTON_WIDTH_SMALL = 86;
const DROPDOWN_BUTTON_WIDTH_BIG = 120;

export const FilterDropdownStyles = createGlobalStyle`
  ${({ theme }) => css`
    .npm-filter-dropdown {
      max-width: ${DROPDOWN_WIDTH}px;
      padding-top: 0;
      .ant-popover-content {
        margin-left: -2px; // to align with button with shadow border

        & > .ant-popover-inner {
          background-color: ${theme.color.general.layout.one};
          border-radius: ${theme.borderRadius.lg}px;
          border: 1px solid ${theme.color.general.borders.secondary};
          box-shadow: ${({ theme }) => theme.shadow.level2};
        }
      }
    }
  `}

`;

export const DropdownButton = styled(Button)<{
  $isOpened?: boolean;
  $variant?: string;
}>`
  ${({ theme, $isOpened, $variant = 'primary' }) => css`
    && {
      > span {
        display: flex;
        align-items: center;
        gap: ${theme.spacing.xs}px;
      }
      width: ${$variant === 'primary'
        ? DROPDOWN_BUTTON_WIDTH_SMALL
        : DROPDOWN_BUTTON_WIDTH_BIG}px;
      ${$isOpened && borderWithShadowCSS}
    }
  `}
`;

import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  useHistory,
  useLocation,
} from '@npm/core/ui/components/molecules/Link';
import {
  DrawerEnum,
  useActiveDrawerStore,
} from '@npm/core/ui/components/organisms/Drawer';
import { CbRoleType, CbWorkstationType } from '@npm/data-access';

import { getInvestorWorkstationLandingPage } from '../../../../workstations/workstationLandingPage.utils';
import { usePermissions } from '../../../permissions/usePermissions';
import { OboModal } from '../../obo';
import { useCurrentAccount, useCurrentWorkstation } from '../../role';
import { useCurrentRole } from '../../role/hooks/useCurrentRole';
import { AccountInfoDropdown } from '../AccountInfo/AccountInfoDropdown';
import { useUserContextStore } from '../store';

import { BrokerageRoleSwitcher } from './BrokerageRoleSwitcher';
import { IssuerSwitcher } from './IssuerSwitcher';
import { useNumberOfInvestorAccounts } from './RoleSwitcher.hooks';
import { RoleSwitcherButton } from './RoleSwitcherButton';

import { ActionRow } from '../AccountInfo/AccountInfoDropdown.styles';

const OBO_DRAWER_KEY = DrawerEnum.OBO_SELECTION;

type Props = {
  isCollapsed: boolean;
};

export const RoleSwitcher = ({ isCollapsed }: Props) => {
  const workstation = useCurrentWorkstation();
  const role = useCurrentRole();
  const context = useUserContextStore();
  const { isInvAccountCentric } = useCurrentAccount();
  const { disabled } = useUserContextStore(store => store.roleSwitcher);
  const isNewUser = useUserContextStore(store => store.isNewUser);
  const { INV_MENU_ONBOARDING: canAccessOnboarding } = usePermissions();

  const { openDrawer, closeDrawer, isOpen } = useActiveDrawerStore();

  const history = useHistory();
  const location = useLocation();

  const numberOfInvestorAccounts = useNumberOfInvestorAccounts();

  const oboRedirectRoute = !window.location.pathname.includes('second-market')
    ? getInvestorWorkstationLandingPage(context, role)
    : undefined;

  if (isNewUser) return null;

  if (workstation?.type?.code === CbWorkstationType.items.brokerage) {
    return (
      <>
        <BrokerageRoleSwitcher
          disabled={
            disabled ||
            role.role_name?.code === CbRoleType.items.BROKER_READ_ONLY ||
            role.role_name?.code === CbRoleType.items.ACCOUNT_MANAGER
          }
          isCollapsed={isCollapsed}
          openOboModal={() => openDrawer(OBO_DRAWER_KEY)}
        />
        <OboModal
          visible={isOpen(OBO_DRAWER_KEY)}
          onCancel={() => closeDrawer()}
          onSuccessRedirectRoute={oboRedirectRoute}
        />
      </>
    );
  }

  if (workstation?.type?.code === CbWorkstationType.items.issuer) {
    return <IssuerSwitcher disabled={disabled} isCollapsed={isCollapsed} />;
  }

  if (workstation?.type?.code === CbWorkstationType.items.advisor) {
    return <RoleSwitcherButton disabled isCollapsed={isCollapsed} />;
  }

  if (workstation?.type?.code === CbWorkstationType.items.investor) {
    if (isInvAccountCentric) {
      return (
        <AccountInfoDropdown
          actions={
            <>
              <ActionRow>
                <Icon name="external-link" size="xs" />
                <button
                  onClick={() =>
                    history.push('/investor-workstation/account/detail')
                  }
                >
                  <Text
                    size="sm"
                    colorVariant="primary"
                    marginBottom="xs"
                    as="span"
                  >
                    Account Information
                  </Text>
                </button>
              </ActionRow>
              {canAccessOnboarding && (
                <ActionRow>
                  <Icon name="external-link" size="xs" />
                  <button onClick={() => history.push('/post-onboarding')}>
                    <Text
                      size="sm"
                      colorVariant="primary"
                      marginBottom="xs"
                      as="span"
                    >
                      Onboarding
                    </Text>
                  </button>
                </ActionRow>
              )}
              <ActionRow>
                <Icon name="external-link" size="xs" />
                <button
                  onClick={() => history.push('/investor-workstation/accounts')}
                >
                  <Text
                    size="sm"
                    colorVariant="primary"
                    marginBottom="xs"
                    as="span"
                  >
                    All Accounts
                    {numberOfInvestorAccounts &&
                      ` (${numberOfInvestorAccounts})`}
                  </Text>
                </button>
              </ActionRow>
            </>
          }
        >
          <RoleSwitcherButton isCollapsed={isCollapsed} />
        </AccountInfoDropdown>
      );
    }

    return (
      <RoleSwitcherButton
        onClick={() => history.push('/investor-workstation/accounts')}
        disabled={
          disabled ||
          location.pathname === '/investor-workstation/accounts' ||
          context.isSpouseRolesOnly
        }
        isCollapsed={isCollapsed}
      />
    );
  }

  return null;
};

import { useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Radio, RadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { Codebooks, useCodebook } from '@npm/data-access';

import { RadioButton } from './OrderPreferences.styles';

type Props = {
  value?: string;
  onItemChange: () => void;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const ROFRFormSection = ({ value, onItemChange, ...props }: Props) => {
  const { data: rofrData } = useCodebook({
    type: Codebooks.ORDER_ITEM_ROFR,
  });

  const selectedOption = useMemo(
    () => rofrData?.codebooks?.find(({ code }) => code === value),
    [rofrData, value]
  );

  return (
    <CollapsibleFormSection.Item
      label="ROFR"
      optional
      preview={selectedOption?.name}
      key="rofr"
      name="rofr"
      {...props}
    >
      <Flex align="center">
        <FormItem name="rofr" marginBottom="sm">
          <RadioGroup onChange={() => onItemChange()}>
            {rofrData?.codebooks?.map(({ code, name }) => (
              <Radio value={code} label={name} key={code} />
            ))}
            {value != null && <RadioButton value={null} label="Deselect" />}
          </RadioGroup>
        </FormItem>
      </Flex>
    </CollapsibleFormSection.Item>
  );
};

import { useMemo } from 'react';
import Big from 'big.js';

import { type Holding } from '@npm/data-access';

export const useHoldingQuantities = (selectedHoldings: Holding[]) => {
  return useMemo(() => {
    const quantitiesBig = selectedHoldings.reduce(
      (acc, holding) => {
        return {
          quantity: acc.quantity.add(holding.quantity),
          remainingQuantity: acc.remainingQuantity.add(
            holding.remaining_quantity
          ),
        };
      },
      { quantity: new Big(0), remainingQuantity: new Big(0) }
    );

    return {
      quantity: quantitiesBig.quantity.toNumber(),
      remainingQuantity: quantitiesBig.remainingQuantity.toNumber(),
    };
  }, [selectedHoldings]);
};

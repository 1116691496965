import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { useOnClickOutside } from 'usehooks-ts';

import { CypressDataIds } from '../../../../constants/cypress.constants';
import { Button } from '../../../atoms/Button';
import { Flex, Margin } from '../../../atoms/common';
import { Icon } from '../../../atoms/Icon';
import { Text } from '../../../atoms/Typography';

import * as S from './DrawerConfirmDialog.styles';

type Props = {
  message: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  handleClickOutsideConfirm?: () => void;
  cancelText?: string;
  offsetBottom?: number;
};

export const DrawerConfirmDialog = ({
  message,
  handleConfirm,
  handleCancel,
  handleClickOutsideConfirm,
  cancelText = 'Back',
  offsetBottom = 85,
}: Props) => {
  const confirmContainerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => {
    handleClickOutsideConfirm?.();
  };

  useOnClickOutside(confirmContainerRef, handleClickOutside);

  return (
    <S.ConfirmWrapper
      ref={confirmContainerRef}
      $offsetBottom={offsetBottom}
      data-cy={CypressDataIds.DrawerConfirmDialog.Dialog}
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
        layout
      >
        <S.StyledCard>
          <Flex align="center" gap="sm">
            <div style={{ flexShrink: 0 }}>
              <Icon name="circle-check" size="sm" color="info" />
            </div>
            <Text size="sm">{message}</Text>
          </Flex>

          <Margin top="md">
            <Flex gap="sm">
              <Button
                size="md"
                onClick={handleConfirm}
                data-cy={CypressDataIds.DrawerConfirmDialog.ConfirmButton}
              >
                I Confirm
              </Button>
              <Button
                size="md"
                onClick={handleCancel}
                variant="text"
                data-cy={CypressDataIds.DrawerConfirmDialog.CancelButton}
              >
                {cancelText}
              </Button>
            </Flex>
          </Margin>
        </S.StyledCard>
      </motion.div>
    </S.ConfirmWrapper>
  );
};

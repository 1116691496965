import { useEffect, useMemo, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { useParams } from '@npm/core/ui/hooks/useParams';
import { type RouteDefinition } from '@npm/core/ui/routes';
import {
  type UserRoleAggregate,
  getApiErrorCode,
  useWorkstationCreate,
} from '@npm/data-access';

export type LoadRoleOptions = {
  on403?: NonNullable<RouteDefinition['roles']>['on403'];
};

export const useLoadRole = (
  roleId: number | null | undefined,
  workstation:
    | 'investor'
    | 'brokerage'
    | 'issuer'
    | 'advisor'
    | undefined
    | null,
  needRole: boolean,
  options?: LoadRoleOptions
) => {
  const params = useParams();
  const history = useHistory();
  const handleError = useErrorHandler();
  const { execute: switchWorkstation } = useWorkstationCreate();

  const [role, setRole] = useState<UserRoleAggregate | null>(null);

  useEffect(() => {
    if (!workstation || !needRole) {
      return;
    }

    switchWorkstation(
      {
        workstationCreateRequestContract: {
          type: workstation,
          user_role_id: roleId,
        },
      },
      {
        roles: {
          workstation,
          roleId,
        },
      }
    )
      .then(data => {
        setRole(!roleId ? null : data?.user_role);
      })
      .catch(e => {
        if (getApiErrorCode(e) === 403) {
          if (options?.on403) return options.on403(history, params);
          history.push('/403');
        } else {
          handleError(e);
        }
      });
  }, [roleId, workstation, needRole, options?.on403]);

  return useMemo(() => {
    return {
      // eslint-disable-next-line eqeqeq
      isLoading: workstation && needRole && role?.id != roleId,
      role,
    };
  }, [role, roleId, needRole, workstation]);
};

import {
  type UserRoleAggregate,
  type Workstation,
  CbEventStatus,
  CbRoleType,
  CbWorkstationType,
  useEventListLazy,
} from '@npm/data-access';

import { type UserContextStore } from '../auth/user/context';

import {
  getAdvisorWorkstationLandingPage,
  getBrokerageWorkstationLandingPage,
  getInvestorWorkstationLandingPage,
  getIssuerWorkstationLandingPage,
} from './workstationLandingPage.utils';

export const useGetWorkstationLandingPage = () => {
  const [fetchActiveEventsForIssuerObserver] = useEventListLazy();

  return async (
    workstation: Workstation['type']['code'],
    userContext: Partial<UserContextStore>,
    role: UserRoleAggregate
  ) => {
    switch (workstation) {
      case CbWorkstationType.items.investor:
        return getInvestorWorkstationLandingPage(userContext, role);
      case CbWorkstationType.items.issuer:
        if (role?.role_name?.code === CbRoleType.items.ISSUER_EVENT_OBSERVER) {
          try {
            const res = await fetchActiveEventsForIssuerObserver(
              {
                variables: {
                  issuerEntityId: role?.subject?.id,
                  eventStatus: CbEventStatus.items.ACTIVE,
                },
              },
              {
                roles: {
                  roleId: role?.id,
                  workstation: 'issuer',
                },
              }
            );
            const hasIssuerEventObserverActiveEvents =
              res?.pagination?.total_records > 0;

            return getIssuerWorkstationLandingPage(
              role,
              hasIssuerEventObserverActiveEvents
            );
          } catch (e) {
            console.error(e);
          }
        }
        return getIssuerWorkstationLandingPage(role, true);
      case CbWorkstationType.items.brokerage:
        return getBrokerageWorkstationLandingPage(role);
      case CbWorkstationType.items.advisor:
        return getAdvisorWorkstationLandingPage();
      default:
        return '/';
    }
  };
};

import { Codebooks } from './codebooks.types';

const Items = {
  match: 'match',
  transfer: 'transfer',
  payment: 'payment',
  complete: 'complete',
  rejected: 'rejected',
} as const;

export const CbNegotiationTransactionPhase = {
  code: Codebooks.NEGOTIATION_TRANSACTION_PHASE,
  items: Items,
};

import React, { type CSSProperties, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { usePremiumPlan } from '../../role';
import { useUnlockPremium } from '../UnlockPremiumModal';

import * as S from './styles';

type Props = {
  children?: React.ReactElement;
  disabled?: boolean;
  valuePropName?: string;
  valueLength?: number;
  isMultiLine?: boolean;
  emptyValue?: string;
};

export const anonymizeValue = (
  value?: string | number,
  forceLength?: number
) => {
  let valueLength;
  const parsedValue = Number(value);

  if (forceLength) {
    valueLength = forceLength;
  } else if (!value) {
    valueLength = 8; // fallback length
  } else {
    valueLength = Math.floor(parsedValue)?.toString().length;
  }

  // if valid number or undefined (if undefined let's still show some random value)
  if (!isNaN(parsedValue) || value === undefined) {
    const max = Math.pow(10, valueLength) - 1;
    const min = Math.pow(10, valueLength - 1);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  if (typeof value === 'string') {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    return Array.from(
      forceLength ? new Array(forceLength) : value ?? new Array(5)
    )
      .map(() =>
        characters.charAt(Math.floor(Math.random() * characters.length))
      )
      .join('');
  }

  return undefined;
};

export const PremiumTextGuard: React.FC<Props> = ({
  children,
  valuePropName = 'value',
  valueLength,
  disabled,
  isMultiLine = false,
  emptyValue = '--',
}) => {
  const { ref: wrapperRef, height: wrapperHeight } = useResizeObserver();
  const anonymizedValueRef = useRef<string | number | undefined>();
  const [isHovered, setIsHovered] = useState(false);

  const { isPremiumContentUnlocked } = usePremiumPlan();
  const unlockPremiumAccount = useUnlockPremium();

  const { isMobile } = useBreakpoints();

  if (isPremiumContentUnlocked || disabled) return children || null;

  const renderContent = () => {
    if (isHovered || isMobile) {
      return (
        <S.UnlockButton
          variant="text"
          icon={<Icon name="lock" size="xs" />}
          onClick={() => unlockPremiumAccount()}
          size={isMultiLine ? 'lg' : 'sm'}
          $isMultiLine={isMultiLine}
        >
          Unlock
        </S.UnlockButton>
      );
    }

    const blurStyle: CSSProperties = {
      filter: 'blur(4px)',
      userSelect: 'none',
    };

    if (
      typeof children === 'string' ||
      typeof children === 'number' ||
      typeof children === 'undefined' ||
      // if we're rendering a falsy value inside the `children` (e.g. <Text>{null}</Text>),
      // it means that the BE is hiding the premium content by returning `null` -> show random blurred value
      (React.isValidElement(children) &&
        (!(children.props as React.PropsWithChildren).children ||
          (children.props as React.PropsWithChildren).children === emptyValue))
    ) {
      if (!anonymizedValueRef.current) {
        anonymizedValueRef.current = anonymizeValue(
          React.isValidElement(children) ? null : children,
          valueLength
        );
      }

      return <span style={blurStyle}>{anonymizedValueRef.current}</span>;
    }

    if (!React.isValidElement(children)) return null;

    const originalValue = children.props[valuePropName];

    if (!anonymizedValueRef.current) {
      anonymizedValueRef.current = anonymizeValue(originalValue, valueLength);
    }

    const newProps = {
      [valuePropName]: anonymizedValueRef.current,
      style: blurStyle,
    };

    return React.cloneElement(children, newProps);
  };

  return (
    <S.UnlockButtonWrapper
      ref={wrapperRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      $initialWrapperHeight={wrapperHeight}
      $isMultiLine={isMultiLine}
    >
      {renderContent()}
    </S.UnlockButtonWrapper>
  );
};

import styled from 'styled-components';

export const LineContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Line = styled.div`
  position: absolute;
  bottom: 25px;
  width: 100%;
  border-bottom: 3px solid
    ${({ theme }) => theme.color.general.typography.muted};
`;

export const Dot = styled.div<{ $price?: number }>`
  position: absolute;
  bottom: 22px;
  height: 8px;
  width: 8px;
  background-color: ${props => props.color};
  border-radius: 50%;
  transform: translateX(-50%);
`;

export const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.general.layout.zero};
`;

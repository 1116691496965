import type { CSSProperties } from 'react';
import styled, {
  type ColorGroup,
  type DefaultTheme,
  type Variant,
  css,
} from 'styled-components';

import { type commonThemeConfig } from '../../themes';
import { centerFlex, useBreakpoint } from '../../utils/style';

export const Priority = styled.span`
  font-size: ${({ theme }) => theme.typography.size.md}px;
  padding: ${({ theme }) => `${theme.spacing.xs}px ${theme.spacing.sm}px`};
  background-color: ${({ theme }) => theme.color.general.layout.zero};
  color: ${({ theme }) => theme.color.general.typography.primary};
  border: 1px solid ${({ theme }) => theme.color.general.typography.primary};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size.xs}px;
    padding: ${theme.spacing.xs / 2}px ${theme.spacing.xs}px;
  `}
`;

// Wrapper for info tooltip displayed in tables
export const InfoIconWrap = styled.div`
  ${centerFlex}
  && svg {
    height: 16px;
    width: 16px;
    margin-right: ${({ theme }) => theme.spacing.sm}px;
    color: ${({ theme }) => theme.color.info.typography.primary};
  }
`;

/**
 * Data Pair style for cards
 * @param $noBorder - if true, it will not render any divider
 * @param $center - if true, items will be centered
 * @param $margin - vertical margin
 */
export const DataPair = styled.div<{
  $noBorder?: boolean;
  $center?: boolean;
  $verticalMargin?: keyof DefaultTheme['spacing'];
}>`
  padding: ${({ theme }) => theme.spacing.xs}px 0;
  border-bottom: ${({ theme, $noBorder }) =>
    $noBorder ? 'none' : `1px solid${theme.color.general.borders.primary}`};
  display: flex;

  & > * {
    flex: 1;
  }

  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  margin-top: ${({ theme }) => theme.spacing.sm}px;

  ${({ theme, $verticalMargin }) =>
    $verticalMargin &&
    css`
      margin-bottom: ${theme.spacing[$verticalMargin]}px;
      margin-top: ${theme.spacing[$verticalMargin]}px;
    `}
  ${({ $center }) =>
    $center &&
    css`
      align-items: center;
    `}
  & > * + * {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`;

/** Wrapper for colored icons */
export const IconColorWrapper = styled.span<{
  $color?: ColorGroup;
  $colorVariant?: Variant;
}>`
  display: flex;

  svg {
    color: ${({ theme, $color, $colorVariant }) =>
      theme.color[$color ?? 'general'].typography[$colorVariant ?? 'primary']};
  }
`;

export const Row = styled.div<{
  $breakpoint?: 'mobile' | 'smTablet' | 'tablet';
}>`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}px;
  align-items: flex-start;

  & > * + * {
    flex: 0 0 320px;
  }

  ${({ theme, $breakpoint = 'mobile' }) => useBreakpoint(theme)[$breakpoint]`
    flex-direction: column-reverse;
    align-items: initial;
    & > * {
      flex: 1
    }
  `}
`;

export const Column = styled.div<{ $grow?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}px;

  ${({ $grow }) =>
    $grow &&
    css`
      flex-grow: 1;
    `}
`;

export const CardBigRow = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing.sm}px 0 ${theme.spacing.md}px`};
  gap: ${({ theme }) => theme.spacing.sm}px;

  & > * {
    flex: 1;
  }
`;

export type ThemeSpacing = keyof typeof commonThemeConfig['spacing'];

export const Flex = styled.div<{
  align?: CSSProperties['alignItems'];
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'stretch';
  direction?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  gap?: ThemeSpacing | number;
  $wrap?: boolean;
}>`
  display: flex;
  ${({ $wrap }) => ($wrap ? 'flex-wrap: wrap' : null)};
  ${({ direction }) => (direction ? `flex-direction: ${direction}` : null)};
  ${({ align }) => (align ? `align-items: ${align}` : null)};
  ${({ justify }) => (justify ? `justify-content: ${justify}` : null)};
  ${({ theme, gap }) =>
    gap
      ? `gap: ${typeof gap === 'number' ? gap : theme.spacing[gap]}px`
      : null};
`;

export const Margin = styled.div<{
  top?: ThemeSpacing | number;
  left?: ThemeSpacing | number;
  right?: ThemeSpacing | number;
  bottom?: ThemeSpacing | number;
}>`
  ${({ theme, top }) =>
    top ? `margin-top: ${theme.spacing[top] ?? top}px` : null};
  ${({ theme, left }) =>
    left ? `margin-left: ${theme.spacing[left] ?? left}px` : null};
  ${({ theme, right }) =>
    right ? `margin-right: ${theme.spacing[right] ?? right}px` : null};
  ${({ theme, bottom }) =>
    bottom ? `margin-bottom: ${theme.spacing[bottom] ?? bottom}px` : null};
`;

export type PaddingProps = {
  all?: ThemeSpacing | number;
  top?: ThemeSpacing | number;
  left?: ThemeSpacing | number;
  right?: ThemeSpacing | number;
  bottom?: ThemeSpacing | number;
  x?: ThemeSpacing | number;
  y?: ThemeSpacing | number;
};

export const Padding = styled.div<PaddingProps>`
  ${({ theme, all, y, top }) => {
    const topVal = top ?? y ?? all;
    return topVal !== undefined
      ? `padding-top: ${theme.spacing[topVal] ?? topVal}px`
      : null;
  }};
  ${({ theme, all, x, left }) => {
    const leftVal = left ?? x ?? all;
    return leftVal !== undefined
      ? `padding-left: ${theme.spacing[leftVal] ?? leftVal}px`
      : null;
  }};
  ${({ theme, all, x, right }) => {
    const rightVal = right ?? x ?? all;
    return rightVal !== undefined
      ? `padding-right: ${theme.spacing[rightVal] ?? rightVal}px`
      : null;
  }};
  ${({ theme, all, y, bottom }) => {
    const bottomVal = bottom ?? y ?? all;
    return bottomVal !== undefined
      ? `padding-bottom: ${theme.spacing[bottomVal] ?? bottomVal}px`
      : null;
  }};
`;

// TODO: NFE-6558: add $hyperlinkColor prop to Text / Button components
export const TextLink = styled.span<{ $color?: ColorGroup }>`
  color: ${({ theme, $color }) =>
    $color
      ? theme.color[$color].typography.primary
      : theme.color.info.typography.primary};
  cursor: pointer;
  transition: 0.3s;

  :hover {
    color: ${({ theme, $color }) => {
      const colorTheme = $color && theme.color[$color];

      return colorTheme && 'primaryHover' in colorTheme.backgrounds
        ? colorTheme.backgrounds.primaryHover
        : theme.color.info.backgrounds.primaryHover;
    }};
  }
`;

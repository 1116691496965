import Big from 'big.js';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import {
  EMPTY_VALUE,
  Heading,
  Text,
} from '@npm/core/ui/components/atoms/Typography';
import { formatPrice, formatQuantity } from '@npm/core/ui/utils/formatters';

import * as S from './PriceBlock.styles';

type Props = {
  quantity: number;
  price?: number;
  isBuy?: boolean;
  onAddClick?: () => void;
  compact?: boolean;
  compactPps?: boolean;
  highlightPps?: boolean;
  borderless?: boolean;
  noBackground?: boolean;
  style?: React.CSSProperties;
};
export const PriceBlock = ({
  quantity,
  price,
  isBuy,
  onAddClick,
  compact,
  compactPps,
  highlightPps,
  borderless,
  noBackground,
  style,
}: Props) => {
  const headingVariant = compact ? 'h3' : 'h1';

  return (
    <S.PriceContainer
      $compact={compact}
      $borderless={borderless}
      $noBg={noBackground}
      style={style}
    >
      <S.PricePerShare $compactPps={compactPps} $highlightPps={highlightPps}>
        {price ? (
          <Flex align="center">
            <Heading
              variant={headingVariant}
              color={isBuy ? 'success' : 'error'}
            >
              {`$${formatPrice(price)}`}
            </Heading>
            <S.SharesText>/ sh</S.SharesText>
          </Flex>
        ) : onAddClick ? (
          <Button variant="text" onClick={onAddClick}>
            + Add PPS
          </Button>
        ) : (
          <div>{EMPTY_VALUE}</div>
        )}
      </S.PricePerShare>
      <S.Operator>
        <Text size="sm" colorVariant="tertiary">
          ×
        </Text>
      </S.Operator>
      <S.Quantity>
        <Heading variant={headingVariant}>{formatQuantity(quantity)}</Heading>
      </S.Quantity>

      <S.Operator>
        <Text size="sm" colorVariant="tertiary">
          =
        </Text>
      </S.Operator>
      <S.EstimatedGrossProceeds>
        <Heading variant={headingVariant}>
          {price
            ? `$${formatPrice(new Big(price).times(quantity).toNumber(), null, {
                minimumFractionDigits: 0,
              })}`
            : EMPTY_VALUE}
        </Heading>
      </S.EstimatedGrossProceeds>
    </S.PriceContainer>
  );
};

import { type RoleType, CbRoleType } from '@npm/data-access';

import { type Permission } from './permissions.types';

export const PERMISSION_TO_USER_ROLE_MAP: Partial<
  Record<Permission, RoleType[]>
> = {
  INV_MENU_NDA: [
    CbRoleType.items.ACCOUNT_ADMIN,
    CbRoleType.items.ACCOUNT_GUEST,
    CbRoleType.items.ADMIN_AND_SIGNER,
    CbRoleType.items.ALL_ACCOUNTS,
    // AoBo
    CbRoleType.items.BROKERAGE_ADMIN,
    CbRoleType.items.INTERNAL_BROKER,
    CbRoleType.items.SPOUSE,
  ],
  INV_MENU_AGREEMENTS: [
    CbRoleType.items.ACCOUNT_ADMIN,
    CbRoleType.items.ACCOUNT_GUEST,
    CbRoleType.items.ADMIN_AND_SIGNER,
    CbRoleType.items.GUEST_AND_SIGNER,
    CbRoleType.items.SPOUSE,
    CbRoleType.items.ALL_ACCOUNTS,
    // AoBo
    CbRoleType.items.BROKERAGE_ADMIN,
    CbRoleType.items.INTERNAL_BROKER,
  ],
  INV_AGREEMENTS_SIGN: [
    CbRoleType.items.ADMIN_AND_SIGNER,
    CbRoleType.items.GUEST_AND_SIGNER,
    CbRoleType.items.SPOUSE,
    // relying on backend validation for now,
    // frontend doesn't know if user has a role available outside all-accounts mode, which can sign
    CbRoleType.items.ALL_ACCOUNTS,
  ],
  INV_MENU_ONBOARDING: [
    CbRoleType.items.ACCOUNT_ADMIN,
    CbRoleType.items.ADMIN_AND_SIGNER,
    CbRoleType.items.NEW_USER,
    // AoBo
    CbRoleType.items.BROKERAGE_ADMIN,
    CbRoleType.items.INTERNAL_BROKER,
  ],
  INV_MENU_OTHER: [
    CbRoleType.items.ACCOUNT_ADMIN,
    CbRoleType.items.ACCOUNT_GUEST,
    CbRoleType.items.ADMIN_AND_SIGNER,
    CbRoleType.items.GUEST_AND_SIGNER,
    CbRoleType.items.ALL_ACCOUNTS,
    // AoBo
    CbRoleType.items.BROKERAGE_ADMIN,
    CbRoleType.items.INTERNAL_BROKER,
  ],
  INV_WRITE: [
    CbRoleType.items.ACCOUNT_ADMIN,
    CbRoleType.items.ADMIN_AND_SIGNER,
    // AoBo
    CbRoleType.items.BROKERAGE_ADMIN,
    CbRoleType.items.INTERNAL_BROKER,
    // relying on backend validation for now,
    // frontend doesn't know if user has a role available outside all-accounts mode, which can sign
    CbRoleType.items.ALL_ACCOUNTS,
  ],
  INV_AOBO: [
    CbRoleType.items.BROKERAGE_ADMIN,
    CbRoleType.items.INTERNAL_BROKER,
  ],
  ISS_MENU_AGREEMENTS: [
    CbRoleType.items.ISSUER_ADMIN,
    CbRoleType.items.ISSUER_OBSERVER,
    CbRoleType.items.ISSUER_ADMIN_AND_SIGNER,
    CbRoleType.items.ISSUER_OBSERVER_AND_SIGNER,
  ],
  ISS_AGREEMENTS_SIGN: [
    CbRoleType.items.ISSUER_ADMIN_AND_SIGNER,
    CbRoleType.items.ISSUER_OBSERVER_AND_SIGNER,
  ],
  ISS_MENU_PROGRAMS: [
    CbRoleType.items.ISSUER_ADMIN,
    CbRoleType.items.ISSUER_OBSERVER,
    CbRoleType.items.ISSUER_ADMIN_AND_SIGNER,
    CbRoleType.items.ISSUER_OBSERVER_AND_SIGNER,
    CbRoleType.items.ISSUER_EVENT_OBSERVER,
  ],
  ISS_DATAROOM_UPLOAD: [
    CbRoleType.items.ISSUER_ADMIN,
    CbRoleType.items.ISSUER_OBSERVER,
    CbRoleType.items.ISSUER_ADMIN_AND_SIGNER,
    CbRoleType.items.ISSUER_OBSERVER_AND_SIGNER,
    CbRoleType.items.ISSUER_EVENT_OBSERVER,
  ],
  ISS_WRITE: [
    CbRoleType.items.ISSUER_ADMIN,
    CbRoleType.items.ISSUER_ADMIN_AND_SIGNER,
  ],
  ISS_MENU_OTHER: [
    CbRoleType.items.ISSUER_ADMIN,
    CbRoleType.items.ISSUER_OBSERVER,
    CbRoleType.items.ISSUER_ADMIN_AND_SIGNER,
    CbRoleType.items.ISSUER_OBSERVER_AND_SIGNER,
  ],
  BRO_OBO: [CbRoleType.items.BROKERAGE_ADMIN, CbRoleType.items.INTERNAL_ADMIN],
  BRO_MENU_OTHER: [
    CbRoleType.items.BROKERAGE_ADMIN,
    CbRoleType.items.INTERNAL_ADMIN,
  ],
  BRO_CHANGE_ORDER_VISIBILITY: [CbRoleType.items.BROKERAGE_ADMIN],
  BRO_EDIT_ORDERS: [CbRoleType.items.BROKERAGE_ADMIN],
  BRO_NEGOTIATE: [CbRoleType.items.BROKERAGE_ADMIN],
  BRO_READ_ONLY: [CbRoleType.items.BROKER_READ_ONLY],
  BRO_ACCOUNT_MANAGER: [CbRoleType.items.ACCOUNT_MANAGER],
  ADVISOR_ADMIN: [CbRoleType.items.ADVISOR_ADMIN],
};

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import { truncate } from '@npm/core/ui/utils/formatters';
import {
  type SecondmarketSubmissionForClientOrders,
  CbOrderBuySell,
} from '@npm/data-access';

import { TextLink } from '../../TextLink';

import * as S from './InvestorOrdersTable.styles';

export const getHoldingDisplayValue = (
  submission: SecondmarketSubmissionForClientOrders,
  onHoldingView?: (holdingId: number) => void,
  onHoldingEdit?: (holdingId: number) => void
) => {
  const holding = submission?.items?.[0]?.holding;
  const isBuyOrder = submission?.side?.code === CbOrderBuySell.items.buy;
  const certificateNumber = holding?.certificate_number;
  const assetName = submission?.asset_type?.name;
  const isMissingPoO = !holding?.proof_of_ownership_document;

  if (isBuyOrder) {
    return <Text size="sm">{assetName}</Text>;
  }

  if (isMissingPoO) {
    return (
      <TextLink
        $color="warning"
        onClick={e => {
          onHoldingEdit(holding?.id);
          e.stopPropagation();
        }}
      >
        + Add Proof of Ownership
      </TextLink>
    );
  }

  const handleClick = (
    e: React.MouseEvent,
    callback?: (holdingId: number) => void
  ) => {
    e.stopPropagation();
    if (callback) callback(holding?.id);
  };

  return (
    <Flex align="center" gap="xs">
      {certificateNumber ? (
        onHoldingView ? (
          <S.TextWrapper>
            <Icon name="eye" size="xs" />
            <Text
              size="sm"
              lineClamp={1}
              onClick={e => handleClick(e, onHoldingView)}
            >
              {truncate(certificateNumber, 24)}
            </Text>
          </S.TextWrapper>
        ) : (
          certificateNumber
        )
      ) : onHoldingEdit ? (
        <TextLink onClick={e => handleClick(e, onHoldingEdit)}>
          + Add ID
        </TextLink>
      ) : (
        EMPTY_VALUE
      )}
      <Text
        size="sm"
        colorVariant="secondary"
        lineClamp={1}
      >{`• ${submission?.asset_type?.name}`}</Text>
    </Flex>
  );
};

export const getSizeDisplayValue = (
  submission: SecondmarketSubmissionForClientOrders,
  onOrderEdit: (submission: SecondmarketSubmissionForClientOrders) => void
) => {
  const order = submission?.items?.[0];
  return (
    <Flex gap="xs">
      <Text.Quantity value={order?.quantity} />
      <Text size="sm" colorVariant="muted">
        @
      </Text>
      {order?.price ? (
        <Text.Price value={order?.price} />
      ) : (
        <TextLink
          onClick={e => {
            e.stopPropagation();
            onOrderEdit(submission);
          }}
        >
          + Add PPS
        </TextLink>
      )}
    </Flex>
  );
};

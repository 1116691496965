import styled from 'styled-components';

const Blur = styled.span`
  filter: blur(7px);
  user-select: none;
`;

type Props = {
  children: React.ReactNode;
};

export const LockedTextGuard = ({ children }: Props) => <Blur>{children}</Blur>;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  ${({ theme }) => `
    padding: ${theme.spacing.lg / 2}px 0;
  `}
`;

export const Item = styled.div`
  position: relative;

  ${({ theme }) => `
    &:not(:first-child) {
      padding: 0 ${theme.spacing.lg}px;
    }

    &:first-child {
      padding-right:  ${theme.spacing.lg}px;
    }

    &:not(:first-child):before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 85%;
      transform: translateY(-50%);
      background-color: ${theme.color.general.borders.primary};
    }
  `}
`;

import React, { type Key, type ReactElement, useMemo } from 'react';
import {
  type DropdownProps as AntdDropdownProps,
  type MenuItemProps,
  type MenuProps,
  Dropdown as AntdDropdown,
  Menu,
} from 'antd';
import { type DefaultTheme } from 'styled-components';

import { Flex } from '../common';
import { Icon } from '../Icon';
import { Skeleton } from '../Skeleton';
import { Tooltip } from '../Tooltip';
import { Text } from '../Typography';

import * as S from './Dropdown.styles';

export type DropdownProps = {
  overlay?: AntdDropdownProps['overlay'];
  overlayHeading?: string;
  isLoading?: boolean;
  items?: ({
    key?: Key; // pass key if label is not string
    label: string | ReactElement;
    description?: string;
    count?: number;
    onClick?: (
      event:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>
    ) => void;
    tooltip?: string;
    selected?: boolean;
    'data-cy'?: string;
    'data-dd-action-name'?: string;
    'data-dd-privacy'?: string;
  } & Partial<MenuItemProps>)[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  zIndex?: keyof DefaultTheme['zIndex'];
  disabled?: boolean;
  placement?: AntdDropdownProps['placement'];
  menuProps?: MenuProps;
  dataCy?: string;
  fixedPosition?: boolean; // if true, this make sure the open dropdown is always at fixed position as user scrolls the page
  overlayHeader?: React.ReactNode;
  noDataArea?: React.ReactNode;
  noMargin?: boolean;
  children?: React.ReactNode;
};

const getMenuItemKey = (
  key: Key | undefined,
  label: string | ReactElement,
  index: number
) => {
  if (key) return key;

  if (typeof label === 'string') return label;

  return index;
};

export const Dropdown: React.FC<DropdownProps> = ({
  items,
  overlay,
  overlayHeading,
  children,
  open,
  onOpenChange,
  zIndex = 'dropdown',
  disabled,
  isLoading,
  placement = 'bottomRight',
  menuProps,
  dataCy,
  fixedPosition = false,
  overlayHeader,
  noDataArea,
  noMargin = false,
}) => {
  const _overlay = useMemo(() => {
    return (
      overlay || (
        <>
          {overlayHeader}
          <Menu {...menuProps} data-cy={dataCy}>
            {overlayHeading && (
              <S.OverlayHeadingWrapper>
                <Text size="xs">{overlayHeading}</Text>
              </S.OverlayHeadingWrapper>
            )}
            {isLoading ? (
              <S.SkeletonContainer>
                <Skeleton.Base paragraph={{ rows: 1 }} />
                <Skeleton.Base paragraph={{ rows: 1 }} />
                <Skeleton.Base paragraph={{ rows: 1 }} />
              </S.SkeletonContainer>
            ) : noDataArea && (!items || items.length === 0) ? (
              <Flex align="center" justify="center">
                {noDataArea}
              </Flex>
            ) : (
              items?.map(
                (
                  {
                    key,
                    label,
                    description,
                    icon,
                    onClick,
                    count,
                    tooltip,
                    selected,
                    'data-cy': dataCy,
                    ...otherProps
                  },
                  i
                ) => (
                  <Menu.Item
                    key={getMenuItemKey(key, label, i)}
                    icon={icon}
                    onClick={info => {
                      onClick?.(info.domEvent);
                      info.domEvent.stopPropagation();
                    }}
                    disabled={!onClick}
                    {...otherProps}
                  >
                    <S.MenuItemContainer data-cy={dataCy} $noMargin={noMargin}>
                      <div>
                        <Tooltip title={tooltip}>{label}</Tooltip>
                        {count && <S.DropdownBadge>{count}</S.DropdownBadge>}
                        {description && (
                          <S.DescriptionText size="xs">
                            {description}
                          </S.DescriptionText>
                        )}
                      </div>
                      {menuProps?.selectable && selected && (
                        <Icon name="check" size="xs" />
                      )}
                    </S.MenuItemContainer>
                  </Menu.Item>
                )
              )
            )}
          </Menu>
        </>
      )
    );
  }, [items, overlay, overlayHeading, overlayHeader, noDataArea]);

  // this make sure the open dropdown is at fixed position as user scrolls the page
  const getPopupContainer = (trigger: HTMLElement) =>
    fixedPosition ? (trigger.parentNode as HTMLElement) : document.body;

  return (
    <>
      <S.NpmDropdown />
      <AntdDropdown
        trigger={['click']}
        overlay={_overlay}
        overlayClassName={`npm-dropdown npm-dropdown-${zIndex}`}
        open={open}
        onOpenChange={onOpenChange}
        disabled={disabled}
        placement={placement}
        getPopupContainer={getPopupContainer}
      >
        {children}
      </AntdDropdown>
    </>
  );
};

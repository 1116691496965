/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CompanyPPSApi,
  CompanyPricesErrorCodes,
  CompanyPricesErrorTypes,
  CompaniesPPSErrorCodes,
  CompaniesPPSErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CompanyPPSApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompanyPricesMethod = CompanyPPSApi['companyPrices'];
export type CompanyPricesResponseType = MethodResult<CompanyPricesMethod>;

export const useCompanyPrices = (
  variables: Parameters<CompanyPricesMethod>[0],
  config?: UseQueryConfig<
    CompanyPricesMethod,
    CompanyPricesErrorTypes
  >
) => {
  return useQuery<
    CompanyPricesMethod,
    CompanyPricesErrorTypes
  >(
    (...args) => api.companyPrices(...args),
    CompanyPricesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyPrices'],
        ...apiQueryConfig.CompanyPrices,
        ...config?.queryConfig
      },
    },
    'CompanyPrices',
  );
};

export const useCompanyPricesInfinite = (
  variables: Parameters<CompanyPricesMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyPricesMethod,
    CompanyPricesErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyPricesMethod,
    CompanyPricesErrorTypes
  >(
    (...args) => api.companyPrices(...args),
    CompanyPricesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyPrices', 'CompanyPricesInfinite'],
        ...apiQueryConfig.CompanyPrices,
        ...config?.queryConfig,
      },
    },
    'CompanyPrices',
  );
};

export const useCompanyPricesLazy = (baseOptions?: {
  variables?: Parameters<CompanyPricesMethod>[0],
  config?: UseQueryConfig<
    CompanyPricesMethod,
    CompanyPricesErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyPricesMethod,
    CompanyPricesErrorTypes
  >(
    (...args) => api.companyPrices(...args),
    CompanyPricesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyPrices'],
        ...apiQueryConfig.CompanyPrices,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyPrices',
  );
};


export type CompaniesPPSMethod = CompanyPPSApi['companiesPPS'];
export type CompaniesPPSResponseType = MethodResult<CompaniesPPSMethod>;

export const useCompaniesPPS = (
  variables: Parameters<CompaniesPPSMethod>[0],
  config?: UseQueryConfig<
    CompaniesPPSMethod,
    CompaniesPPSErrorTypes
  >
) => {
  return useQuery<
    CompaniesPPSMethod,
    CompaniesPPSErrorTypes
  >(
    (...args) => api.companiesPPS(...args),
    CompaniesPPSErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesPPS'],
        ...apiQueryConfig.CompaniesPPS,
        ...config?.queryConfig
      },
    },
    'CompaniesPPS',
  );
};

export const useCompaniesPPSInfinite = (
  variables: Parameters<CompaniesPPSMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompaniesPPSMethod,
    CompaniesPPSErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompaniesPPSMethod,
    CompaniesPPSErrorTypes
  >(
    (...args) => api.companiesPPS(...args),
    CompaniesPPSErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesPPS', 'CompaniesPPSInfinite'],
        ...apiQueryConfig.CompaniesPPS,
        ...config?.queryConfig,
      },
    },
    'CompaniesPPS',
  );
};

export const useCompaniesPPSLazy = (baseOptions?: {
  variables?: Parameters<CompaniesPPSMethod>[0],
  config?: UseQueryConfig<
    CompaniesPPSMethod,
    CompaniesPPSErrorTypes
  >
}) => {
  return useLazyQuery<
    CompaniesPPSMethod,
    CompaniesPPSErrorTypes
  >(
    (...args) => api.companiesPPS(...args),
    CompaniesPPSErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompaniesPPS'],
        ...apiQueryConfig.CompaniesPPS,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompaniesPPS',
  );
};


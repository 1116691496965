import React from 'react';
import { Grid } from 'antd';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { usePagination } from '@npm/core/ui/hooks/pagination/usePagination';
import {
  type SubmissionApiSecondmarketSubmissionIndexRequest,
  useSecondmarketSubmissionIndex,
} from '@npm/data-access';

import {
  type OrdersActionsConfig,
  useOrdersConfig,
} from '../useOrdersConfig.hooks';

import * as S from './InvestorOrdersTable.styles';

type Props = {
  variables: SubmissionApiSecondmarketSubmissionIndexRequest;
  setVariables: (
    variables: SubmissionApiSecondmarketSubmissionIndexRequest
  ) => void;
  filters?: React.ReactNode;
  isSmallerView?: boolean;
} & OrdersActionsConfig;

const { useBreakpoint } = Grid;

export const InvestorOrdersTable = ({
  variables,
  setVariables,
  filters,
  ...props
}: Props) => {
  const breakpoint = useBreakpoint();

  const columns = useOrdersConfig({
    ...props,
  });

  const { data, isLoading } = useSecondmarketSubmissionIndex(variables);

  const { updatePaginationVariables, pagination } = usePagination('orders', {
    variables,
    setVariables,
    paginationData: data?.pagination,
  });

  return (
    <S.Card
      variant="secondMarket"
      header={{
        title: (
          <Flex gap="sm">
            <Label variant="error" round>
              Sell
            </Label>
            <Text colorVariant="secondary" size="sm">
              Order Items
            </Text>
          </Flex>
        ),
      }}
      noContentPadding
    >
      <Divider colorVariant="tertiary" marginBottom={0} />
      {filters}
      <S.Table
        dataSource={data?.submissions}
        loading={isLoading}
        columns={columns}
        outlined={false}
        expandable={{
          showExpandColumn: !breakpoint.xxl || props.showFees,
          rowExpandable: () => !breakpoint.xxl || props.showFees,
        }}
        pagination={pagination}
        onChange={updatePaginationVariables}
      />
    </S.Card>
  );
};

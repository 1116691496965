import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}px;

  padding: ${({ theme }) => theme.spacing.md}px;

  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background-color: ${({ theme }) => theme.color.general.layout.zero};
`;

export const CircleContainer = styled.div<{ $color: string }>`
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`;

export const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.general.layout.zero};
`;

export const PmInsightsPrice = styled.div`
  &&& > span {
    color: ${({ theme }) => theme.color.general.typography.primary} !important;
  }
`;

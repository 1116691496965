import styled from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import { Table as ComponentTable } from '@npm/core/ui/components/molecules/Table';
import { type SecondmarketSubmissionForClientOrders } from '@npm/data-access';

export const Table = styled(
  ComponentTable<SecondmarketSubmissionForClientOrders>
)`
  .ant-table-title {
    padding: 10px ${({ theme }) => theme.spacing.md}px;
  }
`;

export const Card = styled(CardBase)`
  .card-header .card-header-content {
    padding-top: 0;
    padding-bottom: 0;

    .card-header-content-left {
      margin: ${({ theme }) => theme.spacing.sm}px 0;
    }

    .card-header-content-right {
      align-self: center;
    }
  }
`;

export const TextWrapper = styled(Flex).attrs({
  align: 'center',
})`
  white-space: nowrap;

  & > svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
    min-width: 14px;
    margin-right: ${({ theme }) => theme.spacing.xs}px;
  }
`;

import { useEffect, useRef } from 'react';

import { Notification } from '@npm/core/ui/components/atoms/Notification';

import { useUserContextStore } from '../../../context';
import type { UserRoleContextType } from '../../userRole.types';

const KEY = 'role-notification';

/*
 * This hook is responsible for showing a notification when the user switches roles.
 */
export const useRoleNotification = (
  needRole: boolean,
  isLoading: boolean,
  roleContext: UserRoleContextType | null
) => {
  const previousMessage = useRef<string | null>(null);
  const investorAccounts = useUserContextStore(store => store.investorAccounts);

  useEffect(() => {
    // role notification
    if (!roleContext || isLoading || !needRole) {
      return;
    }

    let message = null;
    if (roleContext.workstationType === 'brokerage') {
      if (roleContext.subRole?.type === 'obo') {
        message = (
          <>
            {`You are now acting on behalf of '`}
            <span data-dd-privacy="mask">
              {roleContext.subRole?.account?.name}
            </span>
            {`'`}
          </>
        );
      } else {
        message = (
          <>
            {`You are now using your role '`}
            {roleContext.subRole?.role_name?.name} for{' '}
            <span data-dd-privacy="mask">
              {roleContext?.subRole?.subject?.name}
            </span>
            {`'`}
          </>
        );
      }
    }

    if (roleContext.workstationType === 'investor') {
      if (investorAccounts.length === 1) {
        return;
      }
      if (roleContext.subRole?.type === 'all-accounts') {
        message = `Viewing all your accounts`;
      } else {
        message = (
          <>
            {`You are acting as '`}
            <span data-dd-privacy="mask">
              {roleContext.subRole?.subject?.name}
            </span>
            {`'`}
          </>
        );
      }
    }

    if (roleContext.workstationType === 'issuer') {
      message = (
        <>
          {`You are now using your role '`}
          {roleContext.subRole?.role_name?.name} for{' '}
          <span data-dd-privacy="mask">
            {roleContext?.subRole?.subject?.name}
          </span>
          {`'`}
        </>
      );
    }

    if (previousMessage.current === message) {
      return;
    }

    previousMessage.current = message;
    Notification.close(KEY);
    Notification.success({
      key: KEY,
      message,
    });
  }, [roleContext, isLoading, needRole]);
};

import React, { useCallback } from 'react';
import { chunk, get } from 'lodash';

import { type ExtendedColumnType } from '../Table.types';

import * as S from './ExpandableArea.styles';

type Props<T> = {
  areaRecords: ExtendedColumnType<T>[];
  record: T;
  variant?: 'primary' | 'secondary' | 'compact';
  showExpandedContentInColumns?: boolean;
  tableVerticalClassName?: string;
};

export const ExpandableArea = <T,>({
  areaRecords,
  record,
  variant,
  showExpandedContentInColumns,
  tableVerticalClassName,
}: Props<T>) => {
  const getValue = useCallback(
    ({ key, render }) =>
      typeof render === 'function'
        ? // index passed because it is required in column render method by antd
          render(record[key], record, -1)
        : render,
    [record]
  );

  // hide row if asExtraRow defined and passes the condition
  const filtered = areaRecords.filter(({ hidden, asExtraRow, key, title }) => {
    if (typeof hidden === 'function') {
      return !hidden(key == null ? undefined : get(record, key), record as T);
    }
    if (hidden) return false;

    // Corner case when we have some rows without value in a column without title
    if (!title && (key == null || !record[key])) return false;
    if (asExtraRow === undefined) return true;
    return asExtraRow(key == null ? undefined : record[key], record);
  });

  const rows = filtered.map(({ key, title, render, titleInfo }) => ({
    key,
    title: title as string,
    titleInfo,
    render: () => getValue({ key, render }),
  }));

  if (showExpandedContentInColumns) {
    const [leftColumn, rightColumn] = chunk(rows, Math.ceil(rows.length / 2));
    return (
      <S.Container>
        <S.PaddedColumnsContainer
          columns={[
            <S.TableVertical
              $variant={variant}
              rows={leftColumn ?? []}
              data={undefined}
              key="leftColumn"
            />,
            <S.TableVertical
              $variant={variant}
              rows={rightColumn ?? []}
              data={undefined}
              key="rightColumn"
            />,
          ]}
        />
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.TableVertical
        $variant={variant}
        rows={rows}
        data={undefined}
        borderVariant="secondary"
        className={tableVerticalClassName}
      />
    </S.Container>
  );
};

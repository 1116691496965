/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdvisorReferral } from '../model';
// @ts-ignore
import { AdvisorReferralCreateRequestContract } from '../model';
// @ts-ignore
import { AdvisorReferralIndex } from '../model';
// @ts-ignore
import { AdvisorReferralUpdateRequestContract } from '../model';
/**
 * AdvisorReferralsApi - axios parameter creator
 * @export
 */
export const AdvisorReferralsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a referral
         * @param {AdvisorReferralCreateRequestContract} advisorReferralCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralCreate: async (advisorReferralCreateRequestContract: AdvisorReferralCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorReferralCreateRequestContract' is not null or undefined
            assertParamExists('advisorReferralCreate', 'advisorReferralCreateRequestContract', advisorReferralCreateRequestContract)
            const localVarPath = changeUrl(`/api/advisor-referrals`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advisorReferralCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a referral
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralDestroy: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('advisorReferralDestroy', 'id', id)
            const localVarPath = changeUrl(`/api/advisor-referrals/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list referrals
         * @param {string} [search] 
         * @param {number} [assignedToUserId] 
         * @param {string} [status] 
         * @param {string} [wealthAssets] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralIndex: async (search?: string, assignedToUserId?: number, status?: string, wealthAssets?: string, sort?: string, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/advisor-referrals`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (assignedToUserId !== undefined) {
                localVarQueryParameter['assigned_to_user_id'] = assignedToUserId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (wealthAssets !== undefined) {
                localVarQueryParameter['wealth_assets'] = wealthAssets;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show a referral
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralShow: async (id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('advisorReferralShow', 'id', id)
            const localVarPath = changeUrl(`/api/advisor-referrals/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a referral
         * @param {number} id 
         * @param {AdvisorReferralUpdateRequestContract} advisorReferralUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralUpdate: async (id: number, advisorReferralUpdateRequestContract: AdvisorReferralUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('advisorReferralUpdate', 'id', id)
            // verify required parameter 'advisorReferralUpdateRequestContract' is not null or undefined
            assertParamExists('advisorReferralUpdate', 'advisorReferralUpdateRequestContract', advisorReferralUpdateRequestContract)
            const localVarPath = changeUrl(`/api/advisor-referrals/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advisorReferralUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvisorReferralsApi - functional programming interface
 * @export
 */
export const AdvisorReferralsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvisorReferralsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a referral
         * @param {AdvisorReferralCreateRequestContract} advisorReferralCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advisorReferralCreate(advisorReferralCreateRequestContract: AdvisorReferralCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvisorReferral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advisorReferralCreate(advisorReferralCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a referral
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advisorReferralDestroy(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvisorReferral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advisorReferralDestroy(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list referrals
         * @param {string} [search] 
         * @param {number} [assignedToUserId] 
         * @param {string} [status] 
         * @param {string} [wealthAssets] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advisorReferralIndex(search?: string, assignedToUserId?: number, status?: string, wealthAssets?: string, sort?: string, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvisorReferralIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advisorReferralIndex(search, assignedToUserId, status, wealthAssets, sort, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show a referral
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advisorReferralShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvisorReferral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advisorReferralShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a referral
         * @param {number} id 
         * @param {AdvisorReferralUpdateRequestContract} advisorReferralUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advisorReferralUpdate(id: number, advisorReferralUpdateRequestContract: AdvisorReferralUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvisorReferral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advisorReferralUpdate(id, advisorReferralUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvisorReferralsApi - factory interface
 * @export
 */
export const AdvisorReferralsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvisorReferralsApiFp(configuration)
    return {
        /**
         * 
         * @summary create a referral
         * @param {AdvisorReferralCreateRequestContract} advisorReferralCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralCreate(advisorReferralCreateRequestContract: AdvisorReferralCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AdvisorReferral> {
            return localVarFp.advisorReferralCreate(advisorReferralCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a referral
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralDestroy(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AdvisorReferral> {
            return localVarFp.advisorReferralDestroy(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list referrals
         * @param {string} [search] 
         * @param {number} [assignedToUserId] 
         * @param {string} [status] 
         * @param {string} [wealthAssets] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralIndex(search?: string, assignedToUserId?: number, status?: string, wealthAssets?: string, sort?: string, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AdvisorReferralIndex> {
            return localVarFp.advisorReferralIndex(search, assignedToUserId, status, wealthAssets, sort, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show a referral
         * @param {number} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralShow(id: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AdvisorReferral> {
            return localVarFp.advisorReferralShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a referral
         * @param {number} id 
         * @param {AdvisorReferralUpdateRequestContract} advisorReferralUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisorReferralUpdate(id: number, advisorReferralUpdateRequestContract: AdvisorReferralUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AdvisorReferral> {
            return localVarFp.advisorReferralUpdate(id, advisorReferralUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for advisorReferralCreate operation in AdvisorReferralsApi.
 * @export
 * @interface AdvisorReferralsApiAdvisorReferralCreateRequest
 */
export interface AdvisorReferralsApiAdvisorReferralCreateRequest {
    /**
     * 
     * @type {AdvisorReferralCreateRequestContract}
     * @memberof AdvisorReferralsApiAdvisorReferralCreate
     */
    readonly advisorReferralCreateRequestContract: AdvisorReferralCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for advisorReferralDestroy operation in AdvisorReferralsApi.
 * @export
 * @interface AdvisorReferralsApiAdvisorReferralDestroyRequest
 */
export interface AdvisorReferralsApiAdvisorReferralDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof AdvisorReferralsApiAdvisorReferralDestroy
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralDestroy
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralDestroy
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralDestroy
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralDestroy
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralDestroy
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralDestroy
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for advisorReferralIndex operation in AdvisorReferralsApi.
 * @export
 * @interface AdvisorReferralsApiAdvisorReferralIndexRequest
 */
export interface AdvisorReferralsApiAdvisorReferralIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly assignedToUserId?: number

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly wealthAssets?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for advisorReferralShow operation in AdvisorReferralsApi.
 * @export
 * @interface AdvisorReferralsApiAdvisorReferralShowRequest
 */
export interface AdvisorReferralsApiAdvisorReferralShowRequest {
    /**
     * 
     * @type {number}
     * @memberof AdvisorReferralsApiAdvisorReferralShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for advisorReferralUpdate operation in AdvisorReferralsApi.
 * @export
 * @interface AdvisorReferralsApiAdvisorReferralUpdateRequest
 */
export interface AdvisorReferralsApiAdvisorReferralUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof AdvisorReferralsApiAdvisorReferralUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AdvisorReferralUpdateRequestContract}
     * @memberof AdvisorReferralsApiAdvisorReferralUpdate
     */
    readonly advisorReferralUpdateRequestContract: AdvisorReferralUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AdvisorReferralsApiAdvisorReferralUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * AdvisorReferralsApi - object-oriented interface
 * @export
 * @class AdvisorReferralsApi
 * @extends {BaseAPI}
 */
export class AdvisorReferralsApi extends BaseAPI {
    /**
     * 
     * @summary create a referral
     * @param {AdvisorReferralsApiAdvisorReferralCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorReferralsApi
     */
    public advisorReferralCreate(requestParameters: AdvisorReferralsApiAdvisorReferralCreateRequest, options?: AxiosRequestConfig) {
        return AdvisorReferralsApiFp(this.configuration).advisorReferralCreate(requestParameters.advisorReferralCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a referral
     * @param {AdvisorReferralsApiAdvisorReferralDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorReferralsApi
     */
    public advisorReferralDestroy(requestParameters: AdvisorReferralsApiAdvisorReferralDestroyRequest, options?: AxiosRequestConfig) {
        return AdvisorReferralsApiFp(this.configuration).advisorReferralDestroy(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list referrals
     * @param {AdvisorReferralsApiAdvisorReferralIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorReferralsApi
     */
    public advisorReferralIndex(requestParameters: AdvisorReferralsApiAdvisorReferralIndexRequest = {}, options?: AxiosRequestConfig) {
        return AdvisorReferralsApiFp(this.configuration).advisorReferralIndex(requestParameters.search, requestParameters.assignedToUserId, requestParameters.status, requestParameters.wealthAssets, requestParameters.sort, requestParameters.page, requestParameters.size, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show a referral
     * @param {AdvisorReferralsApiAdvisorReferralShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorReferralsApi
     */
    public advisorReferralShow(requestParameters: AdvisorReferralsApiAdvisorReferralShowRequest, options?: AxiosRequestConfig) {
        return AdvisorReferralsApiFp(this.configuration).advisorReferralShow(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a referral
     * @param {AdvisorReferralsApiAdvisorReferralUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorReferralsApi
     */
    public advisorReferralUpdate(requestParameters: AdvisorReferralsApiAdvisorReferralUpdateRequest, options?: AxiosRequestConfig) {
        return AdvisorReferralsApiFp(this.configuration).advisorReferralUpdate(requestParameters.id, requestParameters.advisorReferralUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AdvisorReferralCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AdvisorReferralCreateErrorCodes = [
    401,
];

export type AdvisorReferralDestroyErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AdvisorReferralDestroyErrorCodes = [
    401,
];

export type AdvisorReferralIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AdvisorReferralIndexErrorCodes = [
    401,
];

export type AdvisorReferralShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AdvisorReferralShowErrorCodes = [
    401,
];

export type AdvisorReferralUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AdvisorReferralUpdateErrorCodes = [
    401,
];



import styled from 'styled-components';

const COMPANY_CARD_HEIGHT = 40;

export const CompanyCardWrapper = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${COMPANY_CARD_HEIGHT}px;

  margin-right: ${({ theme }) => theme.spacing.sm}px;

  border: ${({ theme, $isSelected }) =>
    $isSelected ? `1px solid ${theme.color.info.borders.primary}` : `none`};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  padding: ${({ theme }) => theme.spacing.sm}px;
  background-color: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.color.general.layout.one
      : theme.color.general.layout.two};

  &:hover {
    background-color: ${({ theme }) =>
      theme.color.info.backgrounds.secondaryHover};
    box-shadow: ${({ theme }) => theme.shadow.level1};
    cursor: pointer;
  }
`;

export const TextWrap = styled.div`
  padding: ${({ theme }) => theme.spacing.sm}px;
  margin-left: ${({ theme }) => theme.spacing.sm}px;
`;

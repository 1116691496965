import { useLocalStorage } from 'usehooks-ts';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useModal } from '@npm/core/ui/components/molecules/Modal';
import { SuccessModal } from '@npm/core/ui/components/organisms/SuccessModal';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { type IssuerEntityAggregate } from '@npm/data-access';

import { useZendesk, ZendeskCustomFieldName } from '../../../../app/zendesk';

import * as S from './NotCoveredBanner.styles';

const LS_COMPANY_DATA_REQUESTED_PREFIX_KEY = 'company_data_requested';

const lightBackground = '/public/assets/backgrounds/bg-not-covered-light.png';
const darkBackground = '/public/assets/backgrounds/bg-not-covered-dark.png';

const getCompanyDataRequestedLocalStorageKey = (companyId: number) =>
  `${LS_COMPANY_DATA_REQUESTED_PREFIX_KEY}_${companyId}`;

type Props = {
  issuerEntity: IssuerEntityAggregate | undefined;
  heading?: string;
  title?: React.ReactNode;
  hideButton?: boolean;
};

export const NotCoveredBanner = ({
  issuerEntity,
  heading = "We don't have this data yet",
  title = 'Ping our research team and we will notify you when it is available',
  hideButton = false,
}: Props) => {
  const [openSuccessModal, successModalProps] = useModal();

  const { createCustomerRequest, createRequestLoading } = useZendesk();
  const { showAlert } = useAlerts();
  const { isDarkMode } = useDarkModeContext();

  const handleZendeskRequest = async () => {
    const { success, message } = await createCustomerRequest(
      'second_market_company_overview',
      `I would like to know more about ${
        issuerEntity?.name
      }. Requested at ${new Date().toUTCString()}.`,
      {
        [ZendeskCustomFieldName.CompanyName]: issuerEntity?.name,
      }
    );
    if (success) {
      openSuccessModal();
      return Promise.resolve();
    } else {
      showAlert(message, {
        type: 'error',
      });
      return Promise.reject();
    }
  };

  const [companyCoverageRequested, setCompanyCoverageRequested] =
    useLocalStorage(
      getCompanyDataRequestedLocalStorageKey(issuerEntity?.id),
      false
    );

  const handleRequestMoreDataClick = async () => {
    try {
      await handleZendeskRequest();
      setCompanyCoverageRequested(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <S.Card>
      <NoDataArea
        heading={heading}
        title={title}
        iconColor="info"
        actionButton={
          hideButton ? undefined : (
            <Button
              variant="default"
              color="info"
              disabled={companyCoverageRequested}
              onClick={handleRequestMoreDataClick}
              loading={createRequestLoading}
            >
              {companyCoverageRequested
                ? 'Data has been requested'
                : createRequestLoading
                ? 'Processing...'
                : 'Request Data'}
            </Button>
          )
        }
        bgUrlImg={isDarkMode ? darkBackground : lightBackground}
      />
      <SuccessModal
        {...successModalProps}
        icon="circle-check"
        title="Request sent"
        subTitle="We will notify you when this data is available"
      />
    </S.Card>
  );
};

import styled, {
  type ColorGroup,
  type DefaultTheme,
  css,
} from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';

const TEXT_AREA_WIDTH_SMALL = 320;
const TEXT_AREA_WIDTH_BIG = 480;

export const Wrapper = styled.div<{
  $outlined: boolean;
  $verticalPadding: keyof DefaultTheme['spacing'] | number;
  $bgUrlImg?: string;
}>`
  ${centerFlex};
  flex-direction: column;
  padding: ${({ theme, $verticalPadding }) =>
      typeof $verticalPadding === 'number'
        ? $verticalPadding
        : theme.spacing[$verticalPadding]}px
    0;
  background-color: ${({ theme }) => theme.color.general.layout.one};
  ${({ theme, $outlined }) =>
    $outlined &&
    css`
      border-radius: ${theme.borderRadius.lg}px;
      border: 1px solid ${theme.color.general.borders.primary};
    `};

  ${({ $bgUrlImg }) =>
    $bgUrlImg &&
    css`
      background-image: url(${$bgUrlImg});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top;
    `}
`;

export const TextWrapper = styled.div`
  max-width: ${TEXT_AREA_WIDTH_BIG}px;
  text-align: center;

  ${({ theme }) => useBreakpoint(theme).desktop`
      max-width: ${TEXT_AREA_WIDTH_SMALL}px;
  `}
`;

export const IconWrap = styled.div<{ $color: ColorGroup }>`
  svg {
    color: ${({ theme, $color }) =>
      theme.color[$color || 'info'].typography.primary};
    margin-bottom: ${({ theme }) => theme.spacing.lg / 2}px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
`;

import Big from 'big.js';

export const calculateEstimatedNetPPS = (
  pps: string | number | undefined,
  executionFee: number | undefined,
  isBuy: boolean
): number | undefined => {
  if (!!pps && !!executionFee) {
    const result = new Big(pps)
      .div(100)
      .mul(new Big(100).add(isBuy ? executionFee : -1 * executionFee));

    if (result.lt(0)) {
      return undefined;
    }

    return result.toNumber();
  }

  return undefined;
};

import styled, { type ColorGroup } from 'styled-components';

// TODO: NFE-6558: add $hyperlinkColor prop to Text / Button components
export const TextLink = styled.span<{ $color?: ColorGroup }>`
  color: ${({ theme, $color }) =>
    $color
      ? theme.color[$color].typography.primary
      : theme.color.info.typography.primary};
  cursor: pointer;
  transition: 0.3s;

  :hover {
    color: ${({ theme, $color }) => {
      const colorTheme = $color && theme.color[$color];

      return colorTheme && 'primaryHover' in colorTheme.backgrounds
        ? colorTheme.backgrounds.primaryHover
        : theme.color.info.backgrounds.primaryHover;
    }};
  }
`;

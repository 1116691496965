import React, { type FC } from 'react';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { LAYOUT_WRAPPER_ID, useNoBottomPaddingLayout } from '../../Layout';
import { useLayoutStore } from '../../Layout/Layout.store';
import { useWizardStore } from '../Wizard.store';
import { type WizardStore } from '../Wizard.types';

import { Content, ContentMobile } from './Content';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

import * as S from './WizardLayout.styles';

export const WIZARD_BODY_ID = 'wizard-body';

type Props = {
  title: React.ReactNode;
  exitRoute: string;
  titleExtra?: React.ReactNode;
  subHeader?: React.ReactNode;
  /**
   * Whether the subheader should be affixed under the main page header on desktop
   */
  isSubHeaderAffixed?: boolean;
  /**
   * Custom sidebar content, fallback to a simple list of `StepperItem` buttons
   * based on the Wizard's steps
   */
  renderSidebar?: (
    props: React.ComponentProps<typeof Sidebar>
  ) => React.ReactNode;
  /**
   * Custom title to render above the default sidebar
   */
  sidebarTitle?: React.ReactNode;
  /**
   * Fixed panel based on the bottom of the Wizard
   */
  sidebarTopSection?: React.ReactNode;
  expandablePanel?: React.ReactNode;
  /**
   * Function to determine whether a step is completed
   */
  isStepCompleted?: <T>(ctx: WizardStore<T>, step: number) => boolean;
  handleCloseFormAttempt?: (callback?: () => void) => void;
  wide?: boolean;
  children?: React.ReactNode;
};

export const WizardLayout: FC<Props> = ({
  children,
  title,
  exitRoute,
  titleExtra,
  renderSidebar,
  sidebarTitle,
  sidebarTopSection,
  subHeader,
  isSubHeaderAffixed = true,
  expandablePanel,
  isStepCompleted,
  handleCloseFormAttempt,
  wide = false,
}) => {
  const { isMobile, isTablet } = useBreakpoints();
  const isMobileView = isMobile || isTablet;

  useNoBottomPaddingLayout();
  const maxWidth = useLayoutStore(store => store.maxWidth);
  const isStepperOpen = useWizardStore(s => s.isStepperMobileOpen);

  const header = (
    <Header
      title={title}
      titleExtra={titleExtra}
      exitRoute={exitRoute}
      handleCloseFormAttempt={handleCloseFormAttempt}
    />
  );

  const sidebarProps = {
    title: sidebarTitle,
    isStepCompleted,
    handleCloseFormAttempt,
  };

  const sidebarContent = renderSidebar ? (
    renderSidebar(sidebarProps)
  ) : (
    <>
      {sidebarTopSection}
      <Sidebar {...sidebarProps} />
    </>
  );

  return (
    <S.FullScreenWrapper id={LAYOUT_WRAPPER_ID}>
      {isMobileView && header}
      <S.MaxWidthContainer>
        {!isMobileView && header}
        <S.MainContainer>
          {isMobileView ? (
            <ContentMobile header={subHeader} sidebarContent={sidebarContent}>
              {children}
            </ContentMobile>
          ) : (
            <Content
              header={subHeader}
              isHeaderAffixed={isSubHeaderAffixed}
              sidebarContent={sidebarContent}
              wide={wide}
            >
              {children}
            </Content>
          )}
        </S.MainContainer>
        {expandablePanel && !isStepperOpen && (
          <S.ExpandablePanelWrapper $maxWidth={maxWidth}>
            {expandablePanel}
          </S.ExpandablePanelWrapper>
        )}
      </S.MaxWidthContainer>
    </S.FullScreenWrapper>
  );
};

import styled from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

export const AverageAndBestLevelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md}px;
  ${({ theme }) =>
    useBreakpoint(theme).smDesktop`
      flex-direction: column;
  `};
`;

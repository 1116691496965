import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type VenusApi } from '@npm/data-access';

import { Classifications } from '../../../../../company/CompanyOverview/General/CorporateProfile/components/Classifications';
import { CorporateProfileSkeleton } from '../../../../../company/CompanyOverview/General/skeletons';

import * as S from './CorporateProfile.styles';

type Props = {
  companyProfile: VenusApi.CompanyProfile | undefined;
  isLoading?: boolean;
};

export const CorporateProfile = ({ companyProfile, isLoading }: Props) => {
  if (isLoading) return <CorporateProfileSkeleton />;

  return (
    <S.Container>
      <S.Item>
        <Text size="sm" as="h4" marginBottom="xs">
          Classifications
        </Text>
        <Classifications companyProfile={companyProfile} />
      </S.Item>
      <S.Item>
        <Text size="sm" as="h4" marginBottom="xs">
          Legal Counsel
        </Text>
        {companyProfile?.legal_counsel}
      </S.Item>
    </S.Container>
  );
};

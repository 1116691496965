import styled from 'styled-components';

import { Icon as IconBase } from '@npm/core/ui/components/atoms/Icon';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.color.warning.backgrounds.secondaryDefault};
`;

export const Icon = styled(IconBase)`
  color: ${({ theme }) => theme.color.warning.backgrounds.primaryHover};
`;

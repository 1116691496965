import React from 'react';
import { type ColorGroup } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { PercentageChange } from '@npm/core/ui/components/molecules/PercentageChange';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type CompanyPricingApi } from '@npm/data-access';

import { type PriceType } from '../../../../../CompanyOverview.types';
import { type SelectedPointData } from '../../BidOfferHistoryChart.types';

import * as S from './BidOfferHistoryChartTooltip.styles';

type Props = {
  point: SelectedPointData | undefined;
  priceType: PriceType;
};

const TooltipDataItem = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactElement;
}) => {
  return (
    <Flex direction="column" gap="xs" justify="space-between">
      <Text size="xs">{label}</Text>
      {value}
    </Flex>
  );
};

const Tooltip = ({
  title,
  titleColor,
  date,
  footer,
  children,
}: {
  title: string;
  titleColor?: ColorGroup;
  children: React.ReactElement;
  date: string;
  footer?: React.ReactElement;
}) => {
  return (
    <S.Wrapper>
      <Flex direction="column" gap="sm" align="stretch">
        <Flex
          direction="row"
          gap={'xs'}
          justify="space-between"
          align="center"
          $wrap
        >
          <Text
            color={titleColor}
            style={{
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
            }}
          >
            {title}
          </Text>
          <Text size="xs">
            {formatDate(date, { dateFormat: DATE_FORMATS.MONTH_AND_YEAR })}
          </Text>
        </Flex>
        <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
        {footer}
      </Flex>
    </S.Wrapper>
  );
};

export const BidOfferHistoryChartTooltip = ({ point, priceType }: Props) => {
  const askData =
    point?.type === 'ask'
      ? (point.data as CompanyPricingApi.HistoricalPriceIndex['historical_prices']['aggregated_asks'][0])
      : null;

  const bidData =
    point?.type === 'bid'
      ? (point.data as CompanyPricingApi.HistoricalPriceIndex['historical_prices']['aggregated_bids'][0])
      : null;

  const tradeData =
    point?.type === 'trade'
      ? (point.data as CompanyPricingApi.HistoricalPriceIndex['historical_prices']['aggregated_trades'][0])
      : null;

  const valuationRoundData =
    point?.type === 'round'
      ? (point.data as CompanyPricingApi.HistoricalPriceIndex['historical_prices']['primary_rounds'][0])
      : null;

  if (askData) {
    const sizeRange = priceType === 'average' && !!askData.total_value && (
      <>
        <Divider type="horizontal" marginBottom="sm" />
        <Flex justify="space-between">
          <Text colorVariant="secondary">Size Range</Text>
          <Text colorVariant="secondary">{askData.total_value}</Text>
        </Flex>
      </>
    );

    return (
      <Tooltip
        title="Offer Level"
        titleColor="error"
        date={askData.date}
        footer={
          <>
            {sizeRange}
            <S.FooterText size="xxs">
              The offer level is computed monthly by NPM based <br /> on market
              orders and deals in your order book.
            </S.FooterText>
          </>
        }
      >
        <>
          <TooltipDataItem
            label="Price Per Share"
            value={
              <Flex>
                <Text.Price
                  value={askData.price_per_share}
                  formatOptions={{
                    long: true,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }}
                  size="default"
                />
                {!!askData.discount_or_premium && (
                  <PercentageChange
                    change={askData.discount_or_premium * 100}
                    maximumFractionDigits={0}
                  />
                )}
              </Flex>
            }
          />
          <TooltipDataItem
            label="Implied Val."
            value={
              <Text.Price
                value={askData.implied_valuation}
                compact
                size="default"
              />
            }
          />
        </>
      </Tooltip>
    );
  }

  if (bidData) {
    const sizeRange = priceType === 'average' && !!bidData.total_value && (
      <>
        <Divider type="horizontal" marginBottom="sm" />
        <Flex justify="space-between">
          <Text colorVariant="secondary">Size Range</Text>
          <Text colorVariant="secondary">{bidData.total_value}</Text>
        </Flex>
      </>
    );

    return (
      <Tooltip
        title="Bid Level"
        titleColor="success"
        date={bidData.date}
        footer={
          <>
            {sizeRange}
            <S.FooterText size="xxs">
              The bid level is computed monthly by NPM based <br /> on market
              orders and deals in your order book.
            </S.FooterText>
          </>
        }
      >
        <>
          <TooltipDataItem
            label="Price Per Share"
            value={
              <Flex>
                <Text.Price
                  value={bidData.price_per_share}
                  formatOptions={{
                    long: true,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }}
                  size="default"
                />
                {!!bidData.discount_or_premium && (
                  <PercentageChange
                    change={bidData.discount_or_premium * 100}
                    maximumFractionDigits={0}
                  />
                )}
              </Flex>
            }
          />
          <TooltipDataItem
            label="Implied Val."
            value={
              <Text.Price
                value={bidData.implied_valuation}
                compact
                size="default"
              />
            }
          />
        </>
      </Tooltip>
    );
  }

  if (tradeData) {
    return (
      <Tooltip title="Reported Trade Level" date={tradeData.date}>
        <S.TableVertical
          rows={[
            {
              title: 'PPS',
              render: () => (
                <Flex>
                  <Text.Price
                    value={tradeData.price_per_share}
                    formatOptions={{
                      long: true,
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }}
                    size="default"
                  />
                  {!!tradeData.discount_or_premium && (
                    <PercentageChange
                      change={tradeData.discount_or_premium * 100}
                      maximumFractionDigits={0}
                    />
                  )}
                </Flex>
              ),
            },
            {
              title: 'Implied Val.',
              render: () => (
                <Text.Price
                  value={tradeData.implied_valuation}
                  compact
                  size="default"
                />
              ),
            },
            {
              title: 'Size Range',
              render: () => <Text>{tradeData.total_value}</Text>,
            },
          ]}
          data={tradeData}
        />
      </Tooltip>
    );
  }

  if (valuationRoundData) {
    return (
      <Tooltip
        title={valuationRoundData.name}
        titleColor="general"
        date={valuationRoundData.date}
      >
        <>
          <TooltipDataItem
            label="Price Per Share"
            value={
              <Text.Price
                value={valuationRoundData.price_per_share}
                formatOptions={{
                  long: true,
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }}
                size="default"
              />
            }
          />
          <TooltipDataItem
            label="Est. Valuation"
            value={
              <Text.Price
                value={valuationRoundData.valuation}
                compact
                size="default"
              />
            }
          />
        </>
      </Tooltip>
    );
  }

  return null;
};

import { useTheme } from 'styled-components';
import { type DefaultTheme } from 'styled-components';

import { useDarkModeContext } from '../../../context/DarkModeContext';

type Props = {
  size?: keyof DefaultTheme['typography']['size'];
};

export const PmInsightsLogo = ({ size = 'md' }: Props) => {
  const theme = useTheme();

  const { isDarkMode } = useDarkModeContext();

  return (
    <a
      href={'https://www.pminsights.com'}
      target={'_blank'}
      style={{ cursor: 'pointer' }}
      rel="noreferrer"
    >
      <img
        src={
          isDarkMode
            ? '/public/assets/pm-insights-logo-light.png'
            : '/public/assets/pm-insights-logo-dark.png'
        }
        alt="PM Insights logo"
        style={{ height: `${theme.typography.size[size]}px` }}
      />
    </a>
  );
};

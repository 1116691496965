import { type IconNames } from '@npm/utils';

import { Codebooks } from './codebooks.types';

const items = {
  gross_proceeds_high_to_low: 'gross_proceeds_high_to_low',
  gross_proceeds_low_to_high: 'gross_proceeds_low_to_high',
  equity_potential_high_to_low: 'equity_potential_high_to_low',
  equity_potential_low_to_high: 'equity_potential_low_to_high',
} as const;

const iconMap: Partial<Record<keyof typeof items, IconNames>> = {
  gross_proceeds_high_to_low: 'sort-descending',
  gross_proceeds_low_to_high: 'sort-ascending',
  equity_potential_high_to_low: 'sort-descending',
  equity_potential_low_to_high: 'sort-ascending',
};

export const CbProspectsSort = {
  code: Codebooks.PROSPECTS_SORT,
  items,
  iconMap,
};

export enum ZendeskCustomFieldName {
  Email = 'Email',
  Workstation = 'Workstation',
  PageUrl = 'PageUrl',
  AccountName = 'AccountName',
  AccountExternalId = 'AccountExternalId',
  SizeShares = 'SizeShares',
  Side = 'Side',
  CompanyName = 'CompanyName',
  SizeUsd = 'SizeUsd',
  PricePerShare = 'PricePerShare',
}

export type CreateCustomerResult = {
  success: boolean;
  message: string;
};

export type ZendeskTicketGroupID =
  | 'onboarding_individual_identity_verification'
  | 'onboarding_entity_identity_verification'
  | 'onboarding_entity_accreditation'
  | 'onboarding_individual_accreditation'
  | 'onboarding_entity_tax_id'
  | 'onboarding_individual_tax_id'
  | 'onboarding_individual_government_id'
  | 'onboarding_entity_marketplace_agreement'
  | 'onboarding_individual_marketplace_agreement'
  | 'onboarding_entity_paying_agent_agreement'
  | 'onboarding_individual_paying_agent_agreement'
  | 'onboarding_entity_formation_document'
  | 'onboarding_inqury'
  | 'post_onboarding_entity_accreditation'
  | 'post_onboarding_individual_accreditation'
  | 'post_onboarding_individual_tax_id'
  | 'post_onboarding_individual_government_id'
  | 'post_onboarding_entity_tax_id'
  | 'post_onboarding_entity_government_id'
  | 'post_onboarding_entity_formation_document'
  | 'post_onboarding_entity_marketplace_agreement'
  | 'post_onboarding_individual_marketplace_agreement'
  | 'post_onboarding_entity_paying_agent_agreement'
  | 'post_onboarding_individual_paying_agent_agreement'
  | 'post_onboarding_individual_tax_information_state'
  | 'post_onboarding_entity_tax_information_state'
  | 'submission_entity_accreditation'
  | 'submission_individual_accreditation'
  | 'submission_entity_tax_id'
  | 'submission_individual_tax_id'
  | 'submission_entity_government_id'
  | 'submission_individual_government_id'
  | 'page_error'
  | 'api_request_error'
  | 'data_plan_inquiry'
  | 'company_investment_data_table_filings'
  | 'order_entry_accreditation'
  | 'submission_under_review'
  | 'second_market_market_feed'
  | 'second_market_company_overview'
  | 'marketplace_application'
  | 'company_coverage_request'
  | 'online_submission_progress'
  | 'opportunities_in_contract_rofr_exercised'
  | 'disabled_trading'
  | 'transaction_summary_negotiate_access';

export type ZendeskTicketDomainTag =
  | 'onboarding'
  | 'submission'
  | 'company'
  | 'post_onboarding'
  | 'opportunities'
  | 'user-investor';
export type ZendeskTicketSubDomainTag =
  | 'accreditation_status'
  | 'marketplace_agreement'
  | 'tax_id'
  | 'government_id'
  | 'paying_agent_agreement'
  | 'formation_document'
  | 'investment_data_table'
  | 'filings'
  | 'disabled_trading';
export type ZendeskTicketPageTitleTag =
  | 'accredited_investor_status'
  | 'entity_tax_id'
  | 'formation_document'
  | 'marketplace_agreement'
  | 'paying_agent_services_agreement'
  | 'government_issued_id'
  | 'individual_tax_id'
  | 'representative_tax_id'
  | 'tax_information';
export type ZendeskTicketGenericTag =
  | 'page_error'
  | 'api_request_error'
  | 'order_entry'
  | 'company-overview';
export type ZendeskTicketOperationTag = 'data_plan_inquiry';
export type ZendeskTicketResourceTag = 'entity_account' | 'individual_account';

export type ZendeskTicketTag =
  | ZendeskTicketDomainTag
  | ZendeskTicketSubDomainTag
  | ZendeskTicketGenericTag
  | ZendeskTicketOperationTag
  | ZendeskTicketResourceTag
  | ZendeskTicketPageTitleTag;

export type ZendeskTicketGroupConfig = {
  title: string | null;
  tags: ZendeskTicketTag[];
  configTitleOnly?: boolean;
};

import { useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import {
  type Watchlist,
  useWatchlistCreate,
  useWatchlistDestroy,
} from '@npm/data-access';

import { WatchlistInput } from '../../../account/WatchlistInput/WatchlistInput';

type Props = {
  visible: boolean;
  onClose: () => void;
  accountId: number;
  onCompanyChange: (companyId: number) => void;
  watchlists: Watchlist[];
};

type FormValues = {
  watchlist: string[];
};

export const AddCompanyModal = ({
  visible,
  onClose,
  accountId,
  onCompanyChange,
  watchlists,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const { withShowApiErrorMiddleware } = useAlerts();

  const selectedIssuerEntityIds = Form.useWatch('watchlist', form);

  const { execute: createWatchlist, isLoading: createLoading } =
    useWatchlistCreate();
  const { execute: deleteWatchlist, isLoading: deleteLoading } =
    useWatchlistDestroy();

  const existingIssuerEntityIds = useMemo(
    () => watchlists.map(watchlist => String(watchlist.issuer_entity_id)),
    [watchlists]
  );

  const handleConfirm = async () => {
    let values: FormValues;

    try {
      values = await form.validateFields();
    } catch (error) {
      console.error(error);
      return;
    }

    const addedIds = values.watchlist.filter(
      id => !existingIssuerEntityIds.includes(id)
    );

    const removedIds = existingIssuerEntityIds.filter(
      id => !values.watchlist.includes(id)
    );

    if (addedIds.length > 0) {
      await withShowApiErrorMiddleware(createWatchlist)({
        watchlistCreateRequestContract: {
          account_id: accountId,
          issuer_entity_id: addedIds.map(Number),
        },
      });
    }

    if (removedIds.length > 0) {
      await Promise.all(
        removedIds.map(async issuerEntityId => {
          await withShowApiErrorMiddleware(deleteWatchlist)({
            issuerEntityId: Number(issuerEntityId),
            watchlistAccountId: accountId,
          });
        })
      );
    }
    Notification.success({
      message: `You have updated your companies.`,
    });
    onClose();

    if (addedIds.length > 0) {
      onCompanyChange(Number(addedIds[addedIds.length - 1]));
    }
  };

  const onCancel = () => {
    if (!isDropdownOpen) {
      form.resetFields();
      onClose();
    }
    setIsDropdownOpen(false);
  };

  const handleFocus = () => {
    setIsDropdownOpen(true);
  };

  return (
    <Modal
      open={visible}
      title="Manage Companies in your Dashboard"
      okText="Confirm Selection"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleConfirm}
      okButtonProps={{
        loading: createLoading || deleteLoading,
        disabled:
          !selectedIssuerEntityIds?.length ||
          isEqual(existingIssuerEntityIds, selectedIssuerEntityIds),
      }}
      destroyOnClose
    >
      <Form form={form}>
        <FormItem
          name={'watchlist'}
          initialValue={existingIssuerEntityIds}
          label={'Select Company'}
          rules={[
            VALIDATION_RULES.arrayMinLength(
              1,
              'Please select at least one company'
            ),
          ]}
        >
          <WatchlistInput onFocus={handleFocus} watchlists={watchlists} />
        </FormItem>
      </Form>
    </Modal>
  );
};

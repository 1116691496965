import React from 'react';
import { useTheme } from 'styled-components';

import { Icon } from '../../../atoms/Icon';
import { Text } from '../../../atoms/Typography';

import * as S from './ListItem.styles';

interface Props {
  title: string;
  size?: 'md' | 'sm';
  style?: React.CSSProperties;
}

export const ListItem = ({ title, size = 'md', style }: Props) => {
  const theme = useTheme();

  return (
    <S.Flex direction="row" align="center" gap={size} style={style}>
      <Icon
        name="check"
        color={theme.color.success.typography.primary}
        size="xxs"
      />
      <Text size={size === 'md' ? 'sm' : 'xs'}>{title}</Text>
    </S.Flex>
  );
};

import { type ModalProps } from 'antd/lib/modal';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';

import * as S from './DisabledTradingModal.styles';

type Props = Pick<ModalProps, 'open' | 'onOk'> & {
  companyName: string;
};

export const DisabledTradingModal = ({ open, onOk, companyName }: Props) => {
  return (
    <Modal
      okText="I Understand"
      title={
        <Flex align="center" gap="xs">
          <S.IconContainer>
            <S.Icon name="info-circle" size="xs" />
          </S.IconContainer>
          <Text size="sm" colorVariant="primary">
            {companyName} is not allowing transfers
          </Text>
          <Text size="sm" colorVariant="primary" weight="bold">
            at this time
          </Text>
        </Flex>
      }
      open={open}
      onOk={onOk}
      size="md"
      closable={false}
    >
      <Text colorVariant="secondary" size="sm">
        NPM has been informed by {companyName} that all share transfers are
        being restricted for the time being. Thank you for your understanding
        and cooperation. We will let you know if anything changes.
      </Text>
    </Modal>
  );
};

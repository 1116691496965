import {
  type CbLabelComponent,
  type IconProps,
  type LabelVariant,
} from '@npm/utils';
import { capitalize } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  pending: 'pending',
  verified: 'verified',
  needs_verification: 'needs_verification',
  pre_verified: 'pre_verified',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  pending: 'warning',
  pre_verified: 'info',
  verified: 'success',
  needs_verification: 'error',
};

const LabelIconMap: Record<Keys, IconProps> = {
  pending: { name: 'clock', size: 'sm' },
  pre_verified: { name: 'check', size: 'sm' },
  verified: { name: 'check', size: 'sm' },
  needs_verification: { name: 'warning', size: 'sm' },
};

type Attributes =
  | 'inv_filter_visibility'
  | 'bro_filter_visibility'
  | 'iss_filter_visibility';

export const CbHoldingState = {
  code: Codebooks.HOLDING_STATE,
  items: Items,
  getAttrByName: (item: CodebookItem, name: Attributes) =>
    item.attributes?.find(q => q.name === name),
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem,
    showIcon = false
  ) =>
    Component({
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'none'],
      label: capitalize(codebookItem?.name),
      iconProps:
        showIcon && LabelIconMap[(codebookItem?.code as Keys) ?? 'none'],
    }),
};

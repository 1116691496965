import { type CbLabelComponent, type IconProps } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const items = {
  new_lead: 'new_lead',
  sales_contacted: 'sales_contacted',
  lead_rejected: 'lead_rejected',
  lead_engaged: 'lead_engaged',
  lead_won: 'lead_won',
} as const;

const labelMap = {
  new_lead: 'warning',
  sales_contacted: 'info',
  lead_rejected: 'error',
  lead_engaged: 'warning',
  lead_won: 'success',
};

export const CbLeadStatus = {
  code: Codebooks.LEAD_STATUS,
  items,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined,
    iconProps?: IconProps
  ) =>
    Component({
      variant: labelMap[codebookItem?.code ?? 'none'],
      label: codebookItem?.name,
      iconProps,
    }),
};

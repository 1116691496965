import React from 'react';
import { useInView } from 'react-intersection-observer';

import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { Loader } from '../Loader';

import * as S from './InfiniteScroll.styles';

export type InfiniteScrollProps = {
  loadMore: () => void;
  hasMore: boolean | undefined;
  isFetching: boolean; // Do not confuse with isLoading of react-query. They behave differently.
  children: React.ReactNode;
  loader?: React.ReactNode;
  loaderCount?: number;
  mobileLoaderCount?: number; // Make sure all loaders can fit into the visibile area
};

export const InfiniteScroll = ({
  loadMore,
  hasMore,
  isFetching,
  children,
  loader = <Loader />,
  loaderCount = 1,
  mobileLoaderCount = 1,
}: InfiniteScrollProps) => {
  const { isDesktop } = useBreakpoints();

  const [loaderRef] = useInView({
    threshold: 0,
    onChange: inView => {
      if (inView && hasMore && !isFetching) {
        loadMore();
      }
    },
  });

  return (
    <>
      {children}
      {(hasMore || isFetching) && (
        <>
          <S.LoaderContainer
            // Do not observe while fetching to prevent extra calls
            ref={isFetching ? undefined : loaderRef}
            data-cy="loader"
          >
            {loader}
          </S.LoaderContainer>
          {[...Array((isDesktop ? loaderCount : mobileLoaderCount) - 1)].map(
            (_, index) => (
              <S.LoaderContainer key={index}>{loader}</S.LoaderContainer>
            )
          )}
        </>
      )}
    </>
  );
};

import styled from 'styled-components';

import { useBreakpoint } from '@npm/core/ui/utils/style';

export const TextContainer = styled.div<{
  $align: 'left' | 'center' | 'right';
  $desktopFontSize: 'lg' | 'xl';
  $mobileFontSize: 'md' | 'lg';
}>`
  text-align: ${({ $align }) => $align};
  font-size: ${({ theme, $desktopFontSize }) =>
    theme.typography.size[$desktopFontSize]}px;
  ${({ theme, $mobileFontSize }) => useBreakpoint(theme).mobile`
    font-size: ${theme.typography.size[$mobileFontSize]}px;
  `}
  display: inline;
`;

export const BoldSpan = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
`;

export const UnderlinedSpan = styled.span`
  text-decoration: underline;
`;

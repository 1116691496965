import React, { type ComponentProps } from 'react';

import { Select } from '@npm/core/ui/components/atoms/Select';

import {
  type DateStringOption,
  generateDateOptions,
} from './DateStringSelect.utils';

type Props = Omit<ComponentProps<typeof Select>, 'options'> & {
  options?: DateStringOption[];
};

export const DateStringSelect = ({
  options,
  placeholder = 'All',
  ...props
}: Props) => {
  const generatedOptions = generateDateOptions(options);
  return (
    <Select
      allowClear
      options={generatedOptions}
      placeholder={`${placeholder} (${generatedOptions.length})`}
      {...props}
    />
  );
};

import { Fragment } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { formatPrice } from '@npm/core/ui/utils/formatters';

import * as S from './DataLine.styles';

export type Item = {
  title: string;
  value: number | undefined;
  isLoading?: boolean;
  color: string;
  blurred?: boolean;
  tooltip?: string;
  getDataLineTooltip?: (item: Item) => JSX.Element;
};

type Props = {
  data: Item[];
};

export const DataLine = ({ data }: Props) => {
  const values = data.map(item => item.value);
  const min = Math.min(...values);
  const max = Math.max(...values);

  if (values.length <= 1) {
    return null;
  }

  const getLeftPosition = value => {
    if (max === min) {
      return 50; // Avoid division by zero when all values are the same
    }
    return ((value - min) / (max - min)) * 100;
  };

  return (
    <S.LineContainer>
      <S.Line />
      {data.map((item, index) => (
        <Fragment key={index}>
          <Tooltip
            title={
              item.getDataLineTooltip
                ? item.getDataLineTooltip(item)
                : `${item.title}: $${formatPrice(item.value)}`
            }
          >
            <S.Dot
              style={{ left: `${getLeftPosition(item.value)}%` }}
              color={item.color}
              title={item.title}
              $price={item.value}
            >
              <S.InnerCircle />
            </S.Dot>
          </Tooltip>
        </Fragment>
      ))}
      <Flex justify="space-between">
        <Text.Price value={min} />
        <Text.Price value={max} />
      </Flex>
    </S.LineContainer>
  );
};

import React, { useEffect, useState } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { useLocation } from '@npm/core/ui/components/molecules/Link';
import { type Modal, useModal } from '@npm/core/ui/components/molecules/Modal';
import { usePaginationQueryParam } from '@npm/core/ui/hooks/pagination/usePagination';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { useResetFilterByKey } from '../../../../filters/filters';
import { useAccountSwitchRedirect } from '../../context/RoleSwitcher/AccountSwitcher/AccountSwitcher.hooks';
import { setObo } from '../../role/context/userRole.helpers';
import { type OboDefinition } from '../../role/userRole.types';

import { CreateNewAccountModal } from './steps/CreateNewAccount/CreateNewAccountModal';
import { CreateRepresentativeModal } from './steps/CreateRepresentative';
import { SelectAccount } from './steps/SelectAccount';
import { SelectNegotiationAccount } from './steps/SelectNegotiationAccount';
import { SelectRepresentative } from './steps/SelectRepresentative';
import { FooterButtons } from './FooterButtons';
import {
  type NegotiationAccounts,
  type NegotiationActionFlags,
  type OboModalState,
} from './OboModal.types';
import { TitleWithTooltip } from './TitleWithTooltip';

import * as S from './OboModal.styles';

const INITIAL_STATE = {
  account: undefined,
  representative: undefined,
};

type CancelResult = 'submitted' | 'cancelled' | 'redirected';

type BaseProps = React.ComponentProps<typeof Modal> & {
  onCancel: (result: CancelResult) => void;
  onSuccessRedirectRoute?: string;
  onSuccess?: () => void;
  setOboOverride?: (obo: OboDefinition) => void;
};

// `regular` variant fetches all available accounts in step 1
type RegularVariantProps = BaseProps & {
  variant?: 'regular';
};

// `negotiation` variant expects eligible accounts for step 1 passed as a prop
type NegotiationVariantProps = BaseProps & {
  variant: 'negotiation';
  negotiationAccounts: NegotiationAccounts;
  negotiationActionFlags: NegotiationActionFlags;
};

type Props = RegularVariantProps | NegotiationVariantProps;

export const OboModal = ({
  variant = 'regular',
  onSuccessRedirectRoute,
  onSuccess,
  setOboOverride,
  ...modalProps
}: Props) => {
  const [state, setState] = useState<OboModalState>(INITIAL_STATE);
  const [step, setStep] = useState<'account' | 'representative'>('account');
  const mustAddRepresentative =
    state.account && !state.account?.representative_count;

  const [
    openCreateAccountModal,
    { open: isCreateAccountModalOpen, onCancel: closeCreateAccountModal },
  ] = useModal();
  const [
    openCreateRepresentativeModal,
    {
      open: isCreateRepresentativeModalOpen,
      onCancel: closeCreateRepresentativeModal,
    },
  ] = useModal();

  const { resetQuery: resetPaginationQuery } =
    usePaginationQueryParam('oboAccounts');
  const { resetQuery: resetFiltersQuery } = useResetFilterByKey('oboAccounts');

  const { isMobile, isTablet, isSmDesktop } = useBreakpoints();
  const getRedirectPath = useAccountSwitchRedirect();
  const { pathname } = useLocation();

  useEffect(() => {
    // close modal when the location changes
    if (modalProps.open) modalProps.onCancel('redirected');
  }, [pathname]);

  const handleCancel = (result: CancelResult) => {
    setState(INITIAL_STATE);
    setStep('account');
    modalProps.onCancel(result);
    resetPaginationQuery();
    resetFiltersQuery();
  };

  const handleComplete = (nextOboState: OboModalState) => {
    const { account, representative } = nextOboState;

    if (mustAddRepresentative) {
      openCreateRepresentativeModal();
      return;
    }

    // shouldn't happen
    if (!account || !representative) {
      throw new Error(
        'Not enough info for entering OBO mode: missing account or representative'
      );
    }

    if (setOboOverride) {
      setOboOverride({ account, representative });
    } else {
      setObo(
        { account, representative },
        {
          redirectTo:
            onSuccessRedirectRoute ||
            getRedirectPath(null) ||
            window.location.pathname,
        }
      );
    }

    onSuccess?.();
    handleCancel('submitted');
  };

  const onCreateNewAccountSuccess = () => {
    if (setOboOverride) {
      onSuccess?.();
      handleCancel('submitted');
    } else {
      onSuccess?.();
    }
  };

  return (
    <>
      <S.Modal
        {...modalProps}
        onCancel={() => handleCancel('cancelled')}
        footer={
          <FooterButtons
            variant={variant}
            step={step}
            onCancel={() => handleCancel('cancelled')}
            onNext={() => handleComplete(state)}
            onBack={step === 'representative' ? () => setStep('account') : null}
            isNextDisabled={
              !state.account ||
              (!state.representative && !!state.account?.representative_count)
            }
            openCreateAccountModal={openCreateAccountModal}
            mustAddRepresentative={mustAddRepresentative}
          />
        }
        style={{ top: isTablet || isSmDesktop ? 30 : 65 }}
        width={1000}
        title={<TitleWithTooltip variant={variant} />}
        isFullScreen={isMobile}
        fullScreenProps={{
          footerHeight:
            variant === 'regular' || step === 'representative' ? 110 : 60,
        }}
        open={
          modalProps.open &&
          !isCreateAccountModalOpen &&
          !isCreateRepresentativeModalOpen
        }
      >
        {isMobile && (
          <Margin bottom="sm">
            <TitleWithTooltip variant={variant} />
          </Margin>
        )}
        <SelectAccount
          active={step === 'account' && variant === 'regular'}
          goToRepresentativeStep={() => setStep('representative')}
          selectedAccount={state.account}
          setOboModalState={setState}
          handleComplete={handleComplete}
        />
        {step === 'account' ? (
          variant === 'negotiation' ? (
            <SelectNegotiationAccount
              selectedAccount={state.account}
              setOboModalState={setState}
              goToRepresentativeStep={() => setStep('representative')}
              handleComplete={handleComplete}
              negotiationAccounts={
                (modalProps as NegotiationVariantProps).negotiationAccounts
              }
              negotiationActionFlags={
                (modalProps as NegotiationVariantProps).negotiationActionFlags
              }
            />
          ) : null
        ) : (
          <SelectRepresentative
            oboModalState={state}
            setOboModalState={setState}
            goBack={() => setStep('account')}
            handleComplete={handleComplete}
          />
        )}
      </S.Modal>
      <CreateNewAccountModal
        open={isCreateAccountModalOpen}
        onCancel={closeCreateAccountModal}
        onSuccess={onCreateNewAccountSuccess}
        setOboOverride={setOboOverride}
      />
      <CreateRepresentativeModal
        account={state.account}
        open={isCreateRepresentativeModalOpen}
        onCancel={closeCreateRepresentativeModal}
        onSuccess={onSuccess}
      />
    </>
  );
};

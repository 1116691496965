import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import {
  type ExtendedColumnType,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import { type TableVerticalRowType } from '@npm/core/ui/components/molecules/TableVertical';
import { WatchlistDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { composeDataId } from '@npm/core/ui/utils/cypress';
import {
  DATE_FORMATS,
  formatDate,
  getTimezoneShort,
} from '@npm/core/ui/utils/formatters';
import {
  type AssetTypeCode,
  type Watchlist,
  CbAssetType,
  CbOrderBuySell,
} from '@npm/data-access';

import { AccountName } from '../../account/components/AccountName';

import { type ActionConfigProps } from './WatchlistList/WatchlistList.types';

export type WatchlistConfig = (ExtendedColumnType<Watchlist> &
  TableVerticalRowType<Watchlist>)[];

export const useWatchlistConfig = ({
  onEditClick,
  onDeleteClick,
  isCompanyCentric,
  isSecondMarket,
  isInvestor,
  isSponsoredInvestor,
}: ActionConfigProps & {
  isCompanyCentric?: boolean;
  isSecondMarket?: boolean;
  isInvestor?: boolean;
  isSponsoredInvestor?: boolean;
}): WatchlistConfig => {
  const { isMobile } = useBreakpoints();
  const companyLinkVariant = isCompanyCentric ? 'logo' : 'icon';
  const companyIconVariant = isCompanyCentric
    ? 'external-link'
    : 'chevron-right';

  return [
    {
      title: 'Company',
      key: 'company',
      render: (_, { issuer_entity }) => (
        <CompanyLogo
          url={issuer_entity?.logo_url}
          name={issuer_entity?.name}
          linkProps={{
            companyId: issuer_entity?.id,
            prefix:
              isInvestor && isSponsoredInvestor
                ? '/second-market/sponsored-investor'
                : isInvestor
                ? '/investor-workstation'
                : undefined,
            variant: isSecondMarket ? companyLinkVariant : 'icon',
            iconVariant: isSecondMarket ? companyIconVariant : 'external-link',
            afterPrefixPath: '/company-data',
          }}
        />
      ),
      hidden: isMobile,
    },
    {
      title: 'Side',
      key: 'side',
      titleInfo: 'Side and Visibility',
      render: (_, { order_buy_sell }) =>
        // !order_buy_sell = Buy & Sell
        order_buy_sell ? (
          CbOrderBuySell.getLabel(CbLabel, order_buy_sell)
        ) : (
          <Label round>Buy & Sell</Label>
        ),

      width: 100,
    },
    {
      title: 'Account',
      key: 'account',
      render: (_, { account }) => <AccountName account={account} />,
      collapsed: true,
    },
    {
      title: 'Asset Type',
      key: 'asset_type',
      render: (_, { asset_type }) =>
        // !asset_type = AnyShareClass
        asset_type
          ? CbAssetType.getShortLabel(asset_type?.code as AssetTypeCode)
          : CbAssetType.getShortLabel(CbAssetType.items.AnyShareClass),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
    },
    {
      title: 'Min. Size',
      key: 'min_size',
      render: (_, { minimum_size }) => (
        <Text.Price value={minimum_size} compact />
      ),
      responsive: TABLE_BREAKPOINTS.TABLET,
      hidden: isMobile,
    },
    {
      title: `Note`,
      key: 'note',
      render: (_, { note }) =>
        note?.contents && (
          <Tooltip title={note.contents}>
            <Button variant="text" icon={<Icon name="message" />} />
          </Tooltip>
        ),
      responsive: TABLE_BREAKPOINTS.TABLET,
    },
    {
      title: `Created at (${getTimezoneShort()})`,
      key: 'created_at',
      render: (_, { created_at }) =>
        formatDate(created_at, { dateFormat: DATE_FORMATS.DATE }) || '--',
      responsive: TABLE_BREAKPOINTS.TABLET,
    },
    {
      key: 'link_button',
      render: (
        _,
        {
          user_can_edit_watchlist,
          issuer_entity_id,
          account_id,
          issuer_entity,
          id,
        }
      ) =>
        user_can_edit_watchlist ? (
          <Flex>
            <Button
              variant="outline"
              onClick={() => onEditClick(issuer_entity_id, account_id)}
              data-cy={composeDataId(WatchlistDataIds.Table.EditButton, id)}
            >
              Edit
            </Button>
            <Margin left={'xs'}>
              <Button
                variant="text"
                color="error"
                icon={<Icon name="trash" />}
                onClick={() =>
                  onDeleteClick(
                    issuer_entity_id,
                    account_id,
                    issuer_entity?.name
                  )
                }
                data-cy={composeDataId(WatchlistDataIds.Table.DeleteButton, id)}
              />
            </Margin>
          </Flex>
        ) : undefined,
      width: 1,
      hidden: isMobile,
    },
  ];
};

import { useMemo } from 'react';
import { type ChartDataset } from 'chart.js';
import { useTheme } from 'styled-components';

import { transparentise } from '@npm/core/ui/utils/style';

import { type CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import { type CompanyOverviewChartDataSetEffect } from '../../../TapeDPricing/TapeDPricing.types';
import { getMutualFundMarksColor } from '../../MutualFundMarks.utils';

import {
  type MovingAverageValue,
  type MutualFundChartPoint,
  type MutualFundSortedData,
} from './MutualFundMarksChart.types';
import {
  convertMovingAverageDataSets,
  convertMtmDataSet,
} from './MutualFundMarksChart.utils';

export const useMutualFundChartLimits = ({
  commonMtm,
  preferredMtm,
  movingAverage,
}: MutualFundSortedData) => {
  return useMemo(() => {
    const minDate = Math.min(
      ...[
        commonMtm?.[commonMtm.length - 1]?.reporting_period,
        preferredMtm?.[preferredMtm.length - 1]?.reporting_period,
        movingAverage?.[movingAverage.length - 1]?.date,
      ]
        .filter(Boolean)
        .map(date => new Date(date).getTime())
    );

    return [new Date(minDate), new Date()];
  }, [commonMtm, preferredMtm, movingAverage]);
};

export const useMutualFundChartDataSets = (
  sortedData: MutualFundSortedData,
  series: CompanyOverviewChartSeries
): ChartDataset<'line', MutualFundChartPoint[]>[] => {
  const theme = useTheme();

  const movingAverageDataSet = useMovingAverageChartDataSet(
    sortedData.movingAverage,
    {
      series,
    }
  );

  return useMemo(() => {
    const commonDataPoints = convertMtmDataSet(sortedData.commonMtm, series);

    const preferredDataPoints = convertMtmDataSet(
      sortedData.preferredMtm,
      series
    );

    return [
      {
        label: 'Common',
        data: commonDataPoints,
        borderWidth: 0,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointBorderColor: theme.color.info.typography.primary,
      },
      {
        label: 'Preferred',
        data: preferredDataPoints,
        borderWidth: 0,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointBorderColor: theme.color.success.typography.primary,
      },
      movingAverageDataSet,
    ];
  }, [sortedData, series]);
};

type HookOptions = {
  series: CompanyOverviewChartSeries;
  effect?: CompanyOverviewChartDataSetEffect;
  order?: number;
};

export const useMovingAverageChartDataSet = (
  data: MovingAverageValue[],
  options: HookOptions
): ChartDataset<'line', MutualFundChartPoint[]> => {
  const theme = useTheme();

  const { series, effect, order } = options;

  return useMemo(() => {
    const movingAverageData = convertMovingAverageDataSets(data, series);

    const borderColor = getMutualFundMarksColor(theme);

    return {
      label: '90 Day Moving Average',
      data: movingAverageData,
      borderWidth: effect === 'highlight' ? 3 : 1,
      pointRadius: 0,
      pointHoverRadius: 0,
      borderColor:
        effect === 'dim' ? transparentise(borderColor, 0.3) : borderColor,
      order,
    };
  }, [data, series, effect, order]);
};

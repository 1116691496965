import { useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Radio, RadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import {
  type CodebookShow,
  type OrderItemStateCode,
  CbOrderItemState,
  Codebooks,
  useCodebook,
} from '@npm/data-access';

const supportedOptionCodes = [
  CbOrderItemState.items.expired,
  CbOrderItemState.items.cancelled,
];

type Props = {
  value?: OrderItemStateCode;
  onItemChange: (value: OrderItemStateCode | undefined) => void;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const StateFormSection = ({ value, onItemChange, ...props }: Props) => {
  const { data: stateData } = useCodebook({
    type: Codebooks.ORDER_ITEM_STATE,
  });

  const optionsByCode = useMemo(
    () =>
      stateData?.codebooks?.reduce<Record<string, CodebookShow>>((acc, cur) => {
        acc[cur.code] = cur;
        return acc;
      }, {}),
    [stateData]
  );

  const selectedOption = optionsByCode?.[value];

  return (
    <CollapsibleFormSection.Item
      label="Order Status"
      preview={selectedOption?.name}
      key="state"
      name="state"
      {...props}
    >
      <Flex align="center">
        <FormItem name="state" marginBottom="sm">
          <RadioGroup onChange={event => onItemChange(event?.target?.value)}>
            {supportedOptionCodes.map(
              code =>
                optionsByCode?.[code] && (
                  <Radio
                    value={code}
                    label={optionsByCode[code]?.name}
                    key={code}
                  />
                )
            )}
          </RadioGroup>
        </FormItem>
      </Flex>
    </CollapsibleFormSection.Item>
  );
};

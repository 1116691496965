import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import {
  type Codebook,
  type OrderItemStateCode,
  CbOrderItemState,
} from '@npm/data-access';

import { getTooltip } from './OrderStatusLabel.config';

type Props = {
  state: Codebook | undefined;
  variant: 'individual' | 'entity' | 'broker';
};

export const OrderStatusLabel = ({ state, variant }: Props) => {
  const isLive = state?.code === CbOrderItemState.items.live_in_marketplace;
  const underReview =
    variant === 'individual' &&
    state?.code === CbOrderItemState.items.not_posted_to_marketplace;

  const tooltip = getTooltip(state?.code as OrderItemStateCode, variant);
  const dashed = !!tooltip;
  const withIcon = isLive;

  if (!state) return null;

  return (
    <Tooltip title={tooltip}>
      {CbOrderItemState.getLabel(
        CbLabel,
        underReview ? { ...state, name: 'Under Review' } : state,
        withIcon,
        dashed
      )}
    </Tooltip>
  );
};

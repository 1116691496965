import React, { useRef, useState } from 'react';
import { type FormInstance } from 'antd';
import { isEmpty } from 'lodash';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useConfirmOnLeave } from '@npm/core/ui/components/molecules/Link';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import {
  type IssuerEntityAggregate,
  type UserRoleAggregate,
  type WatchlistCreateRequestContract,
  useUserRoleIndex,
  useWatchlistCreate,
} from '@npm/data-access';

import { USER_ROLE_INDEX_BASE_PARAMS } from '../../../auth/user/context';
import { useObo } from '../../../auth/user/role';
import {
  OrderPlacement,
  useOrderPlacementDrawerContext,
} from '../../../second-market/drawers/OrderEntryDrawer';

import { CompanyCards } from './CompanyCards';

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  filterAccounts?: (role: UserRoleAggregate) => boolean;
};

export const AddWatchlistMultipleDrawer = ({
  isLoading,
  isOpen,
  onClose,
  filterAccounts,
}: Props) => {
  const { isObo, oboAccountId } = useObo();
  const [, orderPlacementProps] = useOrderPlacementDrawerContext();

  const [selectedCompanies, setSelectedCompanies] = useState<
    IssuerEntityAggregate[]
  >([]);
  const [failedCompanyNames, setFailedCompanyNames] = useState<string[]>([]);
  const { setHasUnsavedChanges, discardModalProps, onCloseAttempt } =
    useConfirmOnLeave();

  // store of visible form instances (cards that are shown in the drawer at the moment)
  const formsRef = useRef<
    Record<number, FormInstance<WatchlistCreateRequestContract>>
  >({});
  const [formsValues, setFormsValues] = useState<
    Record<number, WatchlistCreateRequestContract>
  >({});

  const { data: userRoleIndexData } = useUserRoleIndex({
    ...USER_ROLE_INDEX_BASE_PARAMS,
  });
  const roles = filterAccounts
    ? userRoleIndexData?.user_roles?.filter(filterAccounts)
    : userRoleIndexData?.user_roles;

  const { execute, isLoading: isCreating } = useWatchlistCreate();

  const handleSelect = (company: IssuerEntityAggregate) => {
    const filteredCompanies = selectedCompanies.filter(
      c => c.id !== company.id
    );

    if (filteredCompanies.length === selectedCompanies.length) {
      setSelectedCompanies(s => [...s, company]);
    } else {
      setSelectedCompanies(filteredCompanies);
    }

    setHasUnsavedChanges(true);
  };

  const handleCloseConfirm = () => {
    onClose();
    setFailedCompanyNames([]);
    setSelectedCompanies([]);
    setHasUnsavedChanges(false);
  };

  const handleSubmit = async () => {
    setFailedCompanyNames([]);
    const watchlistCreatePromises = [];
    const defaultAccountId = isObo ? oboAccountId : roles?.[0]?.subject?.id;

    for (const company of selectedCompanies) {
      try {
        const formValues = {
          ...(formsValues?.[company.id] || {}),
          ...(formsRef.current?.[company.id]?.getFieldsValue() || {}),
        };
        const values: WatchlistCreateRequestContract = {
          ...formValues,
          issuer_entity_id: [company.id],
          account_id: formValues?.account_id ?? defaultAccountId,
        };

        watchlistCreatePromises.push(
          execute({
            watchlistCreateRequestContract: values,
          })
        );
      } catch (err) {
        console.error(err);
      }
    }

    try {
      await Promise.all(watchlistCreatePromises);
      handleCloseConfirm();
    } catch (err) {
      for (let i = 0; i < watchlistCreatePromises.length; i++) {
        try {
          await watchlistCreatePromises[i];
        } catch {
          setFailedCompanyNames(f => [...f, selectedCompanies[i].name]);
        }
      }
    }
  };

  // store form values in state to not lose them when the filtered list changes
  const handleFilterSubmit = async () => {
    const forms = { ...formsValues };

    for (const company of selectedCompanies) {
      const formValues = formsRef.current[company.id]?.getFieldsValue();
      if (!isEmpty(formValues)) {
        forms[company.id] = formValues;
      }
    }
    setFormsValues(forms);
  };

  return (
    <>
      <Drawer
        title="Add to Watchlist"
        open={isOpen}
        onClose={() => onCloseAttempt(handleCloseConfirm)}
        onSubmit={handleSubmit}
        buttonText="Add to Watchlist"
        buttonLoading={isLoading}
        isDisabled={!selectedCompanies.length || isCreating}
        isFooterFixed
        isFullHeight
      >
        {failedCompanyNames.length > 0 && (
          <Margin bottom="md">
            <Alert
              type="error"
              message={
                <>
                  <Text marginBottom="sm">
                    The following companies could not be added to the watchlist:{' '}
                  </Text>
                  <ul style={{ marginBottom: 0 }}>
                    {failedCompanyNames.map((name, idx) => (
                      <Text key={idx} as="li">
                        {name}
                      </Text>
                    ))}
                  </ul>
                </>
              }
              description="Please try again later."
              expandable={false}
            />
          </Margin>
        )}

        <CompanyCards
          formsValues={formsValues}
          formsRef={formsRef}
          selectedCompanies={selectedCompanies}
          companyCardProps={{
            variant: 'investor',
            showWatchlistButton: true,
            onWatchlistButtonClick: null,
            watchlistButtonStopPropagation: false,
            filterAccounts,
          }}
          onSelect={handleSelect}
          onFilterSubmit={handleFilterSubmit}
        />
        <DiscardModal {...discardModalProps} />
      </Drawer>
      <OrderPlacement {...orderPlacementProps} />
    </>
  );
};

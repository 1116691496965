import styled from 'styled-components';

import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { centerFlex, useBreakpoint } from '@npm/core/ui/utils/style';

export const PriceContainer = styled.div<{
  $compact?: boolean;
  $borderless?: boolean;
  $noBg?: boolean;
}>`
  display: flex;
  align-items: center;

  ${({ theme }) => useBreakpoint(theme).mobile`
    justify-content: center
  `}

  height: ${({ $compact }) => ($compact ? '40px' : '56px')};
  min-width: ${({ $compact }) => ($compact ? '250px' : '300px')};
  min-height: 40px;

  gap: ${({ theme }) => theme.spacing.sm}px;
  padding: 0 ${({ theme }) => theme.spacing.md}px;

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background-color: ${({ theme }) => theme.color.general.layout.two};

  ${({ $borderless }) =>
    $borderless &&
    `
    border: none;
    border-radius: revert;
  `}

  ${({ $noBg }) =>
    $noBg &&
    `
    background-color: transparent;
  `}
`;

export const PricePerShare = styled.div<{
  $compactPps?: boolean;
  $highlightPps?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: ${({ theme }) => theme.spacing.xs}px;
  min-width: 120px;
  width: 35%;
  flex-shrink: 0;

  ${({ $compactPps }) =>
    $compactPps &&
    `
    width: revert;
    min-width: revert;
  `}

  ${({ theme, $highlightPps }) =>
    $highlightPps &&
    `
    background-color: ${theme.color.general.layout.one};
    border-radius: ${theme.borderRadius.sm}px;
    height: 100%;
    padding: ${theme.spacing.xs}px ${theme.spacing.sm}px;
  `}
`;

export const Quantity = styled.div`
  ${centerFlex};

  width: 20%;
`;

export const EstimatedGrossProceeds = styled.div`
  display: flex;
  align-items: center;

  width: 35%;
`;

export const Operator = styled.div`
  ${centerFlex};

  width: 5%;
`;

export const SharesText = styled(Heading).attrs({
  variant: 'h3',
  color: 'general',
  colorVariant: 'tertiary',
})`
  margin-left: ${({ theme }) => theme.spacing.xs}px;
`;

import { useMemo } from 'react';

import { NPMS_NAME } from '../../context';
import { useUserRole } from '../context/userRole.context';

export const useCurrentBrokerageFirm = () => {
  const userRole = useUserRole();

  const brokerageFirmId =
    userRole?.workstationType === 'brokerage'
      ? userRole?.subRole?.subject?.id
      : null;

  const brokerageFirmName =
    userRole?.workstationType === 'brokerage'
      ? userRole?.subRole?.subject?.name
      : null;

  return useMemo(() => {
    return {
      brokerageFirmId,
      isNpms: brokerageFirmName === NPMS_NAME,
    };
  }, [brokerageFirmId, brokerageFirmName]);
};

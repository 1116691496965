import { Codebooks } from './codebooks.types';

const items = {
  interested_services: 'interested_services',
  has_current_financial_advisor: 'has_current_financial_advisor',
  meeting_preference: 'meeting_preference',
  total_investable_assets: 'total_investable_assets',
  preferred_contact_method: 'preferred_contact_method',
  additional_concerns: 'additional_concerns',
} as const;

export type WealthQuizCode = typeof items[keyof typeof items];
export type WealthQuizOther =
  | 'interested_services_other'
  | 'has_current_financial_advisor_other'
  | 'meeting_preference_other';

export const CbWealthQuiz = {
  code: Codebooks.WEALTH_QUIZ,
  items,
};

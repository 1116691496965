import type { DocumentSimple } from '@npm/data-access';

import { getHoldingItem } from '../../../../../../holdings';

export const getHoldingDetailRowsConfig = (
  onDocumentClick?: (doc: DocumentSimple) => void
) => {
  return [
    {
      ...getHoldingItem('remaining_quantity')(),
    },
    {
      ...getHoldingItem('quantity')(),
    },
    {
      ...getHoldingItem('vested_qty')(),
    },
    { ...getHoldingItem('class')() },
    { ...getHoldingItem('cost_basis')() },
    { ...getHoldingItem('account')() },
    { ...getHoldingItem('registered_name')() },
    {
      ...getHoldingItem('proof_of_ownership_document')({
        onClick: onDocumentClick,
      }),
    },
    { ...getHoldingItem('acquisition_date')() },
  ];
};

import { useBrokerageFirmIndex } from '@npm/data-access';

type Props = {
  name: string;
};

export const useBrokerageFirmId = ({ name }: Props) => {
  const { data, isLoading } = useBrokerageFirmIndex({});

  console.log(data);

  if (!data || isLoading) {
    return undefined;
  }

  const result =
    data.brokerage_firms?.find(brokerageFirm => brokerageFirm.name === name)
      ?.id ?? null;

  if (!result) {
    throw new Error(`Brokerage firm with name ${name} not found`);
  }

  return result;
};

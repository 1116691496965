import React from 'react';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';
import {
  type SecondmarketOrderItemSimple,
  type SecondmarketSubmissionForClientOrders,
  CbOrderBuySell,
  CbOrderEntryType,
} from '@npm/data-access';

import { OrderStatusLabel } from '../../../../../components';
import { InvestorOrdersDropdown } from '../../InvestorOrdersDropdown';
import { getHoldingDisplayValue } from '../../InvestorOrdersTable/InvestorOrdersTable.utils';
import { PriceBlock } from '../../PriceBlock';
import {
  type OrdersActionsConfig,
  useOrdersConfig,
} from '../../useOrdersConfig.hooks';

import * as S from './InvestorOrderCard.styles';

type Props = {
  submission: SecondmarketSubmissionForClientOrders | undefined;
  isLoading?: boolean;
  hideAdditionalFields?: boolean;
  hideDropdownItems?: boolean;
  showOrderType?: boolean;
} & OrdersActionsConfig;

export const InvestorOrderCard = ({
  submission,
  isLoading,
  hideAdditionalFields,
  hideDropdownItems,
  onHoldingDetailClick,
  onHoldingEditClick,
  onOrderCancelClick,
  onOrderDetailClick,
  onOrderEditClick,
  onRenewOrderClick,
  showFees,
  showOrderType,
}: Props) => {
  const order = submission?.items?.[0];

  const isBuy = submission?.side?.code === CbOrderBuySell.items.buy;

  const rows = useOrdersConfig({
    onHoldingDetailClick,
    onHoldingEditClick,
    onOrderCancelClick,
    onOrderDetailClick,
    onOrderEditClick,
    onRenewOrderClick,
    showFees,
  });

  return (
    <S.Card
      variant="secondMarket"
      background="one"
      isLoading={isLoading}
      header={{
        title: (
          <Flex align="center" gap="xs">
            {CbOrderBuySell.getLabel(CbLabel, submission?.side)}
            <Text colorVariant="secondary"> Order</Text>
            <Text weight="bold">#{order?.id}</Text>
          </Flex>
        ),
        backgroundLayoutColor: 'one',

        label: (
          <Flex align="center" gap="xs">
            <OrderStatusLabel state={order?.state} variant="individual" />
            {showOrderType &&
              CbOrderEntryType.getLabel(CbLabel, submission?.order_type)}
            {!hideDropdownItems && (
              <InvestorOrdersDropdown
                submission={submission}
                hideDetailButton
                {...{
                  onOrderCancelClick,
                  onOrderDetailClick,
                  onOrderEditClick,
                }}
              />
            )}
          </Flex>
        ),
      }}
    >
      <Margin top="sm" bottom="sm">
        <PriceBlock
          quantity={order?.quantity}
          price={order?.price}
          isBuy={isBuy}
          onAddClick={() => onOrderEditClick?.(submission)}
          compact
        />
      </Margin>
      {hideAdditionalFields ? (
        <Flex align="center" justify="center">
          {getHoldingDisplayValue(
            submission,
            onHoldingDetailClick,
            onHoldingEditClick
          )}
        </Flex>
      ) : (
        <TableVertical<SecondmarketOrderItemSimple>
          rows={rows}
          data={submission}
        />
      )}
    </S.Card>
  );
};

import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';

import type { FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps & {
  value?: boolean;
  onChange?: (value: boolean | undefined) => void;
};

export const BooleanCheckboxFilter = ({
  value,
  onChange,
  name,
  onFilterSubmit,
}: Props) => {
  return (
    <>
      <Checkbox
        label="Yes"
        checked={value === true}
        onChange={event => {
          onChange?.(event.target.checked ? true : undefined);
          onFilterSubmit?.(name);
        }}
      />
      <Checkbox
        label="No"
        checked={value === false}
        onChange={event => {
          onChange?.(event.target.checked ? false : undefined);
          onFilterSubmit?.(name);
        }}
      />
    </>
  );
};

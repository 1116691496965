import { Codebooks } from './codebooks.types';

const Items = {
  brokerage: 'brokerage',
  investor: 'investor',
  issuer: 'issuer',
  advisor: 'advisor',
} as const;

const CodebookValues = {
  brokerage: {
    name: 'Brokerage Workstation',
    code: Items.brokerage,
  },
  investor: {
    name: 'Investor Workstation',
    code: Items.investor,
  },
  issuer: {
    name: 'Issuer Workstation',
    code: Items.issuer,
  },
  advisor: {
    name: 'Advisor Workstation',
    code: Items.advisor,
  },
} as const;

export type WorkstationType = typeof Items[keyof typeof Items];

export const CbWorkstationType = {
  code: Codebooks.WORKSTATION_TYPE,
  items: Items,
  codebookValues: CodebookValues,
};

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  type SecondmarketSubmissionForClientOrders,
  CbAccountSponsorshipType,
  CbATSPool,
  CbOrderItemState,
} from '@npm/data-access';

import { useUserContextStore } from '../../../../auth/user/context';
import { isExpiringOrder } from '../../../client-orders/expiringOrders';
import type { OpenRenewOrderModal } from '../../../client-orders/expiringOrders/RenewOrderModal/RenewOrderModal.types';

import type { OrderActions } from './InvestorOrdersList.types';

type DropdownOptions = 'detail' | 'edit' | 'cancel';

type Props = {
  submission: SecondmarketSubmissionForClientOrders;
  hideDropdownOptions?: DropdownOptions[];
  hideDetailButton?: boolean;
  onRenewOrderClick?: OpenRenewOrderModal;
} & OrderActions;

export const InvestorOrdersDropdown = ({
  submission,
  hideDropdownOptions,
  hideDetailButton,
  onOrderCancelClick,
  onOrderDetailClick,
  onOrderEditClick,
  onRenewOrderClick,
}: Props) => {
  const isSponsoredInvestorLevel2 = useUserContextStore(state =>
    CbAccountSponsorshipType.isSponsoredAccountLevel2(state.sponsorshipType)
  );

  const { isMobile } = useBreakpoints();

  const order = submission?.items?.[0];
  const stateCode = order?.state?.code;

  const isExternal =
    order?.ats_pool?.code === CbATSPool.items.ats_i ||
    order?.ats_pool?.code === CbATSPool.items.ats_sm;

  const isOrderNotEditable =
    stateCode === CbOrderItemState.items.expired ||
    stateCode === CbOrderItemState.items.cancelled ||
    stateCode === CbOrderItemState.items.matched ||
    stateCode === CbOrderItemState.items.filled;

  const isDropdownDisabled = !isMobile && isOrderNotEditable;

  const isExpiring = isExpiringOrder(order);

  const dropdownItems = [
    !hideDropdownOptions?.includes('detail') &&
      onOrderDetailClick && {
        label: 'Details',
        icon: <Icon name="info-circle" />,
        onClick: () => onOrderDetailClick(submission),
      },
    isExpiring &&
      onRenewOrderClick && {
        label: 'Renew Order',
        icon: <Icon name="refresh" />,
        onClick: () => onRenewOrderClick(order),
      },
    !hideDropdownOptions?.includes('edit') &&
      onOrderEditClick && {
        label: 'Edit Order',
        icon: <Icon name="edit" />,
        onClick: () => onOrderEditClick(submission),
        disabled:
          (isExternal && !isSponsoredInvestorLevel2) || isOrderNotEditable,
      },
    !hideDropdownOptions?.includes('cancel') &&
      onOrderCancelClick && {
        label: 'Cancel Order',
        icon: <Icon name="trash" color="error" />,
        onClick: () => onOrderCancelClick(submission),
        disabled: !order?.cancellable,
      },
  ].filter(Boolean);

  // TODO: NFE-6644
  return (
    <Flex justify="flex-end" align="center" gap="sm">
      {!hideDetailButton && (
        <Button
          variant="outline"
          onClick={() => onOrderDetailClick(submission)}
        >
          Detail
        </Button>
      )}
      <Tooltip
        title={isDropdownDisabled ? 'You can not edit this order.' : undefined}
      >
        <div>
          <Dropdown items={dropdownItems} disabled={isDropdownDisabled}>
            <Button
              variant="text"
              size="md"
              icon={<Icon name="dots-vertical" />}
            />
          </Dropdown>
        </div>
      </Tooltip>
    </Flex>
  );
};

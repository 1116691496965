import { useTheme } from 'styled-components';

import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import type { IssuerEntityAggregate, VenusApi } from '@npm/data-access';

import { CompanyHeading } from '../../../../company/CompanyOverview/General/CompanyHeading';
import { Executives } from '../../../../company/CompanyOverview/General/CorporateProfile/components/Executives';
import { Description } from '../../../../company/CompanyOverview/General/DescriptionWithBaseInfo/components';
import { Investors } from '../../../../company/CompanyOverview/General/Investors';

import { CorporateProfile } from './CorporateProfile';

import * as S from './CompanyInfoModal.styles';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  companyProfile?: VenusApi.CompanyProfile;
  issuerEntity?: IssuerEntityAggregate;
  isLoading?: boolean;
};

export const CompanyInfoModal = ({
  isOpen,
  onClose,
  issuerEntity,
  companyProfile,
  isLoading,
}: Props) => {
  const { spacing } = useTheme();
  const { isMobile, isSmTablet } = useBreakpoints();

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={`About ${companyProfile?.name}`}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <>
        <CompanyHeading issuerEntity={issuerEntity} isLoading={isLoading} />
        <Description companyProfile={companyProfile} defaultExpanded={true} />
        <Divider
          marginTop={isMobile || isSmTablet ? spacing.sm : spacing.xxl / 2}
          marginBottom={0}
        />
        <S.TwoColumnBaseInfo companyProfile={companyProfile} />
        <Divider />
        <Investors
          companyProfile={companyProfile}
          isLoading={isLoading}
          defaultExpanded
        />
        <Divider
          marginTop={isMobile || isSmTablet ? spacing.sm : spacing.xxl / 2}
        />
        <CorporateProfile
          companyProfile={companyProfile}
          isLoading={isLoading}
        />

        <Divider />

        <Text size="sm" as="h4" marginBottom="xs">
          Executives
        </Text>
        <Executives companyProfile={companyProfile} />
      </>
    </Modal>
  );
};
